import axios, { AxiosInstance, AxiosError } from 'axios';

const baseURL = 'https://tribhus.shop:5000';

const axiosInstance: AxiosInstance & {
  isAxiosError: (payload: any) => payload is AxiosError;
} = axios.create({
  baseURL: baseURL
}) as any;

axiosInstance.isAxiosError = axios.isAxiosError;

type UpdateTokenFunction = (accessToken: string, refreshToken: string) => Promise<void>;

export function setupInterceptors(updateToken: UpdateTokenFunction) {
  axiosInstance.interceptors.request.use(
    async config => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );
  
  let isRefreshing = false;
  let failedQueue: { resolve: (token: string) => void; reject: (error: any) => void; }[] = [];

  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else if (token) {
        prom.resolve(token);
      } else {
        prom.reject('No token provided');
      }
    });
    failedQueue = [];
  };

  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      // Se não é erro 401, apenas rejeita normalmente
      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }

      // Se é uma falha no refresh, desloga
      if (originalRequest.url === '/users/refresh') {
        console.log("Refresh falhou, deslogando...");
        localStorage.clear();
        window.location.href = '/PrimeiraTela';
        return Promise.reject(error);
      }

      // Para outros 401, tenta refresh
      if (!isRefreshing) {
        isRefreshing = true;
        console.log("Token expirado. Tentando refresh...");

        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const sessionId = localStorage.getItem('sessionId');
          
          if (!refreshToken) {
            throw new Error('Refresh token não encontrado');
          }

          const res = await axiosInstance.post('/users/refresh', { 
            refreshToken, 
            sessionId, 
            platform: 'web' 
          });

          if (res.status === 200 && res.data?.accessToken && res.data?.refreshToken) {
            const newAccessToken = res.data.accessToken;
            const newRefreshToken = res.data.refreshToken;

            localStorage.setItem('accessToken', newAccessToken);
            localStorage.setItem('refreshToken', newRefreshToken);
            await updateToken(newAccessToken, newRefreshToken);

            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            processQueue(null, newAccessToken);

            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return axiosInstance(originalRequest);
          }
          
          throw new Error('Falha ao obter novos tokens');
        } catch (refreshError) {
          console.error("Erro ao atualizar o token:", refreshError);
          processQueue(refreshError, null);
          
          // Se o refresh falhou, desloga
          localStorage.clear();
          window.location.href = '/PrimeiraTela';
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      // Se já está refreshing, aguarda na fila
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      });
    }
  );
}

export default axiosInstance;