import React, { useState, useContext, ChangeEvent, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';
import { useNavigate } from 'react-router-dom';

interface UploadVideoVodProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const UploadVideoVod: React.FC<UploadVideoVodProps> = ({ isOpen, onClose }) => {
  const { state: authState } = useContext(AuthContext);
  const { id_usuario, id_banda } = authState;
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadType, setUploadType] = useState<'banda' | 'usuario'>(id_banda ? 'banda' : 'usuario');

  const videoInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Determinar automaticamente o tipo de upload com base na disponibilidade de IDs
    if (id_banda) {
      setUploadType('banda');
    } else if (id_usuario) {
      setUploadType('usuario');
    }
  }, [id_banda, id_usuario]);

  useEffect(() => {
    // Limpar URLs de objeto ao desmontar para evitar memory leaks
    return () => {
      if (videoPreview) {
        URL.revokeObjectURL(videoPreview);
      }
    };
  }, [videoPreview]);

  const handleModalClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  };

  const handleVideoSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Verificar se é um arquivo de vídeo
      if (!file.type.startsWith('video/')) {
        alert('Por favor, selecione um arquivo de vídeo válido.');
        return;
      }

      // Verificar tamanho máximo (500MB)
      const maxSize = 500 * 1024 * 1024; // 500MB em bytes
      if (file.size > maxSize) {
        alert('O arquivo é muito grande. O tamanho máximo permitido é 500MB.');
        return;
      }

      setVideoFile(file);
      // Criar URL para preview do vídeo
      const videoUrl = URL.createObjectURL(file);
      setVideoPreview(videoUrl);
    }
  };

  const handleUploadVideo = async () => {
    if (!videoFile) {
      alert('Por favor, selecione um vídeo para upload.');
      return;
    }
  
    if (!title.trim()) {
      alert('Por favor, adicione um título para o vídeo.');
      return;
    }
  
    setIsUploading(true);
    setUploadProgress(0);
  
    try {
      const formData = new FormData();
      formData.append('video', videoFile);
      formData.append('titulo_videosvod', title);
      formData.append('descricao', description);
      
      // Adiciona o ID conforme o tipo de upload (banda ou usuário)
      if (uploadType === 'banda' && id_banda) {
        formData.append('id_banda', id_banda.toString());
      } else if (uploadType === 'usuario' && id_usuario) {
        formData.append('id_usuario', id_usuario.toString());
      }
  
      // SOLUÇÃO FINAL: Usar o caminho exato conforme definido no backend
      const baseURL = 'https://tribhus.shop:5000';
      
      // Usar a rota exata como está definida no backend
      const endpoint = uploadType === 'banda' 
        ? `${baseURL}/videos/upload-videosvod/${id_banda}` 
        : `${baseURL}/videos/upload-videosvod-usuario/${id_usuario}`;
      
      console.log('Enviando para endpoint:', endpoint);
  
      const response = await axios.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authState.token}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total 
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          setUploadProgress(percentCompleted);
        }
      });
  
      if (response.status === 201) {
        alert('Vídeo enviado com sucesso! Agora ele será processado no servidor.');
        resetForm();
        
        if (onClose) {
          onClose();
        } else {
          navigate('/VideoVodList');
        }
      }
    } catch (error) {
      console.error('Erro ao fazer upload do vídeo:', error);
      if (axios.isAxiosError(error)) {
        console.error('URL da requisição:', error.config?.url);
        console.error('Status do erro:', error.response?.status);
        console.error('Mensagem do servidor:', error.response?.data);
      }
      alert('Erro ao fazer upload do vídeo. Por favor, tente novamente mais tarde.');
    } finally {
      setIsUploading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setVideoFile(null);
    if (videoPreview) {
      URL.revokeObjectURL(videoPreview);
      setVideoPreview(null);
    }
    setUploadProgress(0);
  };

  const triggerFileInput = () => {
    if (videoInputRef.current) {
      videoInputRef.current.click();
    }
  };

  // Se estiver sendo usado como modal e isOpen for false, não renderiza nada
  if (isOpen === false) return null;

  return (
    <div style={styles.modalOverlay} onClick={handleModalClose}>
      <div style={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
        <button onClick={handleModalClose} style={styles.closeButton}>&times;</button>
        <h1 style={styles.headerTitle}>Upload de Vídeo</h1>
        
        <div style={styles.contentContainer}>
          <input
            style={styles.textInput}
            type="text"
            placeholder="Título do vídeo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          
          <textarea
            style={{...styles.textInput, minHeight: 80}}
            placeholder="Descrição (opcional)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          
          {id_banda && id_usuario && (
            <div style={styles.uploadTypeContainer}>
              <label style={styles.uploadTypeLabel}>
                <input
                  type="radio"
                  name="uploadType"
                  value="banda"
                  checked={uploadType === 'banda'}
                  onChange={() => setUploadType('banda')}
                  style={styles.radioInput}
                />
                <span style={styles.radioLabel}>Enviar como Banda</span>
              </label>
              <label style={styles.uploadTypeLabel}>
                <input
                  type="radio"
                  name="uploadType"
                  value="usuario"
                  checked={uploadType === 'usuario'}
                  onChange={() => setUploadType('usuario')}
                  style={styles.radioInput}
                />
                <span style={styles.radioLabel}>Enviar como Usuário</span>
              </label>
            </div>
          )}
          
          <input
            type="file"
            ref={videoInputRef}
            onChange={handleVideoSelection}
            accept="video/*"
            style={{ display: 'none' }}
            id="video-upload"
          />
          
          {!videoPreview ? (
            <div style={styles.videoSelectionContainer} onClick={triggerFileInput}>
              <span style={styles.videoSelectionText}>
                Clique para selecionar um vídeo
              </span>
              <p style={styles.videoNote}>
                Formatos aceitos: MP4, MOV, AVI (máx. 500MB)
              </p>
            </div>
          ) : (
            <div style={styles.videoPreviewContainer}>
              <video 
                src={videoPreview}
                style={styles.videoPreview}
                controls
              />
              <div style={styles.videoFileInfo}>
                <span style={styles.videoFileName}>
                  {videoFile?.name}
                </span>
                <span style={styles.videoFileSize}>
                  {(videoFile?.size ? (videoFile.size / (1024 * 1024)).toFixed(2) : 0)} MB
                </span>
              </div>
              <button 
                style={styles.changeVideoButton}
                onClick={triggerFileInput}
              >
                Trocar vídeo
              </button>
            </div>
          )}
          
          {isUploading && (
            <div style={styles.progressContainer}>
              <div 
                style={{
                  ...styles.progressBar,
                  width: `${uploadProgress}%`
                }}
              />
              <span style={styles.progressText}>{uploadProgress}% concluído</span>
            </div>
          )}
          
          <button 
            onClick={handleUploadVideo} 
            style={{
              ...styles.uploadButton,
              opacity: isUploading ? 0.7 : 1,
              cursor: isUploading ? 'not-allowed' : 'pointer'
            }}
            disabled={isUploading}
          >
            <span style={styles.uploadButtonText}>
              {isUploading ? 'Enviando...' : 'Enviar Vídeo'}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: '#151922',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '600px',
    width: '90%',
    maxHeight: '90vh',
    overflowY: 'auto',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    color: 'white',
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: '#151922',
  },
  headerTitle: {
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  textInput: {
    width: '100%',
    padding: 15,
    borderColor: '#444',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 20,
    color: 'white',
    fontSize: 16,
    marginBottom: 20,
    backgroundColor: '#000',
  },
  uploadTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 20,
    backgroundColor: '#0F131A',
    padding: 15,
    borderRadius: 10,
  },
  uploadTypeLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    cursor: 'pointer',
    color: 'white',
  },
  radioInput: {
    marginRight: 10,
    cursor: 'pointer',
  },
  radioLabel: {
    fontSize: 14,
  },
  videoSelectionContainer: {
    width: '100%',
    minHeight: 150,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: '#444',
    borderStyle: 'dashed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    backgroundColor: '#000',
    cursor: 'pointer',
    padding: 20,
  },
  videoSelectionText: {
    color: 'white',
    fontSize: 18,
    marginBottom: 10,
  },
  videoNote: {
    color: '#888',
    fontSize: 14,
    textAlign: 'center',
  },
  videoPreviewContainer: {
    width: '100%',
    marginBottom: 20,
    backgroundColor: '#000',
    borderRadius: 20,
    padding: 15,
    borderColor: '#444',
    borderStyle: 'solid',
    borderWidth: 2,
  },
  videoPreview: {
    width: '100%',
    maxHeight: 300,
    objectFit: 'contain',
    borderRadius: 15,
    marginBottom: 10,
    backgroundColor: '#000',
  },
  videoFileInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
  },
  videoFileName: {
    color: 'white',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
  },
  videoFileSize: {
    color: '#888',
    fontSize: 14,
  },
  changeVideoButton: {
    backgroundColor: '#333',
    color: 'white',
    padding: '8px 15px',
    borderRadius: 15,
    border: 'none',
    cursor: 'pointer',
    fontSize: 14,
  },
  uploadButton: {
    backgroundColor: '#914100',
    padding: 15,
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 30,
    width: '70%',
    cursor: 'pointer',
    border: 'none',
  },
  uploadButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
  progressContainer: {
    width: '100%',
    height: 20,
    backgroundColor: '#333',
    borderRadius: 10,
    marginBottom: 20,
    position: 'relative',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#914100',
    transition: 'width 0.3s ease',
  },
  progressText: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 12,
  }
};

export default UploadVideoVod;