import React, { useState, CSSProperties, useEffect } from 'react';
import axios from '../Services/axiosInterceptor'; 

interface FotosAdicionarProps {
    idBanda: number;
    token: string;
}

interface Foto {
    id: number;
    url_foto: string;
    legenda: string; // Adicionada a propriedade legenda
}

interface Album {
    id_album_foto: number;
    nome: string;
    descricao: string;
}

// Interface para controlar o estado de edição de legenda
interface LegendaEditavel {
    fotoId: number;
    editando: boolean;
    valor: string;
}

// Interface para representar a resposta da API
interface FotoResponse {
    id_foto: number;
    id_album_foto: number;
    caminho_arquivo: string;
    titulo: string | null;
    descricao: string | null;
    url_foto: string;
    legenda?: string;
    // Outras propriedades que venham da API
}

const FotosAdicionar: React.FC<FotosAdicionarProps> = ({ idBanda, token }) => {
    const [nomeAlbum, setNomeAlbum] = useState('');
    const [descricaoAlbum, setDescricaoAlbum] = useState('');
    const [albumSalvo, setAlbumSalvo] = useState<Album | null>(null);
    const [fotosParaUpload, setFotosParaUpload] = useState<{arquivo: File, legenda: string}[]>([]); // Estado para guardar fotos com legendas
    const [uploadProgress, setUploadProgress] = useState(0);
    const [albuns, setAlbuns] = useState<Album[]>([]);
    const [fotosDoAlbumSelecionado, setFotosDoAlbumSelecionado] = useState<Foto[]>([]);
    const [albumSelecionado, setAlbumSelecionado] = useState<Album | null>(null);
    const [confirmarExclusao, setConfirmarExclusao] = useState(false);
    const [legendaEditavel, setLegendaEditavel] = useState<LegendaEditavel | null>(null); // Estado para controlar a edição de legendas
    const [fotoParaExcluir, setFotoParaExcluir] = useState<number | null>(null);

    useEffect(() => {
        buscarAlbuns();
    }, [idBanda, token]);

    const buscarAlbuns = async () => {
        try {
            const response = await axios.get(`https://tribhus.shop:5000/fotos/albuns`, {
                params: {
                    id_proprietario: idBanda,
                    tipo_proprietario: 'banda'
                },
                headers: { Authorization: `Bearer ${token}` }
            });
            setAlbuns(response.data);
        } catch (error) {
            console.error('Erro ao buscar álbuns:', error);
        }
    };

    const buscarFotosDoAlbum = async (id_album_foto: number) => {
        try {
            const response = await axios.get(`https://tribhus.shop:5000/fotos/fotos/${id_album_foto}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            console.log('Resposta da API das fotos:', response.data);
            
            // Mapear id_foto para id para manter a interface Foto consistente
            const fotosFormatadas = response.data.map((foto: FotoResponse) => ({
                id: foto.id_foto,
                url_foto: foto.url_foto,
                legenda: foto.legenda || ''
            }));
            
            setFotosDoAlbumSelecionado(fotosFormatadas);
        } catch (error) {
            console.error('Erro ao buscar fotos do álbum:', error);
        }
    };

    const salvarAlbum = async () => {
        try {
            const response = await axios.post(`https://tribhus.shop:5000/fotos/album/${idBanda}`, {
                nome: nomeAlbum,
                descricao: descricaoAlbum,
                id_proprietario: idBanda,
                tipo_proprietario: 'banda'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const novoAlbum = {
                id_album_foto: response.data.id_album_foto,
                nome: nomeAlbum,
                descricao: descricaoAlbum
            };
            setAlbuns([...albuns, novoAlbum]);
            setAlbumSelecionado(novoAlbum);
            setFotosDoAlbumSelecionado([]);
            setAlbumSalvo(novoAlbum);
            setNomeAlbum('');
            setDescricaoAlbum('');
        } catch (error) {
            console.error('Erro ao salvar álbum:', error);
        }
    };

    const adicionarFotos = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const novasFotos = Array.from(event.target.files).map(arquivo => ({
                arquivo,
                legenda: ''
            }));
            setFotosParaUpload([...fotosParaUpload, ...novasFotos]);
        }
    };

    // Função para atualizar a legenda de uma foto antes do upload
    const atualizarLegendaPreUpload = (index: number, legenda: string) => {
        const novasFotos = [...fotosParaUpload];
        novasFotos[index].legenda = legenda;
        setFotosParaUpload(novasFotos);
    };

    const uploadFoto = async (fotoComLegenda: {arquivo: File, legenda: string}) => {
        if (!albumSelecionado) {
            console.error('Nenhum álbum selecionado');
            return;
        }

        const formData = new FormData();
        formData.append('foto', fotoComLegenda.arquivo);
        formData.append('legenda', fotoComLegenda.legenda); // Enviando a legenda para o backend

        try {
            const response = await axios.post(`https://tribhus.shop:5000/fotos/foto/${idBanda}/${albumSelecionado.id_album_foto}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
                    setUploadProgress(percentCompleted);
                }
            });
            // Adicionar a nova foto com a legenda à lista de fotos do álbum
            setFotosDoAlbumSelecionado([...fotosDoAlbumSelecionado, { 
                id: response.data.id_foto, 
                url_foto: response.data.url_foto,
                legenda: fotoComLegenda.legenda
            }]);
        } catch (error) {
            console.error('Erro ao fazer upload da foto:', error);
        }
    };

    const salvarFotos = async () => {
        if (!albumSelecionado) {
            console.error('Nenhum álbum selecionado');
            return;
        }
        for (let foto of fotosParaUpload) {
            await uploadFoto(foto);
        }
        setFotosParaUpload([]);
        setUploadProgress(0);
    };

    const selecionarAlbum = (album: Album) => {
        setAlbumSelecionado(album);
        buscarFotosDoAlbum(album.id_album_foto);
        setAlbumSalvo(null);
    };

    const excluirAlbum = async () => {
        if (!albumSelecionado) return;

        try {
            await axios.delete(`https://tribhus.shop:5000/fotos/album/${idBanda}/${albumSelecionado.id_album_foto}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAlbuns(albuns.filter(album => album.id_album_foto !== albumSelecionado.id_album_foto));
            setAlbumSelecionado(null);
            setFotosDoAlbumSelecionado([]);
            setConfirmarExclusao(false);
            setAlbumSalvo(null);
        } catch (error) {
            console.error('Erro ao excluir álbum:', error);
        }
    };

    // Iniciar edição de legenda
    const iniciarEdicaoLegenda = (foto: Foto) => {
        setLegendaEditavel({
            fotoId: foto.id,
            editando: true,
            valor: foto.legenda || ''
        });
    };

    const excluirFoto = async (idFoto: number) => {
        if (!albumSelecionado) return;
        
        try {
            await axios.delete(`https://tribhus.shop:5000/fotos/fotoex/${idBanda}/${idFoto}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            // Atualizar o estado local removendo a foto excluída
            setFotosDoAlbumSelecionado(fotosDoAlbumSelecionado.filter(foto => foto.id !== idFoto));
        } catch (error) {
            console.error('Erro ao excluir foto:', error);
        }
    };

    // Salvar legenda editada
    const salvarLegenda = async () => {
        if (!legendaEditavel || !albumSelecionado) return;

        try {
            await axios.put(`https://tribhus.shop:5000/fotos/foto/legenda/${legendaEditavel.fotoId}`, {
                legenda: legendaEditavel.valor
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Atualizar o estado local com a nova legenda
            setFotosDoAlbumSelecionado(fotosDoAlbumSelecionado.map(foto => 
                foto.id === legendaEditavel.fotoId 
                    ? { ...foto, legenda: legendaEditavel.valor } 
                    : foto
            ));

            // Resetar o estado de edição
            setLegendaEditavel(null);
        } catch (error) {
            console.error('Erro ao atualizar legenda:', error);
        }
    };

    const cancelarEdicaoLegenda = () => {
        setLegendaEditavel(null);
    };

    // Estilos (mantendo os mesmos do componente original)
    const styles: { [key: string]: CSSProperties } = {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#151922',
            padding: '20px',
            minHeight: '100vh',
        },
        leftColumn: {
            width: '40%',
            backgroundColor: '#1E2736',
            padding: '20px',
        },
        rightColumn: {
            width: '55%',
            backgroundColor: '#1E2736',
            padding: '20px',
            marginTop: '50px',
            marginLeft: '50px',
        },
        title: {
            fontSize: '20px',
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'left',
        },
        subtitulo: {
            fontSize: '20px',
            color: '#FF6600',
            fontWeight: 'bold',
            textAlign: 'left',
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#30343f',
            padding: '20px',
            borderRadius: '10px',
            marginBottom: '20px',
            cursor: 'pointer',
        },
        button: {
            backgroundColor: '#FF6600',
            padding: '10px 20px',
            borderRadius: '5px',
            color: '#fff1e5',
            fontSize: '14px',
            border: 'none',
            cursor: 'pointer',
            marginTop: '10px',
        },
        input: {
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            backgroundColor: '#2C3E50',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
        },
        paragraph: {
            color: '#fff1e5',
            fontSize: '16px',
            marginBottom: '10px',
        },
        fotosPreview: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            marginTop: '20px',
        },
        fotoPreview: {
            width: '180px',
            height: '180px',
            objectFit: 'cover',
            borderRadius: '20px',
        },
        albumDetailsText: {
            fontSize: '16px',
            color: 'white',
        },
        confirmacaoExclusao: {
            backgroundColor: '#30343f',
            padding: '15px',
            borderRadius: '5px',
            marginTop: '10px',
        },
        confirmacaoTexto: {
            color: 'white',
            marginBottom: '10px',
        },
        albumHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
        },
        excluirButton: {
            backgroundColor: 'transparent',
            color: '#FF4136',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            padding: '5px 10px',
        },
        adicionarFotosSection: {
            marginTop: '20px',
            padding: '15px',
            backgroundColor: '#30343f',
            borderRadius: '5px',
        },
        adicionarFotosTitulo: {
            fontSize: '16px',
            color: '#FF6600',
            marginBottom: '10px',
        },
        fileInput: {
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            backgroundColor: '#2C3E50',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
        },
        uploadButton: {
            backgroundColor: '#FF6600',
            padding: '10px 20px',
            borderRadius: '5px',
            color: '#fff1e5',
            fontSize: '14px',
            border: 'none',
            cursor: 'pointer',
        },
        confirmarExclusaoButton: {
            backgroundColor: '#FF4136',
            padding: '10px 20px',
            borderRadius: '5px',
            color: '#fff1e5',
            fontSize: '14px',
            border: 'none',
            cursor: 'pointer',
            marginRight: '10px',
        },
        cancelarExclusaoButton: {
            backgroundColor: '#30343f',
            padding: '10px 20px',
            borderRadius: '5px',
            color: '#fff1e5',
            fontSize: '14px',
            border: '1px solid #fff1e5',
            cursor: 'pointer',
        },
        // Estilos para as novas funcionalidades de legenda
        fotoContainer: {
            position: 'relative',
            width: '180px',
            marginBottom: '20px',
        },
        legenda: {
            marginTop: '5px',
            fontSize: '14px',
            color: 'white',
            wordBreak: 'break-word',
        },
        legendaInput: {
            width: '100%',
            padding: '5px',
            marginTop: '5px',
            backgroundColor: '#2C3E50',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
            fontSize: '14px',
        },
        legendaBotoes: {
            display: 'flex',
            justifyContent: 'space-between',  // Espaça os botões igualmente
            marginTop: '8px',
            width: '100%',  // Garantir que ocupe a largura total
        },
        editarButton: {
            padding: '6px 0',
            color: '#FF6600',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '13px',
            fontWeight: '500',  // Um pouco mais de peso para melhor visibilidade
        },
        excluirFotoButton: {
            padding: '6px 0',
            color: '#FF4136',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '13px',
            fontWeight: '500',
        },
        legendaPreUpload: {
            marginTop: '5px',
            width: '100%',
            backgroundColor: '#2C3E50',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
            padding: '5px',
        },
        confirmacaoExclusaoFoto: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '20px',
        },
        confirmacaoFotoTexto: {
            color: 'white',
            marginBottom: '10px',
            textAlign: 'center',
            padding: '0 10px',
        },
        botoesFotoExclusao: {
            display: 'flex',
            gap: '10px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.leftColumn}>
                <h2 style={styles.title}>Adicionar Álbum de Fotos</h2>
                {!albumSalvo ? (
                    <div style={styles.box}>
                        <input
                            style={styles.input}
                            type="text"
                            value={nomeAlbum}
                            onChange={(e) => setNomeAlbum(e.target.value)}
                            placeholder="Nome do Álbum"
                        />
                        <textarea
                            style={{ ...styles.input, height: '100px' }}
                            value={descricaoAlbum}
                            onChange={(e) => setDescricaoAlbum(e.target.value)}
                            placeholder="Descrição do Álbum"
                        />
                        <button style={styles.button} onClick={salvarAlbum}>Criar Álbum</button>
                    </div>
                ) : (
                    <div style={styles.box}>
                        <h3 style={styles.subtitulo}>Detalhes do Álbum</h3>
                        <p style={styles.albumDetailsText}>Nome: {albumSalvo.nome}</p>
                        <p style={styles.albumDetailsText}>Descrição: {albumSalvo.descricao}</p>
                    </div>
                )}
    
                <div>
                    <h3 style={styles.title}>Álbuns Cadastrados</h3>
                    {albuns && albuns.length > 0 ? (
                        albuns.map((album) => (
                            <div
                                key={album.id_album_foto}
                                style={styles.box}
                                onClick={() => selecionarAlbum(album)}
                            >
                                <h4 style={styles.albumDetailsText}>{album.nome}</h4>
                                <p style={styles.albumDetailsText}>{album.descricao}</p>
                            </div>
                        ))
                    ) : (
                        <p style={styles.albumDetailsText}>Nenhum álbum cadastrado ainda.</p>
                    )}
                </div>
            </div>
      
            {albumSelecionado && (
                <div style={styles.rightColumn}>
                    <div style={styles.albumHeader}>
                        <h3 style={styles.title}>{albumSelecionado.nome}</h3>
                        <button
                            style={styles.excluirButton}
                            onClick={() => setConfirmarExclusao(true)}
                        >
                            Excluir Álbum
                        </button>
                    </div>
                    <p style={styles.albumDetailsText}>{albumSelecionado.descricao}</p>
          
                    {confirmarExclusao && (
                        <div style={styles.confirmacaoExclusao}>
                            <p style={styles.confirmacaoTexto}>Tem certeza que deseja excluir este álbum e todas as suas fotos?</p>
                            <button
                                style={styles.confirmarExclusaoButton}
                                onClick={excluirAlbum}
                            >
                                Confirmar Exclusão
                            </button>
                            <button
                                style={styles.cancelarExclusaoButton}
                                onClick={() => setConfirmarExclusao(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    )}
          
                    <div style={styles.adicionarFotosSection}>
                        <h4 style={styles.adicionarFotosTitulo}>Adicione fotos ao seu álbum</h4>
                        <input
                            style={styles.fileInput}
                            type="file"
                            onChange={adicionarFotos}
                            multiple
                            accept="image/*"
                        />
                        
                        {/* Lista de fotos para upload com campo de legenda */}
                        {fotosParaUpload.length > 0 && (
                            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <h4>Fotos selecionadas:</h4>
                                {fotosParaUpload.map((foto, index) => (
                                    <div key={index} style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: '10px' }}>
                                            <img 
                                                src={URL.createObjectURL(foto.arquivo)} 
                                                alt={`Preview ${index}`} 
                                                style={{ width: '80px', height: '80px', objectFit: 'cover' }} 
                                            />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <p>{foto.arquivo.name}</p>
                                            <input
                                                type="text"
                                                placeholder="Adicionar legenda"
                                                value={foto.legenda}
                                                onChange={(e) => atualizarLegendaPreUpload(index, e.target.value)}
                                                style={styles.legendaPreUpload}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        
                        <button style={styles.uploadButton} onClick={salvarFotos}>Upload Fotos</button>
                    </div>
                    {uploadProgress > 0 && <p style={styles.paragraph}>Progresso: {uploadProgress}%</p>}
                    
                    {/* Galeria de fotos do álbum com legendas */}
                    <div style={styles.fotosPreview}>
                        {fotosDoAlbumSelecionado.map((foto, index) => (
                            <div key={index} style={styles.fotoContainer}>
                                <img src={foto.url_foto} alt={`Foto ${index + 1}`} style={styles.fotoPreview} />
                                
                                {/* Exibição/edição de legenda */}
                                {legendaEditavel && legendaEditavel.fotoId === foto.id ? (
    <div>
        <input
            type="text"
            value={legendaEditavel.valor}
            onChange={(e) => setLegendaEditavel({
                ...legendaEditavel,
                valor: e.target.value
            })}
            style={styles.legendaInput}
        />
<div style={styles.legendaBotoes}>
    <button onClick={salvarLegenda} style={{...styles.editarButton, color: '#4CAF50'}}>
        Salvar
    </button>
    <button 
        onClick={cancelarEdicaoLegenda} 
        style={{...styles.editarButton}}
    >
        Cancelar
    </button>
</div>
    </div>
) : (
    <div>
        <p style={styles.legenda}>
            {foto.legenda || 'Sem legenda'}
        </p>
        <div style={styles.legendaBotoes}>
            <button 
                onClick={() => iniciarEdicaoLegenda(foto)} 
                style={styles.editarButton}
            >
                Editar Legenda
            </button>
            <button 
                onClick={() => setFotoParaExcluir(foto.id)} 
                style={styles.excluirFotoButton}
            >
                Excluir Foto
            </button>
        </div>
    </div>
)}
                                
                                {/* Modal de confirmação de exclusão */}
                                {fotoParaExcluir === foto.id && (
                                    <div style={styles.confirmacaoExclusaoFoto}>
                                        <p style={styles.confirmacaoFotoTexto}>Tem certeza que deseja excluir esta foto?</p>
                                        <div style={styles.botoesFotoExclusao}>
                                            <button
                                                style={{...styles.confirmarExclusaoButton, padding: '5px 10px'}}
                                                onClick={() => {
                                                    excluirFoto(foto.id);
                                                    setFotoParaExcluir(null);
                                                }}
                                            >
                                                Sim
                                            </button>
                                            <button
                                                style={{...styles.cancelarExclusaoButton, padding: '5px 10px'}}
                                                onClick={() => {
                                                    setFotoParaExcluir(null);
                                                }}
                                            >
                                                Não
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FotosAdicionar;