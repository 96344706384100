import React, { useContext, useState, useEffect } from 'react';
import axios from '../Services/axiosInterceptor';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { RegisterContext } from '../context/RegisterContext';
import BotaoAjuda from '../Components/BotaoAjuda'; 
import Modal from 'react-modal';

const QuartaTela = () => {
  const { state, dispatch } = useContext(RegisterContext);
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState<string>('');
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [redirectMessage, setRedirectMessage] = useState('');
  const [preRegistrationComplete, setPreRegistrationComplete] = useState(false);
  
  // Estados para controlar a visibilidade das senhas
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLoginPasswordVisibility, setShowLoginPasswordVisibility] = useState(false);

  useEffect(() => {
    const storedAuthId = localStorage.getItem('auth_id');
    console.log('Stored auth_id on component mount:', storedAuthId);
  }, []);

  const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkSlugAvailability = async (): Promise<boolean> => {
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/check-slug', { slug: state.slug });
      if (response.data.message === "Nome de Usuário já está em uso, por favor escolha outro.") {
        setErrorMsg(response.data.message);
        return false;
      }
      setErrorMsg(null);
      return true;
    } catch (error) {
      console.error(error);
      setErrorMsg('Ocorreu um problema ao verificar a disponibilidade do nome de usuário.');
      return false;
    }
  };

  const validatePassword = (): boolean => {
    if (state.senha.length < 8) {
      setErrorMsg('A senha deve ter no mínimo 8 caracteres.');
      return false;
    }
    if (state.senha !== confirmPassword) {
      setErrorMsg('As senhas não correspondem!');
      return false;
    }
    return true;
  };
  
  // Funções para alternar a visibilidade das senhas
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const toggleLoginPasswordVisibility = () => {
    setShowLoginPasswordVisibility(!showLoginPasswordVisibility);
  };
  
  const handleNext = async () => {
    if (preRegistrationComplete) {
      // Em vez de mostrar o modal, navegamos direto para a próxima tela
      navigate('/ValidacaoUser');
      return;
    }
  
    if (!validateEmail(state.email)) {
      setErrorMsg('Por favor, insira um email válido!');
      return;
    }
    
    const isSlugAvailable = await checkSlugAvailability();
    if (!isSlugAvailable || !validatePassword()) {
      return;
    }
  
    try {
      const userResponse = await axios.post('https://tribhus.shop:5000/users/auth', {
        email: state.email,
        senha: state.senha,
        role: 'usuario',
        slug: state.slug // Adicionado o envio do slug para o backend
      });
  
      const receivedAuthId = userResponse.data.auth_id;
      if (receivedAuthId) {
        localStorage.setItem('auth_id', receivedAuthId.toString());
        dispatch({ type: 'SET_AUTH_ID', auth_id: receivedAuthId });
      }
  
      if (userResponse.data.token) {
        localStorage.setItem('token', userResponse.data.token);
      }
  
      // Verificando se o cadastro está completo ou não
      if (userResponse.data.isComplete) {
        setPreRegistrationComplete(true);
        navigate('/ValidacaoUser');
      } else {
        // Caso o cadastro ainda não tenha sido concluído
        setPreRegistrationComplete(false);
      }
      
    } catch (error) {
      console.error('Erro durante a autenticação:', error);
  
      if (axios.isAxiosError(error) && error.response) {
        const responseData = error.response.data as { error: string, isComplete?: boolean };
        if (responseData.error === 'Cadastro já completo' && responseData.isComplete) {
          // Redirecionar para a tela de login se o cadastro já estiver completo
          handleRedirectWithDelay("Você já é cadastrado na Tribhus", '/PrimeiraTela');
        } else {
          setErrorMsg(responseData.error);
        }
      } else {
        setErrorMsg('Ocorreu um erro desconhecido.');
      }
    }
  };

  const handleRedirectWithDelay = (message: string, path: string) => {
    setRedirectMessage(message);
    setShowRedirectModal(true);
    setTimeout(() => {
      setShowRedirectModal(false);
      navigate(path);
    }, 5000);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('/users/loginusu', {
        email: loginEmail,
        senha: loginPassword,
      });

      const { auth_id, role, emailVerified } = response.data;
      localStorage.setItem('auth_id', auth_id.toString());
      dispatch({ type: 'SET_AUTH_ID', auth_id });
      setShowLoginModal(false);

      // Navega diretamente para a próxima tela sem verificar termos
      handleNavigation(emailVerified, role);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ error: string }>;
        if (axiosError.response?.data?.error) {
          const errorMessage = axiosError.response.data.error;
          if (errorMessage === "Cadastro já completo, redirecionando...") {
            handleRedirectWithDelay("Você já é cadastrado na Tribhus", '/PrimeiraTela');
          } else {
            setLoginError(errorMessage);
          }
        } else {
          setLoginError("Erro na resposta do servidor");
        }
      } else {
        setLoginError("Não foi possível conectar ao servidor.");
      }
    }
  };

  const handleNavigation = (emailVerified: boolean, role: string) => {
    console.log('Navegando para validação:', { emailVerified, role });
    navigate('/ValidacaoUser');
  };

  return (
    <div style={styles.container}>
      <Modal
        isOpen={showLoginModal}
        onRequestClose={() => setShowLoginModal(false)}
        style={customStyles}
        contentLabel="Login Modal"
      >
        <button onClick={() => setShowLoginModal(false)} style={styles.closeButton}>X</button>
        <h2>Email já cadastrado, por favor, faça o login e continue o cadastro.</h2>
        <input
          style={styles.input}
          onChange={(e) => setLoginEmail(e.target.value)}
          value={loginEmail}
          placeholder="Email"
          type="email"
        />
        <div style={styles.passwordInputContainer}>
          <input
            style={styles.passwordInput}
            onChange={(e) => setLoginPassword(e.target.value)}
            value={loginPassword}
            placeholder="Senha"
            type={showLoginPasswordVisibility ? "text" : "password"}
          />
          <button 
            type="button" 
            style={styles.passwordToggleButton}
            onClick={toggleLoginPasswordVisibility}
            aria-label={showLoginPasswordVisibility ? "Ocultar senha" : "Mostrar senha"}
          >
            {showLoginPasswordVisibility ? "👁️" : "👁️‍🗨️"}
          </button>
        </div>
        {loginError && <p style={styles.errorText}>{loginError}</p>}
        <button onClick={handleLogin} style={styles.button}>Login</button>
      </Modal>
      
      {/* Modal de redirecionamento */}
      <Modal
        isOpen={showRedirectModal}
        style={customStyles}
        contentLabel="Redirect Modal"
      >
        <h2>{redirectMessage}</h2>
        <p>Redirecionando para tela de login...</p>
      </Modal>

      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
      <button onClick={() => navigate(-1)} style={styles.backButton}>
        <img src={require('../assets/voltar.png')} alt="Voltar" style={styles.backIcon} />
      </button>
      <h1 style={styles.title}>Vamos começar cadastrando seus dados...</h1>
      
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          onChange={(e) => {
            const newSlug = e.target.value.toLowerCase().replace(/[^a-z0-9_]/g, '');
            dispatch({ type: 'SET_FORM', field: 'slug', value: newSlug });
          }}
          value={state.slug}
          placeholder="Escolha seu Nome de Usuário"
        />
        <input
          style={styles.input}
          onChange={(e) => dispatch({ type: 'SET_FORM', field: 'email', value: e.target.value })}
          value={state.email}
          placeholder="Email"
          type="email"
        />
        
        {/* Campo de senha com botão toggle */}
        <div style={styles.passwordInputContainer}>
          <input
            style={styles.passwordInput}
            onChange={(e) => dispatch({ type: 'SET_FORM', field: 'senha', value: e.target.value })}
            value={state.senha}
            placeholder="Senha"
            type={showPassword ? "text" : "password"}
          />
          <button 
            type="button" 
            style={styles.passwordToggleButton}
            onClick={togglePasswordVisibility}
            aria-label={showPassword ? "Ocultar senha" : "Mostrar senha"}
          >
            {showPassword ? "👁️" : "👁️‍🗨️"}
          </button>
        </div>
        
        {/* Campo de confirmação de senha com botão toggle */}
        <div style={styles.passwordInputContainer}>
          <input
            style={styles.passwordInput}
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            placeholder="Confirmar Senha"
            type={showConfirmPassword ? "text" : "password"}
          />
          <button 
            type="button" 
            style={styles.passwordToggleButton}
            onClick={toggleConfirmPasswordVisibility}
            aria-label={showConfirmPassword ? "Ocultar senha" : "Mostrar senha"}
          >
            {showConfirmPassword ? "👁️" : "👁️‍🗨️"}
          </button>
        </div>
      </div>
           
      {errorMsg && <p style={styles.errorText}>{errorMsg}</p>}
     
      <button
        style={styles.button}
        onClick={handleNext}
      >
        {preRegistrationComplete ? 'Continuar' : 'Próximo'}
      </button>
      {/* Botão de Ajuda com a tela atual */}
      <BotaoAjuda telaAtual="QuartaTela" />
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#151922', // Mesma cor de fundo que no React Native
    padding: '20px',
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain',
    marginBottom: '20px',
  },
  backButton: {
    position: 'absolute' as 'absolute',
    top: '10px',
    left: '10px',
    background: 'none',
    border: 'none',
    fontSize: '12px',
    cursor: 'pointer',
  },
  backIcon: {
    width: '30px',
    height: '30px',
  },
  title: {
    fontSize: '20px',
    color: '#fff', // Cor do título como no React Native
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '-30px',
  },
  inputContainer: {
    width: '80%',
    maxWidth: '300px',
    marginBottom: '30px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '10px',
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: '1px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    boxSizing: 'border-box',
  },
  passwordInputContainer: {
    position: 'relative',
    width: '100%',
    marginBottom: '15px',
  },
  passwordInput: {
    width: '100%',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: '1px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    boxSizing: 'border-box',
  },
  passwordToggleButton: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#914100',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    padding: '0',
    zIndex: 1,
  },
  button: {
    backgroundColor: '#914100',
    padding: '15px 50px',
    borderRadius: '10px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
    width: '200px',
    margin: '10px auto',
  },
  buttonText: {
    color: '#fff',
    fontSize: '16px',
  },
  suggestionsContainer: {
    width: '80%',
    marginBottom: '20px',
  },
  errorText: {
    color: '#FF6600',
    fontSize: '14px',
    textAlign: 'center',
    //marginBottom: '10px',
  },
  modalOverlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparência como no React Native
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    backgroundColor: 'lightgray',
    padding: '35px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    position: 'relative',
    width: '80%',
    maxWidth: '500px',
  },
  modalText: {
    fontSize: '18px',
    color: '#000',
    textAlign: 'center',
    marginBottom: '15px',
  },
  textInputModal: {
    width: '240px',
    padding: '10px',
    marginBottom: '10px',
    backgroundColor: '#151922',
    color: 'white',
    borderRadius: '5px',
    border: '1px solid #914100',
  },
  buttonStyle: {
    backgroundColor: '#914100',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '10px',
    width: '200px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute' as const,
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '20px', // Ajustado para combinar com o React Native
    backgroundColor: 'lightgray',
  },
};

export default QuartaTela;