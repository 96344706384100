import React, { createContext, useReducer, useEffect, ReactNode, Dispatch } from 'react';
import { Howl } from 'howler';

interface Music {
    id_musica: number;
    id_banda: number;
    id_album: number;
    nome_musica: string;
    url_musica: string;
    url_imagem: string;
    nome_banda: string;
    likes_count: number;
    has_liked: boolean;
    user_or_banda_liked_id: number | null;
}

interface StateType {
    track: Music | null;
    playlist: Music[];
    lastPlayedIndex: number;
    isPlaying: boolean;
    isMiniPlayerVisible: boolean;
    howl: Howl | null;
    currentTime: number;
    duration: number;
    volume: number;
    isMuted: boolean;
}

type ActionType =
    | { type: 'SET_TRACK'; payload: Music | null }
    | { type: 'SET_PLAYLIST'; payload: Music[] }
    | { type: 'ADD_TO_PLAYLIST'; payload: Music[] }
    | { type: 'ADD_TO_QUEUE'; payload: Music[] }
    | { type: 'SET_LAST_PLAYED_INDEX'; payload: number }
    | { type: 'TOGGLE_PLAY' }
    | { type: 'NEXT_TRACK' }
    | { type: 'PREV_TRACK' }
    | { type: 'CLOSE_MINIPLAYER' }
    | { type: 'SET_CURRENT_TIME'; payload: number }
    | { type: 'SET_DURATION'; payload: number }
    | { type: 'SET_HOWL'; payload: Howl | null }
    | { type: 'SET_VOLUME'; payload: number }
    | { type: 'TOGGLE_MUTE' };

const initialState: StateType = {
        track: null,
        playlist: [],
        lastPlayedIndex: -1,
        isPlaying: false,
        isMiniPlayerVisible: false,
        howl: null,
        currentTime: 0,
        duration: 0,
        volume: 1,
        isMuted: false,
    };
    
    function musicReducer(state: StateType, action: ActionType): StateType {
        switch (action.type) {
        case 'SET_TRACK':
            return {
                ...state,
                track: action.payload,
                howl: null,  // Vamos criar o Howl fora do reducer
                isPlaying: action.payload ? true : false,
                isMiniPlayerVisible: action.payload ? true : false,
                currentTime: 0,
                duration: 0,
            };
            case 'SET_PLAYLIST':
                const uniquePlaylist = action.payload.filter((track, index, self) =>
                    index === self.findIndex((t) => t.id_musica === track.id_musica)
                );
                return {
                    ...state,
                    playlist: uniquePlaylist,
                    lastPlayedIndex: 0,
                };
        case 'ADD_TO_PLAYLIST':
            return {
                ...state,
                playlist: [...state.playlist, ...action.payload],
            };
        case 'TOGGLE_PLAY':
            return {
                ...state,
                isPlaying: !state.isPlaying,
            };
        case 'CLOSE_MINIPLAYER':
            return {
                ...state,
                isMiniPlayerVisible: false,
                isPlaying: false,
                howl: null,
                currentTime: 0,
                duration: 0,
            };
            case 'SET_CURRENT_TIME':
                return {
                    ...state,
                    currentTime: action.payload,
                };
                case 'SET_DURATION':
                    console.log('Reducer - Setting duration:', action.payload);
                    return {
                        ...state,
                        duration: action.payload,
                    };
        case 'NEXT_TRACK':
                const nextIndex = (state.lastPlayedIndex + 1) % state.playlist.length;
                return {
                    ...state,
                    lastPlayedIndex: nextIndex,
                    track: state.playlist[nextIndex],
                    currentTime: 0,
                };
            case 'PREV_TRACK':
                const prevIndex = (state.lastPlayedIndex - 1 + state.playlist.length) % state.playlist.length;
                return {
                    ...state,
                    lastPlayedIndex: prevIndex,
                    track: state.playlist[prevIndex],
                    currentTime: 0,
                };
                case 'SET_HOWL':
                    return {
                        ...state,
                        howl: action.payload,
                    };
                    case 'SET_VOLUME':
                        return {
                            ...state,
                            volume: action.payload,
                            isMuted: action.payload === 0,
                        };
                    case 'TOGGLE_MUTE':
                        return {
                            ...state,
                            isMuted: !state.isMuted,
                        };
                    default:
                        return state;
                }
            }
        
        function step(sound: Howl | null, dispatch: Dispatch<ActionType>) {
            if (sound && sound.playing()) {
                const seek = sound.seek() as number;
                const duration = sound.duration();
                console.log('Step - Current time:', seek, 'Duration:', duration);
                dispatch({ type: 'SET_CURRENT_TIME', payload: seek });
                // Não atualizamos a duração aqui para evitar possíveis flutuações
                requestAnimationFrame(() => step(sound, dispatch));
            }
        }

interface MusicContextType {
    state: StateType;
    dispatch: Dispatch<ActionType>;
    selectTrack: (track: Music) => void;
    setPlaylist: (tracks: Music[]) => void;
    addToPlaylist: (tracks: Music[]) => void;
    togglePlay: () => void;
    seekTo: (time: number) => void;
    nextTrack: () => void;
    prevTrack: () => void;
    setVolume: (volume: number) => void;
    toggleMute: () => void;
}

const MusicContext = createContext<MusicContextType | undefined>(undefined);

export const MusicProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(musicReducer, initialState);

    useEffect(() => {
        if (state.track && state.track.id_musica) {
            console.log("🎵 Música carregada no contexto:", state.track);
        }
    }, [state.track]);
       
    const selectTrack = (track: Music) => {
        if (state.howl) {
            state.howl.unload();
        }
    
        // Primeiro atualiza o estado com a track
        dispatch({ type: 'SET_TRACK', payload: track });
    
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        fetch(track.url_musica)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                const duration = audioBuffer.duration;
                console.log('Audio duration from Web Audio API:', duration);
                dispatch({ type: 'SET_DURATION', payload: duration });
    
                // Cria e configura o Howl depois de confirmar que o áudio é válido
                const newHowl = new Howl({
                    src: [track.url_musica],
                    html5: true,
                    onplay: function(this: Howl) {
                        requestAnimationFrame(() => step(this, dispatch));
                    },
                    onload: function () {
                        console.log('Track loaded:', track.url_musica);
                        
                        // Tenta tocar imediatamente
                        const playAttempt = newHowl.play();
                        
                        // Se o playAttempt for undefined, significa que o navegador bloqueou
                        if (playAttempt === undefined) {
                            console.log('Autoplay bloqueado, aguardando interação do usuário...');
                            const userInteraction = new Promise(resolve => {
                                document.addEventListener("click", resolve, { once: true });
                            });
                        
                            userInteraction.then(() => {
                                newHowl.play();
                                dispatch({ type: 'TOGGLE_PLAY' });
                            });
                        } else {
                            // Se conseguiu tocar, atualiza o estado
                            dispatch({ type: 'TOGGLE_PLAY' });
                        }
                    },            
                    onloaderror: function(_id, err) {
                        console.error('Error loading audio:', err);
                    },
                    onend: () => {
                        nextTrack();
                    }
                });
    
                // Atualiza o Howl no estado
                dispatch({ type: 'SET_HOWL', payload: newHowl });
            })
            .catch(error => {
                console.error("Erro ao carregar áudio:", error);
            });
    };
    
    const setPlaylist = (tracks: Music[]) => {
        dispatch({ type: 'SET_PLAYLIST', payload: tracks });
    };

    const addToPlaylist = (tracks: Music[]) => {
        const uniqueTracks = tracks.filter(newTrack => 
            !state.playlist.some(existingTrack => existingTrack.id_musica === newTrack.id_musica)
        );
        if (uniqueTracks.length > 0) {
            dispatch({ type: 'ADD_TO_PLAYLIST', payload: uniqueTracks });
        }
    };

    const togglePlay = () => {
        if (state.howl) {
            if (state.isPlaying) {
                state.howl.pause();
            } else {
                state.howl.play();
            }
            dispatch({ type: 'TOGGLE_PLAY' });
        }
    };

    const seekTo = (time: number) => {
        if (state.howl) {
            state.howl.seek(time);
            dispatch({ type: 'SET_CURRENT_TIME', payload: time });
            // Reinicie a animação do progresso
            requestAnimationFrame(() => {
                if (state.howl) {
                    step(state.howl, dispatch);
                }
            });
        }
    };

    const nextTrack = () => {
        dispatch({ type: 'NEXT_TRACK' });
        if (state.playlist[state.lastPlayedIndex + 1]) {
            selectTrack(state.playlist[state.lastPlayedIndex + 1]);
        }
    };

    const prevTrack = () => {
        dispatch({ type: 'PREV_TRACK' });
        if (state.playlist[state.lastPlayedIndex - 1]) {
            selectTrack(state.playlist[state.lastPlayedIndex - 1]);
        }
    };

    const setVolume = (volume: number) => {
        if (state.howl) {
            state.howl.volume(volume);
        }
        dispatch({ type: 'SET_VOLUME', payload: volume });
    };

    const toggleMute = () => {
        if (state.howl) {
            const newMuteState = !state.isMuted;
            state.howl.mute(newMuteState);
            dispatch({ type: 'TOGGLE_MUTE' });
        }
    };

    useEffect(() => {
        if (state.howl) {
            state.howl.volume(state.isMuted ? 0 : state.volume);
        }
    }, [state.volume, state.isMuted, state.howl]);

    return (
        <MusicContext.Provider value={{ 
            state, 
            dispatch, 
            selectTrack, 
            setPlaylist, 
            addToPlaylist, 
            togglePlay, 
            seekTo,
            nextTrack,
            prevTrack,
            setVolume,
            toggleMute
        }}>
            {children}
        </MusicContext.Provider>
    );
};

export default MusicContext;