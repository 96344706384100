import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { SlugContext } from '../context/SlugContext';
import { AuthContext } from '../context/AuthContext';
import { RegisterContext } from '../context/RegisterContext';
import MusicContext from '../context/MusicContext';
import TabBar from '../Components/TabBar'; 
import BotoesSociais from '../Components/BotoesSociais'; 
import DescriUsuario from '../Components/DescriUsuario';
import AlbumEstante from '../Components/AlbumEstante';
import PlaylistUser from '../Components/PlaylistUser';
import EventosConfirmados from '../Components/EventosConfirmados';
import ModalMenuOpcoes from '../Components/ModalMenuOpcoes';
import MiniPlayer from '../Components/MiniPlayer';
import ProfileAlert from '../utils/ProfileAlert'; 

interface User {
  generos: string[];
  url_capa_usuario?: string;
  url_icone_usuario?: string;
  nome?: string;
  sobrenome?: string;
  descricao?: string;
  id_usuario?: number;
  cidade?: string;
  estado?: string;
  slug?: string;
}

const TelaUsuarioPerfil: React.FC = () => {
  const [user, setUser] = useState<User>({generos: []});
  const { state: slugState } = useContext(SlugContext);
  const { dispatch } = useContext(RegisterContext); 
  const { state: authState } = useContext(AuthContext);
  const musicContext = useContext(MusicContext);
  const currentTrack = musicContext?.state.track;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [missingInfo, setMissingInfo] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isOwner, setIsOwner] = useState(false); 
  const [coverBackgroundColor, setCoverBackgroundColor] = useState("#2a2e39");

  useEffect(() => {
    // Atualiza o valor de isOwner sempre que authState ou band mudarem
    setIsOwner(authState.id_usuario === user.id_usuario);
  }, [authState.id_usuario, user.id_usuario]);

  useEffect(() => {
    const fetchData = async () => {
      let id_usuario = authState.id_usuario;

      if (slugState.slugPerfil) {
        try {
          const response = await axios.get(`https://tribhus.shop:5000/users/get-user-id/${slugState.slugPerfil}`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          id_usuario = response.data.id_usuario;
        } catch (error) {
          console.error('Erro ao buscar ID do usuário:', error);
          return;
        }
      }

      if (!id_usuario) {
        console.error("id_usuario é inválido:", id_usuario);
        return;
      }

      if (authState.token && id_usuario) {
        try {
          const response = await axios.get(`https://tribhus.shop:5000/users/${id_usuario}`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          console.log('Response data:', response.data);
          setUser(response.data);
          dispatch({
            type: 'SET_USER_PROFILE',
            id: response.data.id_usuario,
            nome: response.data.nome,
            sobrenome: response.data.sobrenome
          });
          if (response.data.url_capa_usuario) {
            dispatch({ type: 'SET_USER_COVER_URL', url: response.data.url_capa_usuario });
          }

          const missing: string[] = [];
          if (!response.data.url_icone_usuario) missing.push('photo');
          if (!response.data.descricao) missing.push('description');
          if (!response.data.generos || response.data.generos.length === 0) missing.push('genres');
          
          setMissingInfo(missing);
          setShowAlert(missing.length > 0);
        } catch (error) {
          console.error('Erro ao buscar detalhes do usuário:', error);
        }
      }
    };
  
    fetchData();
  }, [authState.id_usuario, authState.token, slugState.slugPerfil]);

   // Função para extrair a cor dominante do ícone do usuário
   const getImagePrimaryColor = (imageUrl: string): Promise<string> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Permite leitura de imagens externas
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          resolve("#2a2e39"); // Cor padrão caso o contexto seja nulo
          return;
        }

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);

        // Pegando a média de cor do canto superior esquerdo
        const pixelData = ctx.getImageData(0, 0, 1, 1).data;
        const color = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;

        resolve(color);
      };

      img.onerror = () => resolve("#2a2e39"); // Cor fallback caso a imagem falhe
    });
  };

  // Quando a página carregar, obtém a cor do ícone do usuário
  useEffect(() => {
    if (!user.url_capa_usuario && user.url_icone_usuario) {
      getImagePrimaryColor(user.url_icone_usuario).then(setCoverBackgroundColor);
    }
  }, [user]);

  return (
    <div style={styles.wrapper}>
    <div style={styles.container}>
      {showAlert && isOwner && (
        <ProfileAlert 
          missingInfo={missingInfo} 
          onClose={() => setShowAlert(false)} 
        />
      )}
    {/* Cabeçalho com imagem de capa e ícone de perfil */}
    <div style={styles.headerContainer}>

     {/* Imagem de capa com cor de fundo dinâmica */}
     <div
            style={{
              ...styles.coverImage,
              backgroundImage: user.url_capa_usuario ? `url(${user.url_capa_usuario})` : "none",
              backgroundColor: user.url_capa_usuario ? "transparent" : coverBackgroundColor,
              border: "2px solid rgba(255,255,255,0.2)", // Borda sutil
            }}
          />
      
      {/* Ícone de perfil */}
      <div style={{
        ...styles.profileIcon,
        backgroundImage: `url(${user.url_icone_usuario})`,
      }} />

      {/* Botões Sociais */}
      {user.id_usuario && (
        <BotoesSociais
          idUsuarioRecebe={user.id_usuario}
          isOwnProfile={authState.id_usuario === user.id_usuario}
        />
      )}

      {/* Botão de menu */}
      <button
        style={styles.menuButton}
        onClick={() => setIsMenuOpen(true)}
      >
        ⋮
      </button>
    </div>

      {/* Modal Menu Opcoes */}
<ModalMenuOpcoes
  isOpen={isMenuOpen}
  onClose={() => setIsMenuOpen(false)}
  isOwner={isOwner}
  itemId={user.id_usuario ?? ''}
  tipoItem="usuario"
/>

    {/* Conteúdo principal com TabBar e Feed */}
    <div style={styles.mainContent}>
      {/* Coluna do TabBar */}
      <div style={styles.tabBarColumn}>
        <TabBar currentScreen="Home" currentSlug={user.slug}/>
        {currentTrack && (
          <div style={styles.miniPlayerContainer}>
            <MiniPlayer />
          </div>
        )}
      </div>

      {/* Coluna do Feed */}
      <div style={styles.feedColumn}>
        <h1>{user.nome} {user.sobrenome}</h1>
        <div className="generos" style={styles.generosContainer}>
          {user.generos.map((genero, index) => (
            <span key={index} style={styles.genreTag}>{genero}</span>
          ))}
        </div>
        <DescriUsuario descricao={user.descricao || ''} />
        {user.id_usuario && (
          <AlbumEstante idUsuario={user.id_usuario} token={authState.token || ''} />
        )}
        {user.id_usuario && authState.token && (
          <PlaylistUser id_usuario={user.id_usuario} token={authState.token} />
        )}
        {user.id_usuario && authState.token && (
          <EventosConfirmados idUsuario={user.id_usuario} token={authState.token} />
        )}
      </div>
    </div>
  </div>
  </div>
);
}; 

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    backgroundColor: '#151922',
    minHeight: '100vh',
    width: '100%',
  },
  container: {
    backgroundColor: '#151922',
    minHeight: '100vh',
    color: 'white',
    padding: '20px',
    maxWidth: '1200px',  // ou outro valor que preferir
    margin: '0 auto',    // para centralizar
},
  headerContainer: {
    position: 'relative',
    width: '100%',
    height: '200px',
    marginBottom: '120px',
  },
  coverImage: {
    width: "100%",
    height: "120%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
  },
  profileIcon: {
    position: 'absolute',
    bottom: '-80px',
    left: '980px',
    width: '180px',
    height: '180px',
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '4px solid #151922',
    boxShadow: '0 2px 4px rgba(255,255,255,0.2)',
    marginBottom: '-30px',
  },
  menuButton: {
    position: 'absolute',
    bottom: '-85px',
    left: '1180px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#151922',
    color: 'white',
    fontSize: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  },
  mainContent: {
    display: 'flex',
    padding: '0 20px',
  },
  tabBarColumn: {
    width: '20%',
    paddingRight: '20px',
  },
  feedColumn: {
    flex: 1,
    overflowY: 'auto',
  },
  genreTag: {
    display: 'inline-block',
    backgroundColor: '#2a2e39',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '15px',
    margin: '5px',
    fontSize: '14px',
    marginBottom: '5px',
  },
  generosContainer: {
    marginBottom: '30px', // Adicione a margem inferior aqui
  },
};

export default TelaUsuarioPerfil;