import React, { useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TermosBandaDocument from '../documents/TermosBandaDocument';
import TermosUsuarioDocument from '../documents/TermosUsuarioDocument';
import BotaoAjuda from '../Components/BotaoAjuda';

type TermosParams = {
  userType?: string;
};

const TelaTermosDeUso: React.FC = () => {
  const params = useParams<TermosParams>();
  const userType = params.userType;
  const navigate = useNavigate();

  const TermsDocument = userType === 'banda' ? TermosBandaDocument : TermosUsuarioDocument;

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleAccept = () => {
    if (userType === 'banda') {
      navigate('/TelaBanda1');
    } else {
      navigate('/QuartaTela');
    }
  };

  return (
    <div style={styles.background}>
      <div style={styles.container}>
        <BotaoAjuda telaAtual="TelaTermosDeUso" />
        
        <h1 style={styles.title}>
          Termos de Uso - {userType === 'banda' ? 'Tribhus para Artistas' : 'Tribhus para Usuários'}
        </h1>
        
        <div ref={scrollContainerRef} style={styles.scrollView}>
          <TermsDocument />
        </div>
        
        <button style={styles.button} onClick={handleAccept}>
          Aceitar Termos e Continuar
        </button>
      </div>
    </div>
  );
};

const styles = {
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#151922',
    minHeight: '100vh',
    padding: '20px',
  },
  container: {
    width: '800px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
    textAlign: 'center' as const,
  },
  scrollView: {
    maxHeight: '70vh',
    width: '100%',
    marginBottom: '20px',
    overflow: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '15px',
  },
  button: {
    borderRadius: '10px',
    padding: '15px',
    backgroundColor: '#2196F3',
    color: 'white',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '300px',
  },
};

export default TelaTermosDeUso;
