import React, { useContext, useState, useEffect, useRef } from 'react';
import { BandaContext } from '../context/BandaContext';
import { useNavigate } from 'react-router-dom';
import BotaoAjuda from '../Components/BotaoAjuda'; 
import axios from 'axios';

interface Estado {
  nome: string;
  uf: string;
  codigo_uf: number;
}

interface Cidade {
  nome: string;
}

const TelaBanda3: React.FC = () => {
  const { state, dispatch } = useContext(BandaContext);
  const navigate = useNavigate();

  const [estados, setEstados] = useState<Estado[]>([]);
  const [cidades, setCidades] = useState<Cidade[]>([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState<string>('');
  const [cidadeSelecionada, setCidadeSelecionada] = useState<string>('');
  const [modalVisibleEstado, setModalVisibleEstado] = useState<boolean>(false);
  const [modalVisibleCidade, setModalVisibleCidade] = useState<boolean>(false);
  const [nomeEstadoSelecionado, setNomeEstadoSelecionado] = useState<string>('');
  const [loadingCidades, setLoadingCidades] = useState<boolean>(false);

  const modalEstadoRef = useRef<HTMLDivElement | null>(null);
  const modalCidadeRef = useRef<HTMLDivElement | null>(null);

  // Função para fechar modal ao clicar fora
  const handleClickOutside = (event: MouseEvent) => {
    if (modalVisibleEstado && modalEstadoRef.current && !modalEstadoRef.current.contains(event.target as Node)) {
      setModalVisibleEstado(false);
    }
    if (modalVisibleCidade && modalCidadeRef.current && !modalCidadeRef.current.contains(event.target as Node)) {
      setModalVisibleCidade(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalVisibleEstado, modalVisibleCidade]);

  useEffect(() => {
    loadEstados();
  }, []);

  const loadEstados = async (): Promise<void> => {
    try {
      const response = await axios.get<Estado[]>('https://tribhus.shop:5000/city/estados');
      setEstados(response.data);
    } catch (error) {
      console.error('Erro ao buscar estados:', error);
    }
  };

  const loadCidades = async (codigo_uf: number): Promise<void> => {
    setLoadingCidades(true);
    try {
      const response = await axios.get<Cidade[]>(`https://tribhus.shop:5000/city/municipios/${codigo_uf}`);
      setCidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    } finally {
      setLoadingCidades(false);
    }
  };

  const handleNextButton = (): void => {
    navigate('/TelaBanda4');
  };

  return (
    <div style={styles.container}>
      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />

      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: 'SET_FORM', field: 'nome_banda', value: e.target.value })}
          value={state.nome_banda}
          placeholder="Nome da Banda"
        />
        <input
          style={styles.input}
          value={nomeEstadoSelecionado || ""}
          onClick={() => {
            setModalVisibleEstado(true);
            if (estados.length === 0) {
              loadEstados();
            }
          }}
          placeholder="Selecionar Estado"
          readOnly
        />
        <input
          style={styles.input}
          value={loadingCidades ? "Carregando cidades..." : (cidadeSelecionada || "Selecionar Cidade")}
          onClick={() => {
            if (estadoSelecionado) {
              setModalVisibleCidade(true);
              if (cidades.length === 0) {
                loadCidades(Number(estadoSelecionado));
              }
            }
          }}
          readOnly
        />
      </div>

      {modalVisibleEstado && (
        <div style={styles.modal}>
          <div ref={modalEstadoRef} style={styles.modalContent}>
            {estados.map((estado, index) => (
              <div
                key={index}
                style={styles.modalItem}
                onClick={() => {
                  setEstadoSelecionado(estado.codigo_uf.toString());
                  setNomeEstadoSelecionado(estado.nome);
                  setCidadeSelecionada('');
                  setCidades([]);
                  setModalVisibleEstado(false);
                  loadCidades(estado.codigo_uf);
                  dispatch({ type: 'SET_FORM', field: 'estado', value: estado.uf });
                }}
              >
                {estado.nome}
              </div>
            ))}
          </div>
        </div>
      )}

      {modalVisibleCidade && (
        <div style={styles.modal}>
          <div ref={modalCidadeRef} style={styles.modalContent}>
            {cidades.map((cidade, index) => (
              <div
                key={index}
                style={styles.modalItem}
                onClick={() => {
                  setCidadeSelecionada(cidade.nome);
                  setModalVisibleCidade(false);
                  dispatch({ type: 'SET_FORM', field: 'cidade', value: cidade.nome });
                }}
              >
                {cidade.nome}
              </div>
            ))}
          </div>
        </div>
      )}

      <button style={styles.button} onClick={handleNextButton}>
        <span style={styles.buttonText}>Próximo</span>
      </button>
      {/* Botão de Ajuda com a tela atual */}
      <BotaoAjuda telaAtual="TelaBanda3" />
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#151922',
    padding: '20px',
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain',
    marginBottom: '20px',
  },
  inputContainer: {
    width: '80%',
    maxWidth: '300px',
    marginBottom: '30px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '10px',
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: '1px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
  },
  button: {
    backgroundColor: '#914100',
    padding: '15px 50px',
    borderRadius: '10px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
    width: '200px',
    margin: '10px auto',
  },
  buttonText: {
    color: '#fff',
    fontSize: '16px',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#151922',
    padding: '20px',
    borderRadius: '10px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  modalItem: {
    padding: '20px',
    borderBottom: '1px solid #ccc',
    color: '#fff',
    cursor: 'pointer',
  },
};

export default TelaBanda3;
