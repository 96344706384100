import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterContext } from '../context/RegisterContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BotaoAjuda from '../Components/BotaoAjuda'; 

const QuintaTela: React.FC = () => {
  const { state, dispatch } = useContext(RegisterContext);
  const navigate = useNavigate();
  const [dataNascimento, setDataNascimento] = useState(state.data_nascimento || '');

  const formatDate = (input: string): string => {
    // Remove todos os caracteres não numéricos
    const numericInput = input.replace(/\D/g, '');
    
    // Aplica a formatação DD/MM/AAAA
    if (numericInput.length <= 2) {
      return numericInput;
    } else if (numericInput.length <= 4) {
      return `${numericInput.slice(0, 2)}/${numericInput.slice(2)}`;
    } else {
      return `${numericInput.slice(0, 2)}/${numericInput.slice(2, 4)}/${numericInput.slice(4, 8)}`;
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = formatDate(e.target.value);
    setDataNascimento(formattedDate);
    
    if (formattedDate.length === 10) {
      dispatch({ type: 'SET_FORM', field: 'data_nascimento', value: formattedDate });
    } else {
      dispatch({ type: 'SET_FORM', field: 'data_nascimento', value: '' });
    }
  };

  const handleNext = () => {
    if (!state.data_nascimento) {
      toast.error('Por favor, insira uma data de nascimento válida.');
      return;
    } else if (!state.genero_sexo || state.genero_sexo === 'placeholder') {
      toast.error('Por favor, selecione uma opção de gênero.');
      return;
    } else if (!state.nome) {
      toast.error('Por favor, insira seu nome.');
      return;
    } else if (!state.sobrenome) {
      toast.error('Por favor, insira seu sobrenome.');
      return;
    }
    navigate('/SetimaTela');
  };

  return (
    <div style={styles.container}>
      <ToastContainer />
      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
      <button onClick={() => navigate(-1)} style={styles.backButton}>
        <img src={require('../assets/voltar.png')} alt="Voltar" style={styles.backIcon} />
      </button>
  
      <h1 style={styles.title}>Vamos começar cadastrando seus dados...</h1>
      
      <div style={styles.inputContainer}>
        <label style={styles.inputLabel} htmlFor="dateOfBirth">Data de Nascimento</label>
        <input
          type="text"
          id="dateOfBirth"
          style={styles.input}
          value={dataNascimento}
          onChange={handleDateChange}
          placeholder="DD/MM/AAAA"
          maxLength={10}
        />

        <label style={styles.inputLabel} htmlFor="gender">Gênero</label>
        <select
          id="gender"
          value={state.genero_sexo || 'placeholder'}
          style={styles.input}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => 
            dispatch({ type: 'SET_FORM', field: 'genero_sexo', value: event.target.value })
          }
        >
          <option value="placeholder" disabled>Selecione</option>
          <option value="Masculino">Masculino</option>
          <option value="Feminino">Feminino</option>
          <option value="Prefiro não dizer">Prefiro não dizer</option>
          <option value="Outro">Outro</option>
        </select>

        {state.genero_sexo === 'Outro' && (
          <input
            type="text"
            style={styles.input}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
              dispatch({ type: 'SET_FORM', field: 'outro_genero', value: event.target.value })
            }
            value={state.outro_genero}
            placeholder="Por favor, especifique..."
          />
        )}

        <label style={styles.inputLabel} htmlFor="name">Nome</label>
        <input
          type="text"
          id="name"
          style={styles.input}
          value={state.nome}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
            dispatch({ type: 'SET_FORM', field: 'nome', value: e.target.value })
          }
          placeholder="Nome"
        />

        <label style={styles.inputLabel} htmlFor="sobrenome">Sobrenome</label>
        <input
          type="text"
          id="sobrenome"
          style={styles.input}
          value={state.sobrenome}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
            dispatch({ type: 'SET_FORM', field: 'sobrenome', value: e.target.value })
          }
          placeholder="Sobrenome"
        />
      </div>
  
      <button style={styles.button} onClick={handleNext}>
        Próximo
      </button>
      {/* Botão de Ajuda com a tela atual */}
      <BotaoAjuda telaAtual="QuintaTela" />
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#151922',
      padding: '20px',
    },
    logo: {
      width: '280px',
      height: '360px',
      objectFit: 'contain' as 'contain',
    },
    backButton: {
      position: 'absolute' as 'absolute',
      top: '10px',
      left: '10px',
      background: 'none',
      border: 'none',
      fontSize: '16px',
      cursor: 'pointer',
    },
    backIcon: {
      width: '30px',
      height: '30px',
    },
    title: {
      fontSize: '20px',
      color: '#fff',
      textAlign: 'center',
      marginBottom: '20px',
    },
    inputContainer: {
      width: '80%',
      maxWidth: '300px',
      marginBottom: '30px',
    },
    inputLabel: {
      color: '#fff',
      fontSize: '16px',
      marginBottom: '5px',
      display: 'block',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '15px',
      borderRadius: '10px',
      backgroundColor: '#151922',
      borderColor: '#914100',
      borderWidth: '1px',
      color: '#fff',
      fontFamily: 'Arial, sans-serif',
    },
    datePickerOverlay: {
      position: 'absolute' as 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
    },
    button: {
      backgroundColor: '#914100',
      padding: '15px 50px',
      borderRadius: '10px',
      border: 'none',
      color: '#fff',
      cursor: 'pointer',
      fontSize: '16px',
      textAlign: 'center',
      width: '200px',
      //margin: '10px auto',
    },
  };
  
  export default QuintaTela;
