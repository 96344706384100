import React, { useState, useContext, ChangeEvent } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor'; 

interface CreatePostProps {
  isOpen: boolean;
  onClose: () => void;
 // onPostCreated: () => void;
}

const CreatePost: React.FC<CreatePostProps> = ({ isOpen, onClose }) => {
  const { state: authState } = useContext(AuthContext);
  const { id_usuario, id_banda } = authState;

  const [newPostContent, setNewPostContent] = useState<string>('');
  const [commentsDisabled, setCommentsDisabled] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const extractYoutubeLink = (text: string) => {
    const youtubeUrlPattern = /(https?:\/\/(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/|live\/|playlist\?list=)|youtu\.be\/)([\w-]+)(?:[^\s]*)?)/;
    const match = text.match(youtubeUrlPattern);
    console.log("Link extraído:", match ? match[0] : "Nenhum link encontrado");
    return match ? match[0] : null;
  };

  const handleImageSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async () => {
    if (!imageFile) {
      console.log("Nenhuma imagem selecionada para upload.");
      return null;
    }

    try {
      const resizedBlob = await resizeImage(imageFile);
      const formData = new FormData();
      formData.append('image', resizedBlob, 'resized-image.jpg');

      const response = await axios.post('https://tribhus.shop:5000/palco/create', formData, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201 && response.data.imageUrl) {
        console.log("Upload bem-sucedido, URL da imagem: ", response.data.imageUrl);
        return response.data.imageUrl;
      } else {
        console.log("Resposta do servidor indicando falha no upload", response);
        return null;
      }
    } catch (error) {
      console.error('Erro ao enviar a imagem:', error);
      alert("Erro ao processar ou enviar imagem. Por favor, tente novamente mais tarde.");
      return null;
    }
  };

  const handleCreatePost = async () => {
    console.log("handleCreatePost iniciado");

    let imageUrl = null;
    if (imageFile) {
      imageUrl = await uploadImage();
      console.log("Upload da imagem completado", imageUrl);
      if (!imageUrl) {
        return;
      }
    }

    const extractedYoutubeLink = extractYoutubeLink(newPostContent);
    const contentWithoutLink = extractedYoutubeLink ? newPostContent.replace(extractedYoutubeLink, '').trim() : newPostContent.trim();

    if (!contentWithoutLink && !imageUrl && !extractedYoutubeLink) {
      alert("O post não pode estar vazio.");
      return;
    }

    const postData = {
      conteudo: contentWithoutLink,
      comentarios_ativados: !commentsDisabled,
      video_url: extractedYoutubeLink || '',
      image_url: imageUrl || '',
      id_banda: id_banda,
      id_usuario: id_usuario,
    };

    try {
      const response = await axios.post('https://tribhus.shop:5000/palco/post', postData, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });

      if (response.status === 201) {
        setNewPostContent('');
        setImageFile(null);
        setImagePreview(null);
        onClose();
        // Recarregar a página após fechar o modal
        window.location.reload();
      } else {
        alert("Falha ao criar a postagem. Por favor, tente novamente.");
      }
    } catch (error) {
      console.error('Erro ao criar a postagem:', error);
      alert("Erro ao criar a postagem. Por favor, tente novamente mais tarde.");
    }
  };

  //função para converter imagem antes de enviar ao backend:
  
  const resizeImage = (file: File): Promise<Blob> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);
          
          canvas.toBlob((blob) => {
            if (blob) resolve(blob);
          }, 'image/jpeg', 0.8);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCommentsToggle = () => {
    setCommentsDisabled(!commentsDisabled);
  };

  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay} onClick={onClose}>
      <div style={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} style={styles.closeButton}>&times;</button>
        <h1 style={styles.headerTitle}>Criar Postagem</h1>
        <div style={styles.contentContainer}>
          <textarea
            style={styles.textInput}
            placeholder="Qual rock você está pensando?"
            value={newPostContent}
            onChange={(e) => setNewPostContent(e.target.value)}
          />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <label style={{ color: 'white', marginRight: '10px', fontSize: '14px' }}>
              Desativar os comentários dessa postagem
              <input
                type="checkbox"
                checked={commentsDisabled}
                onChange={handleCommentsToggle}
                style={{ marginLeft: '10px' }}
              />
            </label>
          </div>
          <input
            type="file"
            onChange={handleImageSelection}
            accept="image/*"
            style={{ display: 'none' }}
            id="image-upload"
          />
          <label htmlFor="image-upload" style={styles.imageSelectionContainer}>
            <span style={styles.imageSelectionText}>Adicionar Imagem</span>
          </label>
          {imagePreview && (
            <img src={imagePreview} alt="Preview" style={styles.imagePreview} />
          )}
          <button onClick={handleCreatePost} style={styles.createPostButton}>
            <span style={styles.createPostButtonText}>Postar</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: '#151922',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '500px',
    width: '90%',
    maxHeight: '90vh',
    overflowY: 'auto',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    color: 'white',
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: '#151922',
  },
  headerTitle: {
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  textInput: {
    width: '100%',
    minHeight: 80,
    padding: 15,
    borderColor: '#444',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 20,
    color: 'white',
    fontSize: 16,
    marginBottom: 20,
    backgroundColor: '#000',
  },
  createPostButton: {
    backgroundColor: '#914100',
    padding: 15,
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 30,
    width: '70%',
    cursor: 'pointer',
    border: 'none',
  },
  imagePreview: {
    maxWidth: '100%',
    maxHeight: 200,
    objectFit: 'contain',
    marginTop: 10,
    marginBottom: 10,
  },
  imageSelectionContainer: {
    width: '100%',
    minHeight: 100,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: '#444',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    backgroundColor: '#000',
    cursor: 'pointer',
  },
  imageSelectionText: {
    color: 'white',
  },
  createPostButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
};

export default CreatePost;