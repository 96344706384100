import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import MusicContext from '../context/MusicContext';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

interface ModalAlbumProps {
  isOpen: boolean;
  album: any; // Substitua 'any' pelo tipo real do álbum.
  onClose: () => void;
}

const ModalAlbum: React.FC<ModalAlbumProps> = ({ isOpen, album, onClose }) => {
  const [tracks, setTracks] = useState<Array<any>>([]);
  const musicContext = useContext(MusicContext);
  const { state: authState } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (album) {
      axios.get(`https://tribhus.shop:5000/bands/album-tracks/${album.id_album}`, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
        params: {
          id_usuario: authState.id_usuario,
          id_banda: authState.id_banda,
        },
      })
      .then(response => {
        console.log('Resposta do servidor:', response);  // Log da resposta do servidor
        setTracks(response.data);
      })
      .catch(err => console.log('Erro na requisição:', err));  // Log do erro
    }
  }, [album, authState.token, authState.id_usuario, authState.id_banda]);
  

  const handleTrackPlay = (track: any) => {
    if (musicContext) {
      const updatedPlaylist = [track, ...musicContext.state.playlist];
      musicContext.setPlaylist(updatedPlaylist);
      musicContext.selectTrack(track);
      navigate('/AudioPlayer');
    }
  };

  const handleTrackAdd = (track: any) => {
    if (musicContext) {
      musicContext.addToPlaylist([track]);
      toast.success('Música adicionada com sucesso!');
    }
  };

  const handlePlayAlbum = () => {
    if (musicContext && tracks.length) {
        musicContext.setPlaylist(tracks);
        musicContext.selectTrack(tracks[0]); // Seleciona a primeira música da playlist
        navigate('/AudioPlayer');
    }
};


  const handleAddAlbumToPlaylist = () => {
    if (musicContext && tracks.length) {
      musicContext.addToPlaylist(tracks);
      toast.success('Músicas do álbum adicionadas com sucesso!');
    }
  };

  if (!isOpen || !album) {
    return null;
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>Fechar</button>
        <div style={styles.content}>
          <img 
            src={album.url_capa_album} 
            alt={album.nome_album}
            style={styles.albumCover} 
          />
          <div style={styles.albumInfo}>
            <h1 style={styles.albumTitle}>{album.nome_album}</h1>
            <h2 style={styles.albumYear}>{album.ano_lancamento}</h2>
            <div style={styles.buttonGroup}>
              <button style={styles.actionButton} onClick={handlePlayAlbum}>Reproduzir álbum</button>
              <button style={styles.actionButton} onClick={handleAddAlbumToPlaylist}>Adicionar álbum à fila</button>
            </div>
          </div>
        </div>
        <div style={styles.tracks}>
          <h2 style={styles.tracksTitle}>Músicas:</h2>
          {tracks.map(track => (
            <div key={track.id_musica} style={styles.track}>
              <span style={styles.trackTitle}>{track.nome_musica}</span>
              <div style={styles.trackButtonGroup}>
                <button style={styles.trackButton} onClick={() => handleTrackPlay(track)}>Reproduzir</button>
                <button style={styles.trackButton} onClick={() => handleTrackAdd(track)}>Adicionar à fila</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  } as React.CSSProperties,
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  } as React.CSSProperties,
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  content: {
    display: 'flex',
    gap: '20px',
  } as React.CSSProperties,
  albumCover: {
    maxWidth: '150px',
    maxHeight: '150px',
    borderRadius: '10px',
  } as React.CSSProperties,
  albumInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
  } as React.CSSProperties,
  albumTitle: {
    color: 'white',
    fontSize: '24px',
    margin: 0,
  } as React.CSSProperties,
  albumYear: {
    color: '#ccc',
    fontSize: '18px',
    margin: 0,
  } as React.CSSProperties,
  buttonGroup: {
    display: 'flex',
    gap: '10px',
  } as React.CSSProperties,
  actionButton: {
    padding: '10px',
    backgroundColor: '#914100',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    flex: 1,
    textAlign: 'center',
  } as React.CSSProperties,
  tracks: {
    marginTop: '20px',
  } as React.CSSProperties,
  tracksTitle: {
    color: 'white',
    fontSize: '20px',
    marginBottom: '10px',
  } as React.CSSProperties,
  track: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    borderBottom: '1px solid #444',
  } as React.CSSProperties,
  trackTitle: {
    color: 'white',
    flex: 1,
    marginRight: '10px',
  } as React.CSSProperties,
  trackButtonGroup: {
    display: 'flex',
    gap: '10px',
  } as React.CSSProperties,
  trackButton: {
    backgroundColor: '#555',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
  } as React.CSSProperties,
};

export default ModalAlbum;
