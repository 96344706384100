import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor'; 

interface User {
    id_usuario?: number;
    nome?: string;
    sobrenome?: string;
    cidade?: string;
    estado?: string;
    genero_sexo?: string;
    genero_personalizado?: string;
    telefone?: string | null;
    url_capa_usuario?: string | null;
    url_icone_usuario?: string | null;
    slug?: string;
    generos?: string[];
    auth_id?: number;
}

interface FormData {
    nome: string;
    sobrenome: string;
    cidade: string;
    estado: string;
    genero_sexo: string;
    genero_personalizado: string; 
    telefone: string;
}

interface Estado {
    nome: string;
    uf: string;
    codigo_uf: number;
}

interface Cidade {
    nome: string;
}

interface DataFormProps {
    user: User;
    handleChange: (field: keyof FormData, value: string) => void;
}

const DataForm: React.FC<DataFormProps> = ({ user, handleChange }) => {
    const { state: authState } = useContext(AuthContext);
    const [estados, setEstados] = useState<Estado[]>([]);
    const [cidades, setCidades] = useState<Cidade[]>([]);
    const [estadoSelecionado, setEstadoSelecionado] = useState('');
    const [cidadeSelecionada, setCidadeSelecionada] = useState('');
    const [isEstadoModalOpen, setIsEstadoModalOpen] = useState(false);
    const [isCidadeModalOpen, setIsCidadeModalOpen] = useState(false);

   
    const [formData, setFormData] = useState<FormData>({
        nome: user.nome || '',
        sobrenome: user.sobrenome || '',
        cidade: user.cidade || '',
        estado: user.estado || '',
        genero_sexo: user.genero_sexo || 'placeholder',
        genero_personalizado: user.genero_personalizado || '', 
        telefone: user.telefone || '',
    });

    useEffect(() => {
        console.log("User data received:", user);
        
        // Encontrando o estado correspondente no array de estados
        const estadoUF = estados.find(e => e.nome === user.estado)?.uf || user.estado || '';
    
        setFormData(prevData => ({
            ...prevData,
            nome: user.nome || '',
            sobrenome: user.sobrenome || '',
            cidade: user.cidade || '',
            estado: estadoUF, // Agora armazena a sigla
            genero_sexo: normalizeGender(user.genero_sexo),
            genero_personalizado: user.genero_personalizado || '',
            telefone: user.telefone ? formatPhone(user.telefone) : '',
        }));
    
        setEstadoSelecionado(estadoUF); // Define a sigla
        setCidadeSelecionada(user.cidade || '');
    
        loadEstados();
        if (estadoUF) {
            const codigoUf = estados.find(estado => estado.uf === estadoUF)?.codigo_uf;
            if (codigoUf) {
                loadCidades(codigoUf);
            }
        }
    }, [user, estados]);
    
    const handleFocus = (field: keyof FormData) => {
        if (!['estado', 'cidade'].includes(field)) {
            setFormData(prev => ({ ...prev, [field]: '' }));
        }
    };

    const handleBlur = (field: keyof FormData) => {
        if (formData[field] === '') {
            setFormData(prev => ({ ...prev, [field]: user[field] || '' }));
        }
        handleChange(field, formData[field]);
    };

    function normalizeGender(gender: string | null | undefined): string {
        if (!gender) return 'placeholder';
        return gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
    }

    const loadEstados = async () => {
        try {
            const response = await axios.get('https://tribhus.shop:5000/city/estados');
            setEstados(response.data);
        } catch (error) {
            console.error('Erro ao buscar estados:', error);
        }
    };

    const loadCidades = async (codigo_uf: number) => {
        try {
            const response = await axios.get(`https://tribhus.shop:5000/city/municipios/${codigo_uf}`);
            setCidades(response.data);
        } catch (error) {
            console.error('Erro ao buscar cidades:', error);
        }
    };
 
    const saveProfileChanges = async () => {
        try {
            const token = authState.token;
    
            // Certifique-se de enviar apenas a sigla do estado
            const dataToSend = {
                ...formData,
                estado: estadoSelecionado, // Já está na sigla correta
                genero_personalizado: formData.genero_sexo === 'Outro' ? formData.genero_personalizado : null
            };
    
            console.log("Data being sent to server:", dataToSend);
            const response = await axios.put(`https://tribhus.shop:5000/users/profile/${user.id_usuario}`, dataToSend, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            if (response.status === 200) {
                alert('Perfil atualizado com sucesso!');
                console.log("Updated user data:", response.data);
            } else {
                alert('Falha ao atualizar perfil.');
            }
        } catch (error) {
            console.error('Erro ao atualizar perfil:', error);
            alert('Erro ao atualizar perfil. Verifique sua conexão e tente novamente.');
        }
    };    
      
    const formatPhone = (phone: string) => {
        let numbers = phone.replace(/\D/g, '');
        numbers = numbers.slice(0, 11);

        if (numbers.length <= 2) {
            return `(${numbers}`;
        } else if (numbers.length <= 3) {
            return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
        } else if (numbers.length <= 7) {
            return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 3)}${numbers.slice(3)}`;
        } else {
            return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 3)}${numbers.slice(3, 7)}-${numbers.slice(7)}`;
        }
    };

    const handlePhoneChange = (value: string) => {
        const formattedPhone = formatPhone(value);
        setFormData(prev => ({ ...prev, telefone: formattedPhone }));
    };

        return (
            <div style={styles.formContainer}>
                {formData.estado !== undefined && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.fieldLabel}>Estado</label>
                        <button style={styles.input} onClick={() => {
                            setIsEstadoModalOpen(true);
                            if (!estados.length) loadEstados();
                        }}>
                            <span style={styles.inputText}>{estadoSelecionado || 'Selecionar Estado'}</span>
                        </button>
                        {isEstadoModalOpen && (
                            <div style={styles.modalContent}>
{estados.map(estado => (
    <button key={estado.codigo_uf} style={styles.modalItemText} onClick={() => {
        setEstadoSelecionado(estado.uf); // Agora salva a sigla (ex: "BA")
        setIsEstadoModalOpen(false);
        setFormData(prev => ({ ...prev, estado: estado.uf })); // Armazena apenas a sigla
        handleChange('estado', estado.uf); // Envia apenas a sigla
        loadCidades(estado.codigo_uf);
    }}>
        {estado.nome} {/* Exibe o nome do estado, mas salva a sigla */}
    </button>
))}
                            </div>
                        )}
                    </div>
                )}
    
                {formData.cidade !== undefined && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.fieldLabel}>Cidade</label>
                        <button style={styles.input} onClick={() => {
                            if (estadoSelecionado) {
                                setIsCidadeModalOpen(true);
                                if (!cidades.length && estadoSelecionado) {
                                    const codigoUf = estados.find(estado => estado.nome === estadoSelecionado)?.codigo_uf;
                                    if (codigoUf) {
                                        loadCidades(codigoUf);
                                    }
                                }
                            }
                        }}>
                            <span style={styles.inputText}>{cidadeSelecionada || 'Selecionar Cidade'}</span>
                        </button>
                        {isCidadeModalOpen && (
                            <div style={styles.modalContent}>
                                {cidades.map(cidade => (
                                    <button key={cidade.nome} style={styles.modalItemText} onClick={() => {
                                        setCidadeSelecionada(cidade.nome);
                                        setIsCidadeModalOpen(false);
                                        setFormData(prev => ({ ...prev, cidade: cidade.nome }));
                                        handleChange('cidade', cidade.nome);
                                    }}>
                                        {cidade.nome}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                )}

{Object.keys(formData).filter(field => field !== 'estado' && field !== 'cidade').map((field) => (
                <div key={field} style={styles.fieldContainer}>
                    <label style={styles.fieldLabel}>
                        {field === 'genero_personalizado' ? 'Especifique o gênero' : field.charAt(0).toUpperCase() + field.slice(1).replace('_', ' ')}
                    </label>
                    {field === 'genero_sexo' ? (
                        <select
                            value={formData.genero_sexo}
                            style={styles.select}
                            onChange={(e) => {
                                const value = e.target.value;
                                console.log("Genero sexo changed to:", value);
                                setFormData(prev => ({ ...prev, genero_sexo: value }));
                                handleChange('genero_sexo', value);
                            }}
                        >
                            <option value="placeholder">Selecione</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Feminino">Feminino</option>
                            <option value="Prefiro não dizer">Prefiro não dizer</option>
                            <option value="Outro">Outro</option>
                        </select>
                    ) : field === 'genero_personalizado' ? (
                        <input
                            style={{...styles.input, display: formData.genero_sexo === 'Outro' ? 'block' : 'none'}}
                            value={formData.genero_personalizado}
                            onChange={(e) => {
                                const value = e.target.value;
                                console.log("Genero personalizado changed to:", value);
                                setFormData(prev => ({ ...prev, genero_personalizado: value }));
                                handleChange('genero_personalizado', value);
                            }}
                            placeholder="Especifique o gênero"
                            type="text"
                        />
                    ) : (
                        <input
                            style={styles.input}
                            value={formData[field as keyof FormData]}
                            onChange={(e) => {
                                if (field === 'telefone') {
                                    handlePhoneChange(e.target.value);
                                } else {
                                    const value = e.target.value;
                                    setFormData(prev => ({ ...prev, [field]: value }));
                                    handleChange(field as keyof FormData, value);
                                }
                            }}
                            onFocus={() => handleFocus(field as keyof FormData)}
                            onBlur={() => handleBlur(field as keyof FormData)}
                            placeholder={field === 'telefone' ? '(XX) 9XXXX-XXXX' : 'Digite aqui'}
                            type={field === 'telefone' ? 'tel' : 'text'}
                        />
                    )}
                </div>
            ))}
    
            <button style={styles.button} onClick={saveProfileChanges}>
                <span style={styles.buttonText}>Salvar</span>
            </button>
        </div>
    );
};

    const styles = {
        formContainer: {
            padding: 20,
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 400,
            maxWidth: '100%',
            margin: '0 auto',
            backgroundColor: '#1E1E1E',
            borderRadius: 10,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        input: {
            height: 40,
            borderColor: 'gray',
            borderWidth: 1,
            marginBottom: 10,
            paddingLeft: 20,
            paddingRight: 10,
            width: '100%',
            boxSizing: 'border-box' as 'border-box',
            borderRadius: 20,
            color: '#fff',
            backgroundColor: '#151922',
        },
        inputText: {
            lineHeight: '40px',
            color: '#fff',
            fontSize: 14,
        },
        fieldLabel: {
            fontSize: 14,
            color: '#FF6600',
            marginBottom: 5,
            alignSelf: 'flex-start',
        },
        fieldContainer: {
            marginBottom: 15,
            width: '100%',
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'flex-start',
            boxSizing: 'border-box' as 'border-box',
        },
        modalContent: {
            marginTop: 22,
            backgroundColor: '#151922',
            padding: 20,
            borderRadius: 10,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        modalItemText: {
            fontSize: 16,
            color: '#fff',
            padding: 10,
            border: '1px solid gray',
            borderRadius: 10,
            marginBottom: 5,
            width: '100%',
            textAlign: 'left' as const,
            backgroundColor: 'transparent',
        },
        button: {
            backgroundColor: '#FF6600',
            padding: '15px 50px',
            borderRadius: 10,
            border: 'none',
            cursor: 'pointer',
            marginTop: 20,
        },
        buttonText: {
            color: '#fff',
            fontSize: 16,
        },
        select: {
            height: 40,
            borderColor: 'gray',
            borderWidth: 1,
            marginBottom: 10,
            paddingLeft: 20,
            paddingRight: 10,
            width: '100%',
            boxSizing: 'border-box' as 'border-box',
            borderRadius: 20,
            color: '#fff',
            backgroundColor: '#151922',
        },
    };
    
    export default DataForm;
    