import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';
import { useNavigate } from 'react-router-dom';

interface VideoItem {
  id_videosvod: number;
  titulo_videosvod: string;
  descricao: string;
  status: string;
  url_thumbnail: string;
  thumbnail_url: string;
  data_upload: string;
  duracao: number;
}

const VideoVodList: React.FC = () => {
  const { state: authState } = useContext(AuthContext);
  const { id_banda } = authState;

  const [videos, setVideos] = useState<VideoItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/videos/videosvod/banda/${id_banda}`);
        setVideos(response.data);
      } catch (err) {
        console.error('Erro ao carregar lista de vídeos:', err);
        setError('Não foi possível carregar os vídeos. Tente novamente mais tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    if (id_banda) {
      fetchVideos();
    }
  }, [id_banda]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const handleVideoClick = (id: number) => {
    // Navegar para a página do player com o ID do vídeo
    navigate(`/VideoVodPlayer/${id}`);
  };

  if (isLoading && videos.length === 0) {
    return (
      <div style={styles.container}>
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
          <p style={styles.loadingText}>Carregando vídeos...</p>
        </div>
      </div>
    );
  }

  if (error && videos.length === 0) {
    return (
      <div style={styles.container}>
        <div style={styles.errorContainer}>
          <p style={styles.errorText}>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <h1 style={styles.title}>Vídeos</h1>
      </div>

      {videos.length === 0 ? (
        <div style={styles.emptyContainer}>
          <p style={styles.emptyText}>Nenhum vídeo encontrado.</p>
        </div>
      ) : (
        <div style={styles.videoListContainer}>
          <div style={styles.videoGrid}>
            {videos.map((video) => (
              <div 
                key={video.id_videosvod} 
                style={styles.videoCard}
                onClick={() => handleVideoClick(video.id_videosvod)}
              >
                <div style={styles.thumbnailContainer}>
                  <img 
                    src={video.thumbnail_url || '/default-thumbnail.jpg'} 
                    alt={video.titulo_videosvod}
                    style={styles.thumbnail}
                    onError={(e) => {
                      (e.target as HTMLImageElement).src = '/default-thumbnail.jpg';
                    }}
                  />
                  {video.status === 'processando' && (
                    <div style={styles.processingOverlay}>
                      <div style={styles.smallSpinner}></div>
                      <span style={styles.processingText}>Processando</span>
                    </div>
                  )}
                </div>
                <div style={styles.videoCardInfo}>
                  <h3 style={styles.videoCardTitle}>{video.titulo_videosvod}</h3>
                  <p style={styles.videoCardDate}>
                    Enviado em {formatDate(video.data_upload)}
                  </p>
                  {video.status === 'disponivel' && video.duracao && (
                    <span style={styles.videoDuration}>
                      {Math.floor(video.duracao / 60)}:{(video.duracao % 60).toString().padStart(2, '0')}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    padding: 20,
    backgroundColor: '#151922',
    borderRadius: 10,
    color: 'white',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
    margin: 0,
  },
  videoListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  playerWrapper: {
    marginBottom: 20,
  },
  videoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gap: 15,
  },
  videoCard: {
    backgroundColor: '#1E2330',
    borderRadius: 10,
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'transform 0.2s, box-shadow 0.2s',
  },
  selectedCard: {
    borderColor: '#914100',
    borderWidth: 2,
    borderStyle: 'solid',
    boxShadow: '0 0 10px rgba(145, 65, 0, 0.5)',
  },
  thumbnailContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%', // 16:9 aspect ratio
  },
  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: '#000',
  },
  processingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  processingText: {
    color: 'white',
    fontSize: 14,
    marginTop: 10,
  },
  smallSpinner: {
    width: 24,
    height: 24,
    border: '2px solid rgba(255, 255, 255, 0.1)',
    borderTopColor: '#914100',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  videoCardInfo: {
    padding: 12,
  },
  videoCardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  videoCardDate: {
    fontSize: 12,
    color: '#999',
    marginBottom: 5,
  },
  videoDuration: {
    fontSize: 12,
    color: '#ccc',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '2px 5px',
    borderRadius: 4,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  spinner: {
    width: 40,
    height: 40,
    border: '3px solid rgba(255, 255, 255, 0.1)',
    borderTopColor: '#914100',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  loadingText: {
    color: 'white',
    marginTop: 10,
  },
  errorContainer: {
    padding: 40,
    textAlign: 'center',
  },
  errorText: {
    color: '#ff6b6b',
    fontSize: 16,
    marginBottom: 20,
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
    textAlign: 'center',
    backgroundColor: '#1E2330',
    borderRadius: 10,
  },
  emptyText: {
    color: '#ccc',
    marginBottom: 20,
  },
};

export default VideoVodList;