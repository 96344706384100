import React, { useEffect, useState, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { BandaContext } from '../context/BandaContext';
import { useNavigate } from 'react-router-dom';
import BotaoAjuda from '../Components/BotaoAjuda'; 

interface Genre {
  id_genero: number;
  nome_genero: string;
}

const TelaBanda4 = () => {
  const [genres, setGenres] = useState<Genre[]>([]);
  const [filteredGenres, setFilteredGenres] = useState<Genre[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state, dispatch } = useContext(BandaContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://tribhus.shop:5000/users/genres');
        setGenres(response.data);
        setFilteredGenres(response.data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred.");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    setFilteredGenres(genres.filter(genre => genre.nome_genero.toLowerCase().includes(lowerSearchTerm)));
  }, [searchTerm, genres]);

  const handleSelectGenre = (genre: Genre) => {
    const genreId = genre.id_genero;
    if (state.selectedGenres.includes(genreId)) {
      dispatch({
        type: 'REMOVE_SELECTED_GENRE',
        id: genreId
      });
    } else {
      dispatch({
        type: 'ADD_SELECTED_GENRE',
        id: genreId
      });
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleNext = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const bandaResponse = await axios.post('https://tribhus.shop:5000/bands/register-band', {
        auth_id: state.auth_id,
        nome_banda: state.nome_banda,
        nome_responsavel: state.nome_responsavel,
        nasc_responsavel: state.nasc_responsavel,
        sobrenome_responsavel: state.sobrenome_responsavel,
        cidade: state.cidade,
        estado: state.estado,
        selected_genres: state.selectedGenres,
        slug: state.slug  
      });

      dispatch({ type: 'SET_BANDA_ID', id: bandaResponse.data.banda.id_banda });
      navigate('/TelaBanda5');
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (error) {
    return (
      <div style={styles.errorContainer}>
        <span style={styles.errorText}>Erro: {error}</span>
      </div>
    );
  }

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          <button style={styles.backButton} onClick={() => window.history.back()}>
            <img src={require('../assets/voltar.png')} alt="Voltar" style={styles.backIcon} />
          </button>
          <img
            style={styles.logo}
            src={require('../assets/logotipo.png')}
            alt="Logotipo"
          />
        </div>
        <input
          style={styles.searchBox}
          placeholder="Busque os Gêneros da Sua Banda"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div style={styles.genresWrapper}>
          <div style={styles.genresContainer}>
            {filteredGenres.length > 0 ? (
              filteredGenres.map((genre, index) => (
                <div
                  key={index}
                  style={{
                    ...styles.genreBox,
                    ...(state.selectedGenres.includes(genre.id_genero) ? styles.selectedGenreBox : {})
                  }}
                  onClick={() => handleSelectGenre(genre)}
                >
                  <span style={styles.genreText}>{genre.nome_genero}</span>
                </div>
              ))
            ) : (
              <div style={styles.loadingContainer}>
                <span style={styles.loadingText}>
                  {searchTerm ? "Nenhum gênero encontrado" : "Carregando gêneros..."}
                </span>
              </div>
            )}
          </div>
        </div>
        <button style={styles.nextButton} onClick={handleNext} disabled={isSubmitting}>
          {isSubmitting ? 'Carregando...' : 'Próximo'}
        </button>
      {/* Botão de Ajuda com a tela atual */}
      <BotaoAjuda telaAtual="TelaBanda4" />
    </div>
  );
};
  
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as const,
      backgroundColor: '#151922',
      padding: '10px',
      height: '100vh',
      overflowY: 'auto' as const,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '20px',
    },
    logo: {
      width: '260px',
      height: '140px',
      objectFit: 'contain' as const,
    },
    backButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
    },
    backIcon: {
      width: '30px',
      height: '30px',
    },
    searchBox: {
      width: '60%',
      padding: '10px',
      marginBottom: '20px',
      backgroundColor: '#ffffff',
      color: '#000000',
      border: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      alignSelf: 'center', // Para centralizar a barra de busca
    },
    genresWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    genresContainer: {
      display: 'flex',
      flexDirection: 'row' as const,
      flexWrap: 'wrap' as const,
      justifyContent: 'space-between',
      maxWidth: '800px',
      width: '100%',
    },
    genreBox: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '15px',
      borderColor: '#fff',
      backgroundColor: '#151922',
      padding: '10px',
      marginBottom: '20px',
      width: 'calc(33.33% - 15px)',
      textAlign: 'center' as const,
      cursor: 'pointer',
    },
    genreText: {
      fontSize: '14px',
      color: '#fff',
    },
    selectedGenreBox: {
      backgroundColor: 'orange',
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '200px',
    },
    loadingText: {
      fontSize: '20px',
      color: '#fff',
    },
    nextButton: {
      backgroundColor: '#ff6347',
      padding: '15px 50px',
      borderRadius: '10px',
      border: 'none',
      color: '#fff',
      cursor: 'pointer',
      fontSize: '16px',
      textAlign: 'center' as const,
      width: '200px',
      margin: '10px auto',
    },
    errorContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
    },
    errorText: {
      color: 'red',
    },
  };
  
  export default TelaBanda4;
  