import React, { useState, useContext, useEffect, useRef, ChangeEvent } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';
import VideoVodList from '../screens/VideoVodList';

interface Video {
    id_video: number;
    url_video: string;
    titulo: string;
    descricao: string;
    tipo?: string; // Para diferenciar vídeos do YouTube de vídeos hospedados internamente
}

const VideosAdicionar: React.FC = () => {
    const { state: authState } = useContext(AuthContext);
    // Estado para vídeos do YouTube
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [titulo, setTitulo] = useState<string>('');
    const [descricao, setDescricao] = useState<string>('');
    
    // Estado para upload de vídeos
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [uploadTitulo, setUploadTitulo] = useState<string>('');
    const [uploadDescricao, setUploadDescricao] = useState<string>('');
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    
    // Referência para o input de arquivo
    const videoInputRef = useRef<HTMLInputElement>(null);
    
    // Estado compartilhado
    const [mensagem, setMensagem] = useState<string>('');
    const [videos, setVideos] = useState<Video[]>([]);

    const extractYoutubeLink = (text: string) => {
        const youtubeUrlPattern = /(https?:\/\/(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+(?:[^\s]*))/;
        const match = text.match(youtubeUrlPattern);
        return match ? match[0] : null;
    };

    useEffect(() => {
        fetchVideos();
    }, []);
    
    // Limpar URLs de objeto ao desmontar para evitar memory leaks
    useEffect(() => {
        return () => {
            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }
        };
    }, [videoPreview]);

    const fetchVideos = async () => {
        try {
            const response = await axios.get<Video[]>(`https://tribhus.shop:5000/videos/band/${authState.id_banda}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setVideos(response.data);
        } catch (error) {
            console.error('Erro ao buscar vídeos:', error);
            setMensagem('Erro ao carregar os vídeos. Por favor, tente novamente mais tarde.');
        }
    };

    const handleAddVideo = async () => {
        setMensagem('');
        const extractedYoutubeLink = extractYoutubeLink(videoUrl);
    
        if (!extractedYoutubeLink) {
            setMensagem('Por favor, insira uma URL válida do YouTube.');
            return;
        }
    
        if (!titulo) {
            setMensagem('Por favor, insira um título para o vídeo.');
            return;
        }
    
        if (!authState.id_banda) {
            setMensagem('ID da banda não encontrado. Por favor, faça login novamente.');
            return;
        }
    
        const videoData = {
            id_banda: authState.id_banda,
            url_video: extractedYoutubeLink,
            titulo,
            descricao,
            tipo: 'youtube'
        };
    
        try {
            const response = await axios.post(`https://tribhus.shop:5000/videos/add/${authState.id_banda}`, videoData, {
                headers: {
                    Authorization: `Bearer ${authState.token}`,
                },
            });
    
            if (response.status === 201) {
                setMensagem('Vídeo do YouTube adicionado com sucesso!');
                
                fetchVideos();
    
                // Limpar os campos do formulário
                setVideoUrl('');
                setTitulo('');
                setDescricao('');
            } else {
                setMensagem('Falha ao adicionar o vídeo. Por favor, tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao adicionar o vídeo:', error);
            setMensagem('Erro ao adicionar o vídeo. Por favor, tente novamente mais tarde.');
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            // Verificar se é um arquivo de vídeo
            if (!selectedFile.type.startsWith('video/')) {
                setMensagem('Por favor, selecione um arquivo de vídeo válido.');
                return;
            }
            
            // Limite de tamanho do arquivo (500MB)
            const maxSize = 500 * 1024 * 1024; // 500MB em bytes
            if (selectedFile.size > maxSize) {
                setMensagem('O arquivo é muito grande. O tamanho máximo permitido é 500MB.');
                return;
            }
            
            setVideoFile(selectedFile);
            
            // Criar URL para preview do vídeo
            const videoUrl = URL.createObjectURL(selectedFile);
            setVideoPreview(videoUrl);
        }
    };
    
    const triggerFileInput = () => {
        if (videoInputRef.current) {
            videoInputRef.current.click();
        }
    };

    const handleUploadVideo = async () => {
        setMensagem('');
        
        if (!videoFile) {
            setMensagem('Por favor, selecione um arquivo de vídeo para fazer upload.');
            return;
        }
        
        if (!uploadTitulo) {
            setMensagem('Por favor, insira um título para o vídeo.');
            return;
        }
        
        if (!authState.id_banda) {
            setMensagem('ID da banda não encontrado. Por favor, faça login novamente.');
            return;
        }
        
        setIsUploading(true);
        setUploadProgress(0);
        
        const formData = new FormData();
        formData.append('video', videoFile);
        formData.append('id_banda', authState.id_banda.toString());
        formData.append('titulo_videosvod', uploadTitulo); // Usando o nome do campo correto como em UploadVideoVod
        formData.append('descricao', uploadDescricao);
        formData.append('tipo', 'upload');
        
        try {
            // Usamos o mesmo endpoint que o componente UploadVideoVod usa
            const endpoint = `https://tribhus.shop:5000/videos/upload-videosvod/${authState.id_banda}`;
            
            const response = await axios.post(
                endpoint, 
                formData, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authState.token}`
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = progressEvent.total 
                            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            : 0;
                        setUploadProgress(percentCompleted);
                    }
                }
            );
            
            if (response.status === 201) {
                setMensagem('Vídeo enviado com sucesso! Aguarde alguns minutos até ser processado.');
                fetchVideos();
                
                // Limpar os campos do formulário e o preview
                resetUploadForm();
            } else {
                setMensagem('Falha ao fazer upload do vídeo. Por favor, tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao fazer upload do vídeo:', error);
            if (axios.isAxiosError(error)) {
                console.error('URL da requisição:', error.config?.url);
                console.error('Status do erro:', error.response?.status);
                console.error('Mensagem do servidor:', error.response?.data);
            }
            setMensagem('Erro ao fazer upload do vídeo. Por favor, tente novamente mais tarde.');
        } finally {
            setIsUploading(false);
        }
    };
    
    const resetUploadForm = () => {
        setVideoFile(null);
        setUploadTitulo('');
        setUploadDescricao('');
        
        if (videoPreview) {
            URL.revokeObjectURL(videoPreview);
            setVideoPreview(null);
        }
        
        setUploadProgress(0);
        
        // Limpar o input file
        if (videoInputRef.current) {
            videoInputRef.current.value = '';
        }
    };

    const handleDeleteVideo = async (videoId: number) => {
        try {
            await axios.delete(`https://tribhus.shop:5000/videos/delete/${authState.id_banda}/${videoId}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setMensagem('Vídeo excluído com sucesso!');
            fetchVideos(); // Atualiza a lista de vídeos
        } catch (error) {
            console.error('Erro ao excluir o vídeo:', error);
            setMensagem('Erro ao excluir o vídeo. Por favor, tente novamente mais tarde.');
        }
    };

    const getYoutubeEmbedUrl = (url: string) => {
        const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
        return `https://www.youtube.com/embed/${videoId}`;
    };

    // Determina a fonte do vídeo, se é do YouTube ou hospedado no servidor
    const renderVideoPlayer = (video: Video) => {
        if (video.tipo === 'upload' || !video.url_video.includes('youtube')) {
            return (
                <video 
                    width="100%" 
                    height="200" 
                    controls
                    src={video.url_video}
                >
                    Seu navegador não suporta o elemento de vídeo.
                </video>
            );
        } else {
            return (
                <iframe
                    width="100%"
                    height="200"
                    src={getYoutubeEmbedUrl(video.url_video)}
                    title={video.titulo}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            );
        }
    };

    // Filtra vídeos por tipo para exibir em cada coluna
    const youtubeVideos = videos.filter(video => !video.tipo || video.tipo === 'youtube');

    return (
        <div style={styles.container}>
            <div style={styles.column}>
                {/* Coluna da esquerda – YouTube */}
                <div style={styles.formSection}>
                    <h2 style={styles.headerTitle}>Adicionar Vídeo do YouTube</h2>
                    <input
                        type="text"
                        value={videoUrl}
                        onChange={(e) => setVideoUrl(e.target.value)}
                        placeholder="URL do Vídeo do YouTube"
                        style={styles.input}
                    />
                    <input
                        type="text"
                        value={titulo}
                        onChange={(e) => setTitulo(e.target.value)}
                        placeholder="Título do Vídeo"
                        style={styles.input}
                    />
                    <textarea
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        placeholder="Descrição do Vídeo (opcional)"
                        style={styles.textArea}
                    />
                    <button onClick={handleAddVideo} style={styles.addButton}>
                        <span style={styles.addButtonText}>Adicionar Vídeo do YouTube</span>
                    </button>
                </div>
    
                <div style={styles.videoGallery}>
                    <h3 style={styles.subheaderTitle}>Vídeos do YouTube</h3>
                    <div style={styles.videosGrid}>
                        {youtubeVideos.map((video) => (
                            <div key={video.id_video} style={styles.videoContainer}>
                                <h3 style={styles.videoTitle}>{video.titulo}</h3>
                                {renderVideoPlayer(video)}
                                <p style={styles.videoDescription}>{video.descricao}</p>
                                <button onClick={() => handleDeleteVideo(video.id_video)} style={styles.deleteButton}>
                                    <span style={styles.deleteButtonText}>Excluir Vídeo</span>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
    
            <div style={styles.column}>
                {/* Coluna da direita – Upload de vídeo */}
                <div style={styles.formSection}>
                    <h2 style={styles.headerTitle}>Upload de Vídeo</h2>
                    <input
                        type="text"
                        value={uploadTitulo}
                        onChange={(e) => setUploadTitulo(e.target.value)}
                        placeholder="Título do Vídeo"
                        style={styles.input}
                    />
                    <textarea
                        value={uploadDescricao}
                        onChange={(e) => setUploadDescricao(e.target.value)}
                        placeholder="Descrição do Vídeo (opcional)"
                        style={styles.textArea}
                    />
                    <input
                        type="file"
                        ref={videoInputRef}
                        onChange={handleFileChange}
                        accept="video/*"
                        style={{ display: 'none' }}
                        id="video-upload"
                    />
    
                    {!videoPreview ? (
                        <div style={styles.videoSelectionContainer} onClick={triggerFileInput}>
                            <span style={styles.videoSelectionText}>Clique para selecionar um vídeo</span>
                            <p style={styles.videoNote}>Formatos aceitos: MP4, MOV, AVI (máx. 500MB)</p>
                        </div>
                    ) : (
                        <div style={styles.videoPreviewContainer}>
                            <video src={videoPreview} style={styles.videoPreview} controls />
                            <div style={styles.videoFileInfo}>
                                <span style={styles.videoFileName}>{videoFile?.name}</span>
                                <span style={styles.videoFileSize}>
                                    {(videoFile?.size ? (videoFile.size / (1024 * 1024)).toFixed(2) : 0)} MB
                                </span>
                            </div>
                            <button style={styles.changeVideoButton} onClick={triggerFileInput}>
                                Trocar vídeo
                            </button>
                        </div>
                    )}
    
                    {isUploading && (
                        <div style={styles.progressContainer}>
                            <div style={{ ...styles.progressBar, width: `${uploadProgress}%` }} />
                            <span style={styles.progressText}>{uploadProgress}% concluído</span>
                        </div>
                    )}
    
                    <button
                        onClick={handleUploadVideo}
                        style={{
                            ...styles.uploadButton,
                            opacity: isUploading ? 0.7 : 1,
                            cursor: isUploading ? 'not-allowed' : 'pointer',
                        }}
                        disabled={isUploading}
                    >
                        <span style={styles.uploadButtonText}>
                            {isUploading ? 'Enviando...' : 'Fazer Upload de Vídeo'}
                        </span>
                    </button>
                </div>
    
                {/* 👇 Aqui está o componente filho VideoVodList corretamente posicionado */}
                <div style={styles.videoGallery}>
                    <h3 style={styles.subheaderTitle}>Vídeos Hospedados na Plataforma</h3>
                    <VideoVodList />
                </div>
            </div>
    
            {mensagem && (
                <div style={styles.mensagemContainer}>
                    <p style={styles.mensagem}>{mensagem}</p>
                </div>
            )}
        </div>
    );    
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#151922',
        padding: '20px',
        minHeight: '100vh',
        position: 'relative',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '20px',
    },
    formSection: {
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
        marginBottom: '20px',
    },
    videoGallery: {
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
    },
    headerTitle: {
        color: 'white',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
    },
    subheaderTitle: {
        color: 'white',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '15px',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'white',
        fontSize: '16px',
    },
    textArea: {
        width: '100%',
        minHeight: '100px',
        padding: '10px',
        marginBottom: '15px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'white',
        fontSize: '16px',
        resize: 'vertical',
    },
    addButton: {
        backgroundColor: '#FF6600',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: 'none',
        width: '100%',
    },
    addButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    mensagemContainer: {
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(0,0,0,0.8)',
        padding: '15px 30px',
        borderRadius: '5px',
        zIndex: 1000,
    },
    mensagem: {
        color: 'white',
        margin: 0,
        textAlign: 'center',
    },
    videosGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
    },
    videoContainer: {
        backgroundColor: '#30343f',
        padding: '15px',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    videoTitle: {
        color: 'white',
        fontSize: '18px',
        marginBottom: '10px',
    },
    videoDescription: {
        color: 'white',
        fontSize: '14px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    videoType: {
        display: 'inline-block',
        backgroundColor: '#2C3E50',
        color: 'white',
        fontSize: '12px',
        padding: '4px 8px',
        borderRadius: '3px',
        marginBottom: '10px',
    },
    deleteButton: {
        backgroundColor: '#d9534f',
        padding: '8px 15px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: 'none',
        marginTop: '10px',
    },
    deleteButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    uploadButton: {
        backgroundColor: '#914100',
        padding: '15px',
        borderRadius: '20px',
        marginTop: '10px',
        marginBottom: '15px',
        width: '100%',
        cursor: 'pointer',
        border: 'none',
    },
    disabledButton: {
        backgroundColor: '#6c757d',
        padding: '15px',
        borderRadius: '20px',
        marginTop: '10px',
        marginBottom: '15px',
        width: '100%',
        cursor: 'not-allowed',
        border: 'none',
    },
    uploadButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center',
    },
    progressContainer: {
        width: '100%',
        height: '20px',
        backgroundColor: '#333',
        borderRadius: '10px',
        marginBottom: '15px',
        position: 'relative',
        overflow: 'hidden',
    },
    progressBar: {
        height: '100%',
        backgroundColor: '#914100',
        transition: 'width 0.3s ease',
    },
    progressText: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '12px',
    },
    uploadInfo: {
        marginTop: '15px',
        color: '#adb5bd',
        fontSize: '14px',
    },
    videoSelectionContainer: {
        width: 'auto',
        minHeight: '120px',
        borderRadius: '10px',
        borderWidth: '2px',
        borderColor: '#444',
        borderStyle: 'dashed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '15px',
        backgroundColor: '#000',
        cursor: 'pointer',
        padding: '15px',
    },
    videoSelectionText: {
        color: 'white',
        fontSize: '18px',
        marginBottom: '10px',
    },
    videoNote: {
        color: '#888',
        fontSize: '14px',
        textAlign: 'center',
    },
    videoPreviewContainer: {
        width: 'auto',
        marginBottom: '15px',
        backgroundColor: '#000',
        borderRadius: '10px',
        padding: '12px',
        borderColor: '#444',
        borderStyle: 'solid',
        borderWidth: '1px',
    },
    videoPreview: {
        width: '100%',
        maxHeight: '300px',
        objectFit: 'contain',
        borderRadius: '15px',
        marginBottom: '10px',
        backgroundColor: '#000',
    },
    videoFileInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
    },
    videoFileName: {
        color: 'white',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '80%',
    },
    videoFileSize: {
        color: '#888',
        fontSize: '14px',
    },
    changeVideoButton: {
        backgroundColor: '#333',
        color: 'white',
        padding: '8px 15px',
        borderRadius: '15px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
    },
};

// Para telas com largura >= 768px (tablets e desktops)
const mediaQuery = window.matchMedia('(min-width: 768px)');
if (mediaQuery.matches) {
    styles.container = {
        ...styles.container,
        flexDirection: 'row',
        justifyContent: 'space-between',
    };
    styles.column = {
        ...styles.column,
        width: '49%', // Ligeiramente menor que 50% para evitar problemas de layout
        marginBottom: 0,
    };
}

export default VideosAdicionar;