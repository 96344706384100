import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import { SlugContext } from '../context/SlugContext';
import { AuthContext } from '../context/AuthContext';
import MusicContext from '../context/MusicContext';
import debounce from 'lodash.debounce';
import TabBar from '../Components/TabBar'; 
import MiniPlayer from '../Components/MiniPlayer';

interface Banda {
    id_banda: number;
    nome_banda: string;
    slug: string;
    cidade: string;
    estado: string;
    url_icone_banda: string;
    generos: string[];
}

const Bandas: React.FC = () => {
    const navigate = useNavigate();
    const { dispatch: slugDispatch } = useContext(SlugContext);
    const { state: authState } = useContext(AuthContext);
    const [bandas, setBandas] = useState<Banda[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [carregando, setCarregando] = useState(false);
    const [temMaisBandas, setTemMaisBandas] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const searchQueryRef = useRef('');
    const isSearchingRef = useRef(false);
    const [showScrollTop, setShowScrollTop] = useState(false);
    const musicContext = useContext(MusicContext);
    const currentTrack = musicContext?.state.track;
    const observerRef = useRef<IntersectionObserver | null>(null);

    // Função que será chamada quando precisar carregar mais bandas
    const loadMoreBandas = useCallback(() => {
        if (!carregando && temMaisBandas && !isSearchingRef.current) {
            console.log(`Carregando mais bandas - página ${paginaAtual}`);
            carregarBandas(paginaAtual, searchQueryRef.current);
        }
    }, [carregando, temMaisBandas, paginaAtual]);

    // Referência para o último elemento usando useCallback
    const lastBandaRef = useCallback((node: HTMLDivElement | null) => {
        if (carregando) return;
        
        // Desconecta o observer anterior
        if (observerRef.current) {
            observerRef.current.disconnect();
        }
        
        // Cria um novo observer
        observerRef.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && temMaisBandas && !isSearchingRef.current) {
                console.log("Último item visível, carregando mais bandas...");
                loadMoreBandas();
            }
        }, { threshold: 0.1 }); // 10% de visibilidade é suficiente
        
        // Observa o nó se estiver disponível
        if (node) {
            console.log("Observando novo último elemento");
            observerRef.current.observe(node);
        }
    }, [carregando, temMaisBandas, loadMoreBandas]);

    const debouncedSearch = useCallback(
        debounce((value: string) => {
            searchQueryRef.current = value;
            isSearchingRef.current = true;
            setBandas([]);
            setPaginaAtual(1);
            setTemMaisBandas(true);
            carregarBandas(1, value, true);
        }, 300),
        []
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        debouncedSearch(value);
    };

    const carregarBandas = useCallback(async (pagina: number, busca: string = '', isNewSearch: boolean = false) => {
        if (carregando || (!temMaisBandas && !isNewSearch)) return;
        
        console.log(`Carregando bandas - página ${pagina}, busca: "${busca}", isNewSearch: ${isNewSearch}`);
        setCarregando(true);

        try {
            const endpoint = busca
            ? `https://tribhus.shop:5000/bands/search?page=${pagina}&pageSize=40&search=${busca}`
            : `https://tribhus.shop:5000/bands/all-bands?page=${pagina}&pageSize=40`;

            console.log(`Chamando API: ${endpoint}`);
            const response = await axios.get<{ bandas: Banda[] }>(endpoint, {
                headers: {
                    'Authorization': `Bearer ${authState.token}`
                }
            });
            
            console.log(`Recebidas ${response.data.bandas.length} bandas da página ${pagina}`);

            // Verifica se há mais bandas para carregar
            if (response.data.bandas.length === 0 || response.data.bandas.length < 40) {
                console.log("Não há mais bandas para carregar");
                setTemMaisBandas(false);
            } else {
                setTemMaisBandas(true);
            }

            setBandas(prevBandas => {
                if (isNewSearch) {
                    return response.data.bandas;
                } else {
                    const novasBandas = [...prevBandas, ...response.data.bandas];
                    const bandasUnicas = Array.from(new Set(novasBandas.map(b => b.id_banda)))
                        .map(id => novasBandas.find(b => b.id_banda === id)!);
                    return bandasUnicas;
                }
            });
            
            setPaginaAtual(prevPagina => isNewSearch ? 2 : prevPagina + 1);
        } catch (error) {
            console.error("Erro ao buscar bandas: ", error);
        } finally {
            setCarregando(false);
            if (isNewSearch) {
                isSearchingRef.current = false;
            }
        }
    }, [authState.token, carregando]);

    // Carregar bandas iniciais quando o componente montar
    useEffect(() => {
        if (authState.token) {
            carregarBandas(1);
        }
    }, [authState.token, carregarBandas]);

    const handleBandaClick = useCallback((slug: string) => {
        slugDispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
        navigate('/TelaBandaPerfil');
    }, [slugDispatch, navigate]);

    //voltar ao topo
useEffect(() => {
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setShowScrollTop(true);
        } else {
            setShowScrollTop(false);
        }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
}, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div style={styles.wrapper}>
            <div style={styles.container}>
                <div style={styles.mainContent}>
                    <div style={styles.tabBarColumn}>
                        <TabBar currentScreen="Bandas" />
                        {currentTrack && (
                            <div style={styles.miniPlayerContainer}>
                                <MiniPlayer />
                            </div>
                        )}
                    </div>
                    
                    <div style={styles.feedColumn}>
                        <input
                            style={styles.searchInput}
                            placeholder="Buscar bandas..."
                            value={inputValue}
                            onChange={handleInputChange}
                        />

                        <div style={styles.listContainer}>
                            {bandas.map((item, index) => {
                                const generosLimitados = item.generos.slice(0, 2).join(', ');
                                const isLastElement = index === bandas.length - 1;

                                return (
                                    <div 
                                        key={item.id_banda} 
                                        ref={isLastElement ? lastBandaRef : null}
                                        style={styles.bandaCard} 
                                        onClick={() => handleBandaClick(item.slug)}
                                    >
                                        {item.url_icone_banda ? (
                                            <img
                                                style={styles.bandaImage}
                                                src={item.url_icone_banda}
                                                alt={item.nome_banda}
                                            />
                                        ) : (
                                            <div style={styles.noImage}>
                                                <span style={styles.noImageText}>Sem imagem</span>
                                            </div>
                                        )}
                                        <span style={styles.bandaName}>{item.nome_banda}</span>
                                        <span style={styles.bandaGenero}>{generosLimitados}</span>
                                        <span style={styles.bandaLocation}>
                                            {item.cidade}
                                            {item.estado && `, ${item.estado}`}
                                        </span>
                                    </div>
                                );
                            })}
                            
                            {/* Indicador de carregamento */}
                            {carregando && (
                                <div style={styles.loadingMore}>
                                    Carregando mais bandas...
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {showScrollTop && (
                    <button onClick={scrollToTop} style={styles.scrollTopButton}>
                        ↑
                    </button>
                )}
            </div>
        </div>
    );
};

const styles = {
    wrapper: {
        backgroundColor: '#151922',
        minHeight: '100vh',
        width: '100%',
      },
      container: {
        backgroundColor: '#151922',
        minHeight: '100vh',
        color: 'white',
        padding: '20px',
        maxWidth: '1200px',  // ou outro valor que preferir
        margin: '0 auto',    // para centralizar
    },
    headerContainer: {
        position: 'relative',
        width: '100%',
        height: '200px',
        marginBottom: '70px',
    },
    coverImage: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '8px',
    },
    miniPlayerContainer: {
        marginTop: '60px',
      },
    profileIcon: {
        position: 'absolute',
        bottom: '-80px',
        left: '920px',
        width: '180px',
        height: '180px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '4px solid #151922',
        boxShadow: '0 2px 4px rgba(255,255,255,0.2)',
        marginBottom: '30px',
    },
    mainContent: {
        display: 'flex',
        padding: '0 20px',
    },
    tabBarColumn: {
        width: '20%',
        paddingRight: '20px',
    },
    feedColumn: {
        flex: 1,
        overflowY: 'auto',
    },
    searchInput: {
        height: '40px',
        borderColor: '#666',
        borderWidth: '1px',
        borderRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        margin: '10px',
        color: '#000',
    },
    listContainer: {
        padding: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    bandaCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px',
        padding: '10px',
        backgroundColor: '#1f2937',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    bandaImage: {
        width: '150px',
        height: '150px',
        borderRadius: '5px',
    },
    noImage: {
        width: '150px',
        height: '150px',
        borderRadius: '5px',
        backgroundColor: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noImageText: {
        color: '#FFFFFF',
        fontSize: '12px',
    },
    bandaName: {
        fontSize: '16px',
        color: 'lightgray',
        fontWeight: 'bold',
        marginTop: '5px',
    },
    bandaLocation: {
        fontSize: '10px',
        color: '#E0E0E0',
        marginTop: '5px',
    },
    bandaGenero: {
        fontSize: '10px',
        color: '#ff6600',
        marginTop: '5px',
    },
    loadingMore: {
        textAlign: 'center' as const,
        padding: '20px',
        color: '#FFFFFF',
        width: '100%', // Adicionado para garantir que ocupe toda a largura
    },
    scrollTopButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#ff6600',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        fontSize: '24px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        transition: 'opacity 0.3s',
        opacity: 0.7,
        ':hover': {
            opacity: 1
        }
    } as React.CSSProperties,
} as const;

export default Bandas;