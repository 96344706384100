import React, { useState, useContext, useEffect } from 'react';
import MusicContext from '../context/MusicContext';
import AudioPlayer from '../screens/AudioPlayer';
import Palco from '../screens/Palco';  // Corrigido o caminho para a pasta screens

const MusicLoader: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const musicContext = useContext(MusicContext);

  if (!musicContext) {
    throw new Error("MusicContext not found");
  }

  const { state } = musicContext;

  useEffect(() => {
    // Verifica se o track foi carregado no contexto
    if (state.track && state.track.id_musica) {
      console.log("🎵 MusicLoader - Música carregada:", state.track);
      setIsLoaded(true);
    }
  }, [state.track]);

  useEffect(() => {
    // Log do estado de carregamento
    console.log("🔄 MusicLoader - Estado de carregamento:", isLoaded);
  }, [isLoaded]);

  // Enquanto não carregar, mostra o Palco
  if (!isLoaded) {
    console.log("⏳ MusicLoader - Aguardando carregamento da música...");
    return <Palco />;
  }

  // Quando carregar, mostra o AudioPlayer
  console.log("✅ MusicLoader - Renderizando AudioPlayer");
  return <AudioPlayer />;
};

export default MusicLoader;

/**
 * MusicLoader - Componente de gerenciamento de carregamento para reprodução de mídia
 * 
 * Este componente funciona como um intermediário para controlar o carregamento e renderização 
 * de conteúdo que depende de dados assíncronos do MusicContext. Atualmente usado para
 * gerenciar o fluxo de músicas compartilhadas, garantindo que todos os dados necessários
 * estejam disponíveis antes de renderizar o player.
 * 
 * Funcionamento:
 * 1. Renderiza um componente de loading (Palco) enquanto os dados são carregados
 * 2. Monitora o estado do MusicContext através do track
 * 3. Quando os dados estiverem prontos, renderiza o componente final (AudioPlayer)
 * 
 * Este padrão pode ser reutilizado para outros cenários onde:
 * - O componente depende de dados assíncronos para funcionar corretamente
 * - É necessário garantir uma ordem específica de carregamento
 * - Precisamos de um estado intermediário durante o carregamento
 * - Queremos evitar renders desnecessários ou estados indefinidos
 * 
 * @example
 * // Uso em rotas:
 * <Route path="/share/music/:id" element={<MusicLoader />} />
 */