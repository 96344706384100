import React, { useState, useEffect } from 'react';

type DescriBandaProps = {
  descricao: string;
};

const MAX_LINHAS = 10;

const DescriBanda = ({ descricao }: DescriBandaProps) => {
  const [descricaoBanda, setDescricaoBanda] = useState(descricao || '');
  const [expandido, setExpandido] = useState(false);

  useEffect(() => {
    setDescricaoBanda(descricao || '');
  }, [descricao]);

  return (
    <div style={styles.descriptionContainer}>
      <p
        style={{
          ...styles.descriptionText,
          ...(expandido ? {} : styles.truncateText),
        }}
      >
        {descricaoBanda || 'Nenhuma descrição fornecida.'}
      </p>
      {!expandido && descricaoBanda.split('\n').length > MAX_LINHAS && (
        <div style={styles.buttonContainer}>
          <button style={styles.button} onClick={() => setExpandido(true)}>
            Ver mais...
          </button>
        </div>
      )}
    </div>
  );
};

const styles = {
  descriptionContainer: {
    backgroundColor: '#2D2D2D',
    padding: 10,
    paddingTop: 20,
    borderRadius: 10,
    marginTop: 30,
    marginBottom: 30,
    position: 'relative' as 'relative',
    maxWidth: '900px',
    margin: '0 auto',
  },
  descriptionText: {
    color: '#FFFFFF',
    marginTop: 5,
    whiteSpace: 'pre-line' as 'pre-line', // Mantém quebras de linha
    lineHeight: 1.6, // Aumenta espaçamento entre linhas
    marginBottom: '1.5em', // Adiciona espaço extra entre parágrafos
  },
  truncateText: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical' as 'vertical',
    WebkitLineClamp: MAX_LINHAS,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end', // Alinha o botão à direita
  },
  button: {
    background: 'none',
    border: 'none',
    color: '#FF6600',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: 10,
  },
};

export default DescriBanda;
