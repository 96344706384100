import React, { useState } from 'react';
import GuiaAjudaDocument from '../documents/GuiaAjudaDocument';

const BotaoAjuda: React.FC<{ telaAtual: string }> = ({ telaAtual }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');

  // Função para formatar o telefone no padrão (XX) XXXXX-XXXX
  const formatPhone = (phone: string) => {
    let numbers = phone.replace(/\D/g, ''); // Remove tudo que não for número
    numbers = numbers.slice(0, 11); // Limita a 11 dígitos

    if (numbers.length <= 2) {
      return `(${numbers}`;
    } else if (numbers.length <= 7) {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
    } else {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7)}`;
    }
  };

  // Atualiza o estado do telefone ao digitar
  const handlePhoneChange = (value: string) => {
    const formattedPhone = formatPhone(value);
    setTelefone(formattedPhone);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleOpenMessageModal = () => {
    setMessageModalOpen(true);
    setModalOpen(false);
  };

  const handleCloseMessageModal = () => setMessageModalOpen(false);

  const handleSendMessage = async () => {
    if (!nome.trim()) {
      alert('O nome é obrigatório.');
      return;
    }

    if (!email.trim() && !telefone.trim()) {
      alert('Preencha pelo menos o e-mail ou o telefone para podermos entrar em contato.');
      return;
    }

    if (!userMessage.trim()) {
      alert('Digite uma mensagem antes de enviar.');
      return;
    }

    try {
      await fetch('https://tribhus.shop:5000/homeuser/ajuda/enviar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome,
          email: email || null,
          telefone: telefone || null,
          mensagem: userMessage,
          tela: telaAtual, // Agora registramos o nome correto do componente
        }),
      });
      alert('Mensagem enviada com sucesso! Entraremos em contato.');
      setUserMessage('');
      setNome('');
      setEmail('');
      setTelefone('');
      setModalOpen(false);
    } catch (error) {
      alert('Erro ao enviar a mensagem.');
    }
  };

  const handleOpenHelp = () => {
    setHelpOpen(true);
    setModalOpen(false);
  };

  const handleCloseHelp = () => setHelpOpen(false);

  return (
    <>
      <button style={styles.helpButton} onClick={handleOpenModal}>
        Dúvidas?
      </button>

      {modalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h3 style={styles.modalTitle}>Como deseja obter ajuda?</h3>

            <div style={styles.optionContainer}>
              <span style={styles.optionLabel}>Solicite ajuda pelo WhatsApp:</span>
              <span style={styles.optionValue}>(19) 99664-7030</span>
            </div>
            <hr style={styles.divider} />

            <div style={styles.optionContainer}>
              <span style={styles.optionLabel}>Solicite ajuda por mensagem:</span>
              <span style={styles.optionLink} onClick={handleOpenMessageModal}>
                Enviar Mensagem
              </span>
            </div>
            <hr style={styles.divider} />

            <div style={styles.optionContainer}>
              <span style={styles.optionLabel}>Leia nosso Guia de Ajuda:</span>
              <span style={styles.optionLink} onClick={handleOpenHelp}>
                Guia de Ajuda
              </span>
            </div>

            <button style={styles.closeButton} onClick={handleCloseModal}>
              Fechar
            </button>
          </div>
        </div>
      )}

      {messageModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h3 style={styles.modalTitle}>Envie sua dúvida</h3>
            <input
              type="text"
              style={styles.input}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              placeholder="Nome *"
            />
            <input
              type="email"
              style={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
            />
            <input
              type="tel"
              style={styles.input}
              value={telefone}
              onChange={(e) => handlePhoneChange(e.target.value)}
              placeholder="Telefone"
            />
            <textarea
              style={styles.textArea}
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Digite sua dúvida aqui..."
            />
            <button style={styles.modalButton} onClick={handleSendMessage}>
              Enviar
            </button>
            <button style={styles.closeButton} onClick={handleCloseMessageModal}>
              Fechar
            </button>
          </div>
        </div>
      )}

      {helpOpen && (
        <div style={styles.modalOverlay} onClick={handleCloseHelp}>
          <div style={styles.helpModal} onClick={(e) => e.stopPropagation()}>
            <button style={styles.closeButton} onClick={handleCloseHelp}>
              Fechar
            </button>
            <GuiaAjudaDocument />
          </div>
        </div>
      )}
    </>
  );
};

const styles: {
  helpButton: React.CSSProperties,
  modalOverlay: React.CSSProperties,
  modalContent: React.CSSProperties,
  modalTitle: React.CSSProperties,
  optionContainer: React.CSSProperties,
  optionLabel: React.CSSProperties,
  optionValue: React.CSSProperties,
  optionLink: React.CSSProperties,
  divider: React.CSSProperties,
  input: React.CSSProperties,
  textArea: React.CSSProperties,
  modalButton: React.CSSProperties,
  closeButton: React.CSSProperties,
  helpModal: React.CSSProperties,
} = {
  helpButton: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#914100',
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#1F1F1F',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    width: '400px',
    maxWidth: '90%',
  },
  modalTitle: {
    color: '#fff',
    marginBottom: '15px',
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  optionLabel: {
    color: '#fff',
  },
  optionValue: {
    color: '#fff',
    fontWeight: 'bold',
  },
  optionLink: {
    color: '#FF6600',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  divider: {
    borderTop: '1px solid #ccc',
    margin: '10px 0',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  textArea: {
    width: '100%',
    height: '80px',
    padding: '8px',
  },
  modalButton: {
    backgroundColor: '#914100',
    color: '#fff',
    border: 'none',
    padding: '10px',
    width: '100%',
    marginBottom: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  closeButton: {
    backgroundColor: 'transparent',
    color: '#FF6600',
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  helpModal: {
    backgroundColor: '#1F1F1F',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
  },
};

export default BotaoAjuda;
