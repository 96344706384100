import React, { useEffect, useContext, PropsWithChildren, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SlugContext } from '../context/SlugContext';
import MusicContext from '../context/MusicContext';
import { useAuth } from '../context/AuthContext';

const SlugInterceptor: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = useContext(SlugContext);
  const musicContext = useContext(MusicContext);
  const { state: authState } = useAuth();
  const lastLoadedMusicId = useRef<number | null>(null);

  if (!musicContext) {
    throw new Error("MusicContext not found");
  }

  useEffect(() => {
    const slug = location.hash.slice(1);
    const urlParams = new URLSearchParams(location.search);
    const idMusica = urlParams.get("id_musica");

    // Processa o slug do perfil (mantém funcionalidade existente)
    if (slug) {
      dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
      console.log(`👤 Slug do perfil capturado: ${slug}`);
      window.history.replaceState(null, '', location.pathname);
    }

    // Processa o ID da música
    if (idMusica) {
      console.log(`🎵 ID da música capturado: ${idMusica}`);

      // Se a música já foi carregada, evita repetição
      if (lastLoadedMusicId.current === Number(idMusica)) {
        console.log("🔁 Música já carregada recentemente. Evitando nova requisição.");
        return;
      }

      // Atualiza a referência do ID carregado
      lastLoadedMusicId.current = Number(idMusica);

      // Limpa o estado antes de carregar a nova música
      if (musicContext.state.howl) {
        musicContext.state.howl.unload();
      }

      let token = authState.token || localStorage.getItem('accessToken');
      if (!token) {
        console.error("❌ Nenhum token disponível. Não será possível buscar a música.");
        return;
      }

      fetch(`https://tribhus.shop:5000/bands/music/${idMusica}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (!response.ok) throw new Error("Música não encontrada");
        return response.json();
      })
      .then(track => {
        console.log("🎶 Dados da música recebidos:", track);
        musicContext.setPlaylist([track]);
        musicContext.selectTrack(track);
        // Redireciona para a nova rota de música compartilhada
        navigate(`/share/music/${idMusica}`);
      })
      .catch(error => {
        console.error("❌ Erro ao buscar música:", error);
        musicContext.state.howl?.unload();
      });

      // Remove os parâmetros da URL mas mantém o pathname
      window.history.replaceState(null, '', location.pathname);
    }
  }, [location, dispatch, musicContext, authState.token, navigate]);

  return <>{children}</>;
};

export default SlugInterceptor;