import React, { useState, useEffect, useRef } from 'react';
import axios from '../Services/axiosInterceptor';
import Hls from 'hls.js';
import { useParams, useNavigate } from 'react-router-dom';

interface VideoVodPlayerProps {
  id_videosvod?: number;
  onBack?: () => void;
}

interface VideoData {
  id_videosvod: number;
  titulo_videosvod: string;
  descricao: string | null;
  streaming_url: string;
  thumbnail_url: string | null;
  status: string;
  duracao: number | null;
  nome_banda: string;
  data_upload: string;
}

const VideoVodPlayer: React.FC<VideoVodPlayerProps> = ({ id_videosvod: propId, onBack }) => {
  // Obter ID do vídeo da URL ou das props
  const { id } = useParams<{ id?: string }>();
  const videoId = propId || (id ? parseInt(id, 10) : undefined);
  
  const [videoData, setVideoData] = useState<VideoData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [bufferingState, setBufferingState] = useState<boolean>(false);
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const controlsTimerRef = useRef<NodeJS.Timeout | null>(null);
  const hls = useRef<Hls | null>(null);
  const navigate = useNavigate();

  // Formatar duração de segundos para HH:MM:SS
  const formatTime = (seconds: number): string => {
    if (!seconds || isNaN(seconds)) return '00:00';
    
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    
    const formattedMins = mins.toString().padStart(2, '0');
    const formattedSecs = secs.toString().padStart(2, '0');
    
    if (hrs > 0) {
      const formattedHrs = hrs.toString().padStart(2, '0');
      return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
    }
    
    return `${formattedMins}:${formattedSecs}`;
  };

  // Formatar data para o formato brasileiro
  const formatDate = (dateString: string): string => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };// Função fetchVideoData modificada com logs adicionais
  useEffect(() => {
    const fetchVideoData = async () => {
      console.log('🔍 Iniciando busca de vídeo com ID:', videoId);
      
      if (!videoId) {
        console.error('❌ ID de vídeo não fornecido');
        setError('ID de vídeo não fornecido');
        setIsLoading(false);
        return;
      }
  
      try {
        setIsLoading(true);
        console.log('🔄 Fazendo requisição para:', `/videos/videosvod/detalhe/${videoId}`);
        
        // API para obter detalhes do vídeo
        const response = await axios.get(`/videos/videosvod/detalhe/${videoId}`);
        
        console.log('✅ Resposta recebida do servidor:', response.status);
        console.log('📦 Dados do vídeo:', response.data);
        
        // Verificar se os dados são válidos
        if (!response.data) {
          console.error('❌ Dados do vídeo ausentes na resposta');
          throw new Error('Dados do vídeo inválidos ou não encontrados');
        }
        
        if (response.status !== 200) {
          console.error('❌ Status da resposta não é 200:', response.status);
          throw new Error(`Resposta inesperada do servidor: ${response.status}`);
        }
        
        console.log('✅ Dados válidos, atualizando estado do componente');
        setVideoData(response.data);
        
        // Verificar campos importantes
        console.log('🎬 URL de streaming:', response.data.streaming_url);
        console.log('🖼️ URL de thumbnail:', response.data.thumbnail_url);
        console.log('📊 Status do vídeo:', response.data.status);
        
        // Se houver duração no banco de dados, atualizar o estado
        if (response.data.duracao) {
          console.log('⏱️ Duração do vídeo:', response.data.duracao, 'segundos');
          setDuration(response.data.duracao);
        } else {
          console.log('⚠️ Vídeo sem duração definida no banco de dados');
        }
      } catch (err) {
        console.error('❌ Erro ao carregar dados do vídeo:', err);
        
        // Log detalhado do erro
        if (axios.isAxiosError(err)) {
          console.error('📡 Detalhes do erro de requisição:');
          console.error('- Status:', err.response?.status);
          console.error('- Mensagem:', err.response?.data);
          console.error('- URL da requisição:', err.config?.url);
        }
        
        setError('Não foi possível carregar o vídeo. Tente novamente mais tarde.');
      } finally {
        setIsLoading(false);
        console.log('🏁 Finalizada a tentativa de busca do vídeo');
      }
    };
  
    fetchVideoData();
  }, [videoId]);

  // Configurar o player HLS quando os dados do vídeo estiverem disponíveis
  useEffect(() => {
    if (!videoData || !videoData.streaming_url || videoData.status !== 'disponivel') return;

    const setupHlsPlayer = () => {
      const video = videoRef.current;
      if (!video) return;

      if (hls.current) {
        hls.current.destroy();
      }

      // Verificar se o navegador suporta HLS nativamente
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // Safari e iOS suportam HLS nativamente
        video.src = videoData.streaming_url;
        
        // Adicionar event listeners para o vídeo
        setupVideoEvents(video);
      } else if (Hls.isSupported()) {
        // Para outros navegadores, usar a biblioteca HLS.js
        const newHls = new Hls({
          maxBufferLength: 30,
          maxMaxBufferLength: 60,
          enableWorker: true,
          lowLatencyMode: false,
          backBufferLength: 60
        });
        
        newHls.loadSource(videoData.streaming_url);
        newHls.attachMedia(video);
        
        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          setupVideoEvents(video);
        });

        newHls.on(Hls.Events.ERROR, (_event, data) => {
          if (data.fatal) {
            console.error('Erro fatal no HLS player:', data);
            
            if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
              // Tentar reconectar
              newHls.startLoad();
              setBufferingState(true);
            } else if (data.type === Hls.ErrorTypes.MEDIA_ERROR) {
              // Tentar recuperar erro de mídia
              newHls.recoverMediaError();
              setBufferingState(true);
            } else {
              // Outro erro fatal, destruir e recriar
              newHls.destroy();
              setupHlsPlayer();
            }
          }
        });

        // Eventos de buffer
        newHls.on(Hls.Events.BUFFER_CREATED, () => {
          setBufferingState(true);
        });
        
        newHls.on(Hls.Events.BUFFER_APPENDED, () => {
          setBufferingState(false);
        });

        hls.current = newHls;
      } else {
        setError('Seu navegador não suporta a reprodução deste vídeo.');
      }
    };

    const setupVideoEvents = (video: HTMLVideoElement) => {
      // Eventos para atualizar a UI
      video.addEventListener('play', () => setIsPlaying(true));
      video.addEventListener('pause', () => setIsPlaying(false));
      video.addEventListener('timeupdate', () => setCurrentTime(video.currentTime));
      video.addEventListener('volumechange', () => {
        setVolume(video.volume);
        setIsMuted(video.muted);
      });
      video.addEventListener('loadedmetadata', () => {
        if (video.duration && !isNaN(video.duration) && isFinite(video.duration)) {
          setDuration(video.duration);
        }
      });
      video.addEventListener('waiting', () => setBufferingState(true));
      video.addEventListener('canplay', () => setBufferingState(false));
    };

    setupHlsPlayer();

    // Limpar quando o componente for desmontado
    return () => {
      if (hls.current) {
        hls.current.destroy();
      }
      
      if (videoRef.current) {
        const video = videoRef.current;
        video.removeEventListener('play', () => setIsPlaying(true));
        video.removeEventListener('pause', () => setIsPlaying(false));
        video.removeEventListener('timeupdate', () => setCurrentTime(video.currentTime));
        video.removeEventListener('volumechange', () => {
          setVolume(video.volume);
          setIsMuted(video.muted);
        });
        video.removeEventListener('loadedmetadata', () => {
          if (video.duration) setDuration(video.duration);
        });
        video.removeEventListener('waiting', () => setBufferingState(true));
        video.removeEventListener('canplay', () => setBufferingState(false));
      }
    };
  }, [videoData]);

  // Controlar exibição dos controles de vídeo
  const [showControls, setShowControls] = useState<boolean>(true);
  
  useEffect(() => {
    const hideControlsTimer = () => {
      if (controlsTimerRef.current) {
        clearTimeout(controlsTimerRef.current);
      }
      
      if (isPlaying) {
        controlsTimerRef.current = setTimeout(() => {
          setShowControls(false);
        }, 3000);
      }
    };

    hideControlsTimer();

    return () => {
      if (controlsTimerRef.current) {
        clearTimeout(controlsTimerRef.current);
      }
    };
  }, [isPlaying, showControls]);

  // Manipuladores de eventos
  const handlePlayPause = () => {
    const video = videoRef.current;
    if (!video) return;
    
    if (video.paused) {
      video.play().catch(e => {
        console.warn('Erro ao tentar reproduzir:', e);
      });
    } else {
      video.pause();
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const video = videoRef.current;
    if (!video) return;
    
    const newTime = parseFloat(e.target.value);
    video.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const video = videoRef.current;
    if (!video) return;
    
    const newVolume = parseFloat(e.target.value);
    video.volume = newVolume;
    setVolume(newVolume);
    
    if (newVolume === 0) {
      video.muted = true;
      setIsMuted(true);
    } else if (video.muted) {
      video.muted = false;
      setIsMuted(false);
    }
  };

  const handleMuteToggle = () => {
    const video = videoRef.current;
    if (!video) return;
    
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };

  const handleFullscreenToggle = () => {
    if (!videoContainerRef.current) return;
    
    if (!document.fullscreenElement) {
      videoContainerRef.current.requestFullscreen().then(() => {
        setIsFullscreen(true);
      }).catch(err => {
        console.error(`Erro ao entrar em tela cheia: ${err.message}`);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      }).catch(err => {
        console.error(`Erro ao sair da tela cheia: ${err.message}`);
      });
    }
  };

  const handleVideoContainerMouseMove = () => {
    setShowControls(true);
    
    if (controlsTimerRef.current) {
      clearTimeout(controlsTimerRef.current);
    }
    
    if (isPlaying) {
      controlsTimerRef.current = setTimeout(() => {
        setShowControls(false);
      }, 3000);
    }
  };
  
  const handleBackButton = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1); // Volta para a página anterior
    }
  };

  if (!videoId) {
    return (
      <div style={styles.container}>
        <div style={styles.errorContainer}>
          <p style={styles.errorText}>ID de vídeo não fornecido</p>
          <button 
            onClick={handleBackButton}
            style={styles.backButton}
          >
            Voltar
          </button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={styles.container}>
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
          <p style={styles.loadingText}>Carregando vídeo...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.container}>
        <div style={styles.errorContainer}>
          <p style={styles.errorText}>{error}</p>
          <button 
            onClick={handleBackButton}
            style={styles.backButton}
          >
            Voltar
          </button>
        </div>
      </div>
    );
  }

  if (!videoData) {
    return (
      <div style={styles.container}>
        <div style={styles.errorContainer}>
          <p style={styles.errorText}>Vídeo não encontrado.</p>
          <button 
            onClick={handleBackButton}
            style={styles.backButton}
          >
            Voltar
          </button>
        </div>
      </div>
    );
  }

  if (videoData.status === 'processando') {
    return (
      <div style={styles.container}>
        <div style={styles.processingContainer}>
          <div style={styles.spinner}></div>
          <h3 style={styles.processingTitle}>{videoData.titulo_videosvod}</h3>
          <p style={styles.processingText}>
            Este vídeo ainda está sendo processado. Por favor, aguarde alguns minutos.
          </p>
          <button 
            onClick={handleBackButton}
            style={styles.backButton}
          >
            Voltar
          </button>
        </div>
      </div>
    );
  }

  if (videoData.status === 'erro') {
    return (
      <div style={styles.container}>
        <div style={styles.errorContainer}>
          <h3 style={styles.errorTitle}>{videoData.titulo_videosvod}</h3>
          <p style={styles.errorText}>
            Ocorreu um erro ao processar este vídeo. Entre em contato com o suporte.
          </p>
          <button 
            onClick={handleBackButton}
            style={styles.backButton}
          >
            Voltar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div 
        ref={videoContainerRef} 
        style={styles.playerContainer}
        onMouseMove={handleVideoContainerMouseMove}
      >
        <video
          ref={videoRef}
          style={styles.videoPlayer}
          playsInline
          poster={videoData.thumbnail_url || undefined}
          onClick={handlePlayPause}
        />
        
        {/* Overlay de carregamento */}
        {bufferingState && (
          <div style={styles.bufferingOverlay}>
            <div style={styles.spinner}></div>
          </div>
        )}
        
        {/* Controles personalizados */}
        {showControls && (
          <div style={styles.controls}>
            <div style={styles.topControls}>
              <button 
                onClick={handleBackButton}
                style={styles.backButtonOverlay}
              >
                ← Voltar
              </button>
            </div>
            
            <div style={styles.centerControls}>
              <button 
                onClick={handlePlayPause}
                style={styles.playPauseButton}
              >
                {isPlaying ? '❚❚' : '▶'}
              </button>
            </div>
            
            <div style={styles.bottomControls}>
              <div style={styles.progressContainer}>
                <input
                  type="range"
                  min="0"
                  max={duration || 100}
                  value={currentTime}
                  onChange={handleSeek}
                  style={styles.progressBar}
                />
                <div style={styles.timeDisplay}>
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
              </div>
              
              <div style={styles.rightControls}>
                <div style={styles.volumeControl}>
                  <button 
                    onClick={handleMuteToggle}
                    style={styles.muteButton}
                  >
                    {isMuted ? '🔇' : volume > 0.5 ? '🔊' : volume > 0 ? '🔉' : '🔈'}
                  </button>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={isMuted ? 0 : volume}
                    onChange={handleVolumeChange}
                    style={styles.volumeSlider}
                  />
                </div>
                
                <button 
                  onClick={handleFullscreenToggle}
                  style={styles.fullscreenButton}
                >
                  {isFullscreen ? '⤧' : '⤢'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      
      <div style={styles.videoInfo}>
        <h2 style={styles.videoTitle}>{videoData.titulo_videosvod}</h2>
        
        <div style={styles.metaInfo}>
          <span style={styles.bandName}>{videoData.nome_banda}</span>
          <div style={styles.rightMeta}>
            {videoData.data_upload && (
              <span style={styles.uploadDate}>
                Enviado em {formatDate(videoData.data_upload)}
              </span>
            )}
            {videoData.duracao && (
              <span style={styles.duration}>
                {formatTime(videoData.duracao)}
              </span>
            )}
          </div>
        </div>
        
        {videoData.descricao && (
          <p style={styles.videoDescription}>{videoData.descricao}</p>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    backgroundColor: '#151922',
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 20,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  playerContainer: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#000',
  },
  videoPlayer: {
    width: '100%',
    backgroundColor: '#000',
    aspectRatio: '16 / 9',
    display: 'block',
  },
  controls: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '15px',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,0.7) 100%)',
  },
  topControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  centerControls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomControls: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  progressContainer: {
    width: '100%',
  },
  progressBar: {
    width: '100%',
    height: '5px',
    WebkitAppearance: 'none',
    appearance: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    outline: 'none',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  timeDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    fontSize: '12px',
    marginTop: '5px',
  },
  rightControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
  volumeControl: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  volumeSlider: {
    width: '60px',
    height: '5px',
    WebkitAppearance: 'none',
    appearance: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    outline: 'none',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  playPauseButton: {
    backgroundColor: 'rgba(145, 65, 0, 0.8)',
    border: 'none',
    color: 'white',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    fontSize: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  muteButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '18px',
    cursor: 'pointer',
  },
  fullscreenButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '18px',
    cursor: 'pointer',
  },
  backButtonOverlay: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '14px',
    cursor: 'pointer',
    padding: '5px 10px',
  },
  videoInfo: {
    padding: 20,
  },
  videoTitle: {
    color: 'white',
    fontSize: 20,
    marginBottom: 8,
    fontWeight: 'bold',
  },
  metaInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  rightMeta: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  bandName: {
    color: '#914100',
    fontSize: 14,
  },
  uploadDate: {
    color: '#999',
    fontSize: 12,
  },
  duration: {
    color: '#888',
    fontSize: 14,
  },
  videoDescription: {
    color: '#ccc',
    fontSize: 14,
    lineHeight: 1.5,
    marginTop: 10,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  spinner: {
    width: 40,
    height: 40,
    border: '3px solid rgba(255, 255, 255, 0.1)',
    borderTopColor: '#914100',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  loadingText: {
    color: 'white',
    marginTop: 10,
  },
  errorContainer: {
    padding: 40,
    textAlign: 'center',
  },
  errorTitle: {
    color: 'white',
    marginBottom: 10,
  },
  errorText: {
    color: '#ff6b6b',
    fontSize: 16,
    marginBottom: 20,
  },
  processingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
    textAlign: 'center',
  },
  processingTitle: {
    color: 'white',
    marginTop: 15,
    marginBottom: 10,
  },
  processingText: {
    color: '#999',
    fontSize: 14,
    marginBottom: 20,
  },
  backButton: {
    backgroundColor: '#914100',
    color: 'white',
    border: 'none',
    padding: '8px 15px',
    borderRadius: 20,
    cursor: 'pointer',
    fontSize: 14,
  },
  bufferingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

// Adicionar a animação de spinner ao CSS global
const addSpinnerStyles = () => {
  if (typeof document !== 'undefined') {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      
      input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #914100;
        cursor: pointer;
      }
      
      input[type=range]::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #914100;
        cursor: pointer;
      }
    `;
    document.head.appendChild(style);
  }
};

addSpinnerStyles();

export default VideoVodPlayer;