import React, { useEffect, useState } from 'react';
import axios from '../Services/axiosInterceptor';

interface AlbumFotos {
id_album_foto: number;
nome: string;
descricao: string;
id_proprietario?: number;
tipo_proprietario?: string;
data_criacao?: string;
imagens?: string[];
}

interface Foto {
id_foto: number;
url_foto: string;
legenda: string | null;
descricao: string | null;
titulo: string | null;
caminho_arquivo: string;
data_upload: string;
id_album_foto: number;
}

interface ModalFotosProps {
isOpen: boolean;
album: AlbumFotos;
onClose: () => void;
token: string;
}

const ModalFotos: React.FC<ModalFotosProps> = ({ isOpen, album, onClose, token }) => {
const [fotos, setFotos] = useState<Foto[]>([]);
const [selectedFotoIndex, setSelectedFotoIndex] = useState<number | null>(null);

useEffect(() => {
  const buscarFotos = async () => {
    try {
      const response = await axios.get(`https://tribhus.shop:5000/fotos/fotos/${album.id_album_foto}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFotos(response.data);
    } catch (error) {
      console.error('Erro ao buscar fotos do álbum:', error);
    }
  };

  if (isOpen && album) {
    buscarFotos();
  }
}, [isOpen, album, token]);

if (!isOpen) return null;

const handleFotoClick = (index: number) => {
  setSelectedFotoIndex(index);
};

const handleClose = (e: React.MouseEvent) => {
  if (e.target === e.currentTarget) {
    onClose();
  }
};

const handlePrev = () => {
  if (selectedFotoIndex !== null && selectedFotoIndex > 0) {
    setSelectedFotoIndex(selectedFotoIndex - 1);
  }
};

const handleNext = () => {
  if (selectedFotoIndex !== null && selectedFotoIndex < fotos.length - 1) {
    setSelectedFotoIndex(selectedFotoIndex + 1);
  }
};

return (
  <div style={styles.modalOverlay} onClick={handleClose}>
    <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
      <div style={styles.modalHeader}>
        <h2 style={styles.modalTitle}>{album.nome}</h2>
        <button style={styles.closeButton} onClick={onClose}>×</button>
      </div>
      
      {selectedFotoIndex !== null ? (
        <div style={styles.fotoViewer}>
          <div style={styles.navButtonsContainer}>
            <button 
              style={{...styles.navButton, ...styles.prevButton}} 
              onClick={handlePrev}
              disabled={selectedFotoIndex === 0}
            >
              &#9664;
            </button>
            
            <button 
              style={{...styles.navButton, ...styles.nextButton}} 
              onClick={handleNext}
              disabled={selectedFotoIndex === fotos.length - 1}
            >
              &#9654;
            </button>
          </div>
          
          <div style={styles.mainFotoContainer}>
            <img 
              src={fotos[selectedFotoIndex].url_foto} 
              alt={`Foto ${selectedFotoIndex + 1}`} 
              style={styles.mainFoto} 
            />
            
            {fotos[selectedFotoIndex].legenda && (
              <div style={styles.legendaContainer}>
                <p style={styles.legendaText}>
                  {fotos[selectedFotoIndex].legenda}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={styles.fotosGrid}>
          {fotos.map((foto, index) => (
            <div key={foto.id_foto} style={styles.fotoContainer} onClick={() => handleFotoClick(index)}>
              <img src={foto.url_foto} alt={`Miniatura ${index + 1}`} style={styles.fotoThumbnail} />
              {foto.legenda && (
                <div style={styles.thumbnailLegendaContainer}>
                  <p style={styles.thumbnailLegendaText}>{foto.legenda}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);
};

  const styles: { [key: string]: React.CSSProperties } = {
modalOverlay: {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
},
modalContent: {
  width: '90%',
  maxWidth: '1200px',
  maxHeight: '90vh',
  backgroundColor: '#1E2736',
  borderRadius: '10px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column' as const,
},
nextButton: {
  marginLeft: '10px',
},
modalHeader: {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 20px',
  borderBottom: '1px solid #30343f',
},
modalTitle: {
  color: '#FF6600',
  margin: 0,
  fontSize: '20px',
},
closeButton: {
  background: 'none',
  border: 'none',
  color: 'white',
  fontSize: '30px',
  cursor: 'pointer',
},
fotosGrid: {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: '15px',
  padding: '20px',
  overflowY: 'auto' as const,
  maxHeight: 'calc(90vh - 70px)',
},
fotoContainer: {
  position: 'relative' as const,
  borderRadius: '10px',
  overflow: 'hidden',
  cursor: 'pointer',
  aspectRatio: '1/1',
},
fotoThumbnail: {
  width: '100%',
  height: '100%',
  objectFit: 'cover' as const,
},
thumbnailLegendaContainer: {
  position: 'absolute' as const,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  padding: '8px',
},
thumbnailLegendaText: {
  color: 'white',
  margin: 0,
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical' as const,
  lineHeight: '1.2em',
  maxHeight: '2.4em', // 2 linhas * line-height
},
fotoViewer: {
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  height: 'calc(90vh - 70px)',
  position: 'relative' as const,
},
mainFotoContainer: {
  width: '100%',
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative' as const,
  maxHeight: 'calc(90vh - 130px)',
},
mainFoto: {
  maxHeight: '60vh',
  maxWidth: '90%',
  objectFit: 'contain' as const,
  borderRadius: '5px',
},
legendaContainer: {
  width: '90%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  padding: '15px',
  marginTop: '20px',
  borderRadius: '5px',
  maxHeight: '140px', // Aumentado para acomodar aproximadamente 4 linhas
  overflowY: 'auto' as const,
},
legendaText: {
  color: 'white',
  margin: 0,
  fontSize: '16px',
  textAlign: 'center' as const,
  lineHeight: '1.5em',
},
navButtonsContainer: {
  position: 'absolute' as const,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  zIndex: 5,
},
navButton: {
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  borderRadius: '50%',
  color: 'white',
  fontSize: '20px',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  pointerEvents: 'auto',
},
prevButton: {
  marginRight: '10px',
},
voltarButton: {
  backgroundColor: '#FF6600',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 15px',
  cursor: 'pointer',
  marginTop: '15px',
  fontSize: '14px',
},
};

export default ModalFotos;