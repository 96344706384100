import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor'; 

interface Band {
    id_banda: number;
    nome_banda: string;
    cnpj?: string;
    nome_responsavel: string;
    nasc_responsavel: string;
    data_inicio?: string;
    telefone?: string;
    descricao?: string;
    url_capa_banda?: string;
    url_icone_banda?: string;
    cidade: string;
    estado?: string;
    id_musica_apresentacao?: number;
    url_facebook?: string;
    url_instagram?: string;
    url_spotify?: string;
    sobrenome_responsavel: string;
    auth_id: number;
    slug?: string;
}

interface FormData {
    nome_banda: string;
    cnpj: string;
    nome_responsavel: string;
    sobrenome_responsavel: string;
    nasc_responsavel: string;
    data_inicio: string;
    cidade: string;
    estado: string;
}

interface Estado {
    nome: string;
    uf: string;
    codigo_uf: number;
}

interface Cidade {
    nome: string;
}

interface DataFormBandProps {
    band: Band;
    handleChange: (field: keyof FormData, value: string) => void;
}

const DataFormBand: React.FC<DataFormBandProps> = ({ band, handleChange }) => {
    const { state: authState } = useContext(AuthContext);
    const [estados, setEstados] = useState<Estado[]>([]);
    const [cidades, setCidades] = useState<Cidade[]>([]);
    const [estadoSelecionado, setEstadoSelecionado] = useState<string>('');
    const [cidadeSelecionada, setCidadeSelecionada] = useState<string>('');

    const [formData, setFormData] = useState<FormData>({
        nome_banda: band.nome_banda || '',
        cnpj: band.cnpj || '',
        nome_responsavel: band.nome_responsavel || '',
        sobrenome_responsavel: band.sobrenome_responsavel || '',
        nasc_responsavel: band.nasc_responsavel || '',
        data_inicio: band.data_inicio || '',
        cidade: band.cidade || '',
        estado: band.estado || '',
    });

    useEffect(() => {
        // Buscar a sigla UF correspondente ao estado da banda
        const estadoUF = estados.find(e => e.nome === band.estado)?.uf || band.estado || '';
    
        setFormData({
            nome_banda: band.nome_banda || '',
            cnpj: band.cnpj || '',
            nome_responsavel: band.nome_responsavel || '',
            sobrenome_responsavel: band.sobrenome_responsavel || '',
            nasc_responsavel: band.nasc_responsavel || '',
            data_inicio: band.data_inicio || '',
            cidade: band.cidade || '',
            estado: estadoUF, // Agora armazena a sigla do estado
        });
    
        setEstadoSelecionado(estadoUF);
        setCidadeSelecionada(band.cidade || '');
    
        loadEstados();
        if (estadoUF) {
            const codigoUf = estados.find(e => e.uf === estadoUF)?.codigo_uf;
            if (codigoUf) {
                loadCidades(codigoUf);
            }
        }
    }, [band, estados]);     

    const handleFocus = (field: keyof FormData) => {
        if (!['estado', 'cidade'].includes(field)) {
            setFormData(prev => ({ ...prev, [field]: '' }));
        }
    };

    const handleBlur = (field: keyof FormData) => {
        if (formData[field] === '') {
            setFormData(prev => ({ ...prev, [field]: band[field as keyof Band] || '' }));
        }
        handleChange(field, formData[field]);
    };

    const loadEstados = async () => {
        try {
            const response = await axios.get<Estado[]>('https://tribhus.shop:5000/city/estados');
            setEstados(response.data);
        } catch (error) {
            console.error('Erro ao buscar estados:', error);
        }
    };

    const loadCidades = async (codigo_uf: number) => {
        try {
            const response = await axios.get<Cidade[]>(`https://tribhus.shop:5000/city/municipios/${codigo_uf}`);
            setCidades(response.data);
        } catch (error) {
            console.error('Erro ao buscar cidades:', error);
        }
    };

    const saveProfileChanges = async () => {
        try {
            // Certifique-se de enviar apenas a sigla do estado
            const estadoUF = estados.find(e => e.nome === estadoSelecionado)?.uf || estadoSelecionado;
    
            const dataToSend = {
                ...formData,
                estado: estadoUF, // Envia apenas a sigla do estado
            };
    
            console.log("Data being sent to server:", dataToSend);
            const response = await axios.put(`https://tribhus.shop:5000/bands/profile/${band.id_banda}`, dataToSend, {
                headers: { Authorization: `Bearer ${authState.token}` }
            });
    
            if (response.status === 200) {
                alert('Perfil atualizado com sucesso!');
                Object.keys(formData).forEach(key => {
                    handleChange(key as keyof FormData, formData[key as keyof FormData]);
                });
            } else {
                alert('Falha ao atualizar perfil.');
            }
        } catch (error) {
            console.error('Erro ao atualizar perfil:', error);
            alert('Erro ao atualizar perfil. Verifique sua conexão e tente novamente.');
        }
    };    

    const formatDate = (dateStr: string) => {
        if (!dateStr) return '';
        const [year, month, day] = dateStr.split('-').map(num => parseInt(num, 10));
        const date = new Date(year, month - 1, day);
        return date.toLocaleDateString('pt-BR');
    };

    const handleDateChange = (field: keyof Pick<FormData, 'nasc_responsavel' | 'data_inicio'>, event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = event.target.value;
        setFormData(prev => ({ ...prev, [field]: selectedDate }));
        handleChange(field, selectedDate);
    };

    const styles = {
        formContainer: {
            padding: 20,
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 400,
            maxWidth: '100%', // Garante que o contêiner não exceda a largura disponível
            margin: '0 auto',
            backgroundColor: '#1E1E1E',
            borderRadius: 10,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        input: {
            height: 40,
            borderColor: 'gray',
            borderWidth: 1,
            marginBottom: 10,
            paddingLeft: 20, // Aumenta o espaçamento à esquerda
            paddingRight: 10,
            width: '100%', // Garante que o input ocupe 100% da largura do contêiner pai
            boxSizing: 'border-box' as 'border-box', // Inclui padding e border na largura total
            borderRadius: 20,
            color: '#fff',
            backgroundColor: '#151922',
        },
        fieldLabel: {
            fontSize: 14,
            color: '#FF6600',
            marginBottom: 5,
            alignSelf: 'flex-start',
        },
        fieldContainer: {
            marginBottom: 15,
            width: '100%', // Garante que o contêiner de cada campo ocupe 100% da largura do contêiner pai
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'flex-start',
            boxSizing: 'border-box' as 'border-box',
        },
        button: {
            backgroundColor: '#FF6600',
            padding: '15px 50px',
            borderRadius: 10,
            border: 'none',
            cursor: 'pointer',
            marginTop: 20,
        },
        buttonText: {
            color: '#fff',
            fontSize: 16,
        },
        select: {
            height: 40,
            borderColor: 'gray',
            borderWidth: 1,
            marginBottom: 10,
            paddingLeft: 20, // Aumenta o espaçamento à esquerda
            paddingRight: 10,
            width: '100%', // Garante que o select ocupe 100% da largura do contêiner pai
            boxSizing: 'border-box' as 'border-box',
            borderRadius: 20,
            color: '#fff',
            backgroundColor: '#151922',
        },
    };    

    return (
        <div style={styles.formContainer}>
            <div style={styles.fieldContainer}>
                <label style={styles.fieldLabel}>Estado</label>
                <select 
                    style={styles.select}
                    value={estadoSelecionado} 
                    onChange={(e) => {
                        const estadoUF = e.target.value; // Agora pegamos a sigla UF
                        setEstadoSelecionado(estadoUF);
                        setCidadeSelecionada('');
                        setFormData(prev => ({ ...prev, estado: estadoUF, cidade: '' })); // Armazena UF
                        handleChange('estado', estadoUF);
                        handleChange('cidade', '');
                        const estado = estados.find(e => e.uf === estadoUF); // Busca pelo UF agora
                        if (estado) loadCidades(estado.codigo_uf);
                    }}
                >
                    <option value="">Selecionar Estado</option>
                    {estados.map(estado => (
                        <option key={estado.codigo_uf} value={estado.uf}>{estado.nome}</option> 
                        // Agora value usa estado.uf (sigla) e exibe estado.nome (nome completo)
                    ))}
                </select>
            </div>  
    
            <div style={styles.fieldContainer}>
                <label style={styles.fieldLabel}>Cidade</label>
                <select 
                    style={styles.select}
                    value={cidadeSelecionada} 
                    onChange={(e) => {
                        const cidadeNome = e.target.value;
                        setCidadeSelecionada(cidadeNome);
                        setFormData(prev => ({ ...prev, cidade: cidadeNome }));
                        handleChange('cidade', cidadeNome);
                    }}
                    disabled={!estadoSelecionado}
                >
                    <option value="">Selecionar Cidade</option>
                    {cidades.map(cidade => (
                        <option key={cidade.nome} value={cidade.nome}>{cidade.nome}</option>
                    ))}
                </select>
            </div>
    
            {Object.keys(formData)
                .filter(field => field !== 'cidade' && field !== 'estado') // Filtrando os campos duplicados
                .map((field) => (
                <div key={field} style={styles.fieldContainer}>
                    <label style={styles.fieldLabel}>
                        {field === 'nasc_responsavel' ? 'Data de nascimento do responsável' : 
                         field === 'data_inicio' ? 'Data de início da Banda' : 
                         field === 'cnpj' ? 'CNPJ' : 
                         field.charAt(0).toUpperCase() + field.slice(1).replace('_', ' ')}
                    </label>
    
                    {field === 'cnpj' ? (
                        <input
                            type="text"
                            style={styles.input}
                            value={formData[field as keyof FormData]}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, '');
                                const cnpj = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
                                setFormData(prev => ({ ...prev, [field]: cnpj }));
                                handleChange(field as keyof FormData, cnpj);
                            }}
                            onFocus={() => handleFocus(field as keyof FormData)}
                            onBlur={() => handleBlur(field as keyof FormData)}
                            placeholder='Digite o CNPJ'
                        />
                    ) : ['nasc_responsavel', 'data_inicio'].includes(field) ? (
                        <div>
                            <input
                                type="date"
                                style={styles.input}
                                value={formData[field as keyof FormData]}
                                onChange={(e) => handleDateChange(field as 'nasc_responsavel' | 'data_inicio', e)}
                                onFocus={() => handleFocus(field as keyof FormData)}
                                onBlur={() => handleBlur(field as keyof FormData)}
                            />
                            <span>{formatDate(formData[field as keyof FormData])}</span>
                        </div>
                    ) : (
                        <input
                            type="text"
                            style={styles.input}
                            value={formData[field as keyof FormData]}
                            onChange={(e) => {
                                setFormData(prev => ({ ...prev, [field]: e.target.value }));
                                handleChange(field as keyof FormData, e.target.value);
                            }}
                            onFocus={() => handleFocus(field as keyof FormData)}
                            onBlur={() => handleBlur(field as keyof FormData)}
                            placeholder='Digite aqui'
                        />
                    )}
                </div>
            ))}
    
            <button style={styles.button} onClick={saveProfileChanges}>
                <span style={styles.buttonText}>Salvar</span>
            </button>
        </div>
    );    
};

export default DataFormBand;
