import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';

interface CustomJwtPayload {
  exp: number;
  auth_id: number;
  specific_id: number;
  role: 'usuario' | 'banda';
}

const ProtectedLayout: React.FC = () => {
  const { state, dispatch: authDispatch, updateToken } = useContext(AuthContext);
  const [isChecking, setIsChecking] = useState(true);
  const updateTokenRef = useRef(updateToken);

  useEffect(() => {
    updateTokenRef.current = updateToken;
  }, [updateToken]);

  const updateAuthContext = useCallback((token: string) => {
    const decodedToken: CustomJwtPayload = jwtDecode(token);
    if (decodedToken.role === 'banda') {
      authDispatch({ type: 'SET_BANDA_ID', id_banda: decodedToken.specific_id });
    } else if (decodedToken.role === 'usuario') {
      authDispatch({ type: 'SET_USUARIO_ID', id_usuario: decodedToken.specific_id });
    }
    authDispatch({ type: 'SET_TOKEN', token });
  }, [authDispatch]);

  const clearTokensAndRedirect = useCallback(() => {
    console.log("Limpando tokens e redirecionando...");
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('sessionId');
    authDispatch({ type: 'RESET_AUTH' });
    setIsChecking(false);
  }, [authDispatch]);

  const verifySessionAndCheckTokens = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const accessToken = localStorage.getItem('accessToken');

      if (!refreshToken || !accessToken) {
        console.log("Tokens ausentes, redirecionando...");
        clearTokensAndRedirect();
        return;
      }

      try {
        const decodedToken: CustomJwtPayload = jwtDecode(accessToken);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (decodedToken.exp > currentTimestamp) {
          updateAuthContext(accessToken);
          setIsChecking(false);
          return;
        }
      } catch {
        // Erro ao decodificar token, vai tentar refresh
      }

      // Se chegou aqui, precisa fazer refresh
      try {
        const response = await axios.post('/delete/verify-tokenreact', 
          { refreshToken },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.data?.valid) {
          throw new Error('Token inválido');
        }

        setIsChecking(false);
      } catch (error) {
        console.log("Erro na verificação, redirecionando...");
        clearTokensAndRedirect();
      }
    } catch (error) {
      console.error('Erro durante verificação de sessão:', error);
      clearTokensAndRedirect();
    }
  };

  useEffect(() => {
    verifySessionAndCheckTokens();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'accessToken' && !e.newValue) {
        clearTokensAndRedirect();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [clearTokensAndRedirect]);

  if (isChecking) {
    return <div>Verificando autenticação...</div>;
  }

  if (!state.token) {
    return <Navigate to="/PrimeiraTela" />;
  }

  return <Outlet />;
};

export default ProtectedLayout;