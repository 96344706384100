import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../Services/axiosInterceptor';


type ToastType = 'info' | 'success' | 'error' | 'warning';

interface ToastState {
  show: boolean;
  message: string;
  type: ToastType;
}

type VerificationType = 'email' | 'phone' | null;

const ValidacaoUser: React.FC = () => {
  // Auth ID state
  const [authId, setAuthId] = useState<number | null>(null);
  
  // Estado para o método de verificação escolhido
  const [verificationType, setVerificationType] = useState<VerificationType>(null);
  
  // Estados para código de email
  const [emailCode, setEmailCode] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  
  // Estados para telefone
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [sendAttempts, setSendAttempts] = useState(0);
  const [countdown, setCountdown] = useState(140);
  const [isExpired, setIsExpired] = useState(false);

  // Estados gerais
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState<ToastState>({ show: false, message: '', type: 'info' });
  
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect para carregar e validar o auth_id
  useEffect(() => {
    const storedAuthId = localStorage.getItem('auth_id');
    
    if (!storedAuthId && location.pathname !== '/login') {
      navigate('/login');
    } else if (storedAuthId) {
      const parsedAuthId = parseInt(storedAuthId, 10);
      if (!isNaN(parsedAuthId)) {
        setAuthId(parsedAuthId);
      } else {
        console.error('Stored auth_id is not a valid number');
        navigate('/login');
      }
    }
  }, [navigate, location]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (showVerificationInput && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsExpired(true);
      showToast("Tempo expirado. Por favor, tente novamente.", 'warning');
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showVerificationInput, countdown]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (toast.show) {
      timer = setTimeout(() => {
        setToast(prev => ({ ...prev, show: false }));
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [toast]);

  const showToast = (message: string, type: ToastType = 'info') => {
    setToast({ show: true, message, type });
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, '');
    let formattedNumber = '';
    if (value.length > 2 && value.length <= 6) {
      formattedNumber = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    } else if (value.length > 6 && value.length <= 10) {
      formattedNumber = `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`;
    } else if (value.length > 10) {
      formattedNumber = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
    } else {
      formattedNumber = value;
    }
    setPhoneNumber(formattedNumber);
  };

  const verifyEmailCode = async () => {
    if (!emailCode) {
      showToast("Por favor, insira o código recebido no email.", "error");
      return;
    }

    if (!authId) {
      showToast("Erro de autenticação. Por favor, faça login novamente.", "error");
      navigate('/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/verify-code', { 
        code: emailCode,
        auth_id: authId
      });
      
      if (response.data.isVerified) {
        setEmailVerified(true);
        showToast("Email verificado com sucesso!", "success");
        navigate('/QuintaTela');
      } else {
        showToast("Código de verificação inválido.", "error");
      }
    } catch (error) {
      showToast("Erro ao verificar código.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const submitPhoneNumber = async (method: 'sms' | 'whatsapp') => {
    if (phoneNumber.replace(/\D/g, '').length < 11) {
      showToast("Por favor, insira um número de telefone válido.", "error");
      return;
    }

    if (sendAttempts >= 2) {
      showToast("Não foi possível enviar o código, por favor entre em contato.", "error");
      return;
    }

    if (!authId) {
      showToast("Erro de autenticação. Por favor, faça login novamente.", "error");
      navigate('/login');
      return;
    }

    setIsLoading(true);
    try {
      const endpoint = method === 'sms' ? '/users/send-sms' : '/users/send-whatsapp';
      const response = await axios.post(`https://tribhus.shop:5000${endpoint}`, {
        phoneNumber,
        auth_id: authId
      });

      if (response.data.message?.includes("enviado com sucesso")) {
        setShowVerificationInput(true);
        setCountdown(140);
        setVerificationType('phone');
        showToast(`Código enviado com sucesso ${method === 'sms' ? 'por SMS' : 'pelo WhatsApp'}.`, "success");
      } else {
        setSendAttempts(prev => prev + 1);
        showToast(`Falha ao enviar o código ${method === 'sms' ? 'por SMS' : 'pelo WhatsApp'}. Tente novamente.`, "error");
      }
    } catch (error: any) {
      setSendAttempts(prev => prev + 1);
      if (error.response?.data?.error?.includes('must subscribe to this instance')) {
        showToast("Serviço de WhatsApp temporariamente indisponível. Por favor, tente enviar por SMS.", "warning");
      } else {
        showToast('Erro ao conectar ao servidor. Tente novamente.', "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyPhoneCode = async () => {
    if (!phoneCode) {
      showToast("Por favor, insira o código de verificação.", "error");
      return;
    }

    if (!authId) {
      showToast("Erro de autenticação. Por favor, faça login novamente.", "error");
      navigate('/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/verify-sms-code', {
        phoneNumber,
        verificationCode: phoneCode,
        auth_id: authId
      });

      if (response.data.isVerified) {
        setPhoneVerified(true);
        showToast("Telefone verificado com sucesso!", "success");
        navigate('/QuintaTela');
      } else {
        showToast('Código de verificação inválido.', "error");
      }
    } catch (error) {
      showToast('Erro ao verificar o código.', "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {toast.show && (
        <div style={{ ...styles.toast, ...styles[toast.type] }}>
          {toast.message}
        </div>
      )}
      
      <div style={styles.scrollView}>
        <img src={require('../assets/logotipo.png')} alt="Logo" style={styles.logo} />
              
        <h1 style={styles.title}>Escolha como quer Validar sua Conta</h1>
        <p style={styles.subtitle}>
          Para sua segurança, valide o código recebido no seu email
          ou cadastre e valide seu telefone celular.
        </p>

        {/* Seção de Email */}
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>Escolher Validar por Email</h2>
          <p style={styles.instruction}>
            Digite o código que foi enviado para seu email cadastrado
          </p>
          <input
            style={styles.input}
            placeholder="Insira o Código de verificação"
            value={emailCode}
            onChange={(e) => setEmailCode(e.target.value)}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            disabled={emailVerified || isLoading}
          />
          {!emailVerified && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <button 
                style={styles.button} 
                onClick={verifyEmailCode}
                disabled={isLoading}
              >
                Verificar Código
              </button>
            </div>
          )}
          {emailVerified && (
            <p style={styles.verifiedText}>✓ Email verificado com sucesso!</p>
          )}
        </div>

        {/* Seção de Telefone */}
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>Escolher Validar por Celular</h2>
          <input
            style={styles.input}
            placeholder="Insira Seu Número com DDD"
            value={phoneNumber}
            onChange={handlePhoneChange}
            type="tel"
            disabled={phoneVerified || isLoading}
          />
          {verificationType === 'phone' && (
            <input
              style={styles.input}
              placeholder="Insira o Código de verificação"
              value={phoneCode}
              onChange={(e) => setPhoneCode(e.target.value)}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              disabled={phoneVerified || isLoading || countdown === 0}
            />
          )}
          {!phoneVerified && !showVerificationInput && sendAttempts < 2 && (
            <div style={styles.buttonContainer}>
              <button 
                style={styles.button} 
                onClick={() => submitPhoneNumber('sms')}
                disabled={isLoading}
              >
                Enviar Código por SMS
              </button>
              <button 
                style={{...styles.button, marginTop: '10px'}} 
                onClick={() => submitPhoneNumber('whatsapp')}
                disabled={isLoading}
              >
                Enviar Código pelo WhatsApp
              </button>
            </div>
          )}
          {verificationType === 'phone' && showVerificationInput && (
            <>
              {!isExpired ? (
                <>
                  <button 
                    style={styles.button} 
                    onClick={verifyPhoneCode}
                    disabled={isLoading}
                  >
                    Verificar Código
                  </button>
                  <p style={styles.countdown}>
                    Tempo restante: {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}
                  </p>
                </>
              ) : (
                <>
                  <p style={styles.expiredText}>Código expirado!</p>
                  <a
                    href={`https://wa.me/5519996647030`}
                    style={styles.linkWhatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Não conseguiu? Entre em contato
                  </a>
                  <button 
                    style={styles.button}
                    onClick={() => {
                      setShowVerificationInput(false);
                      setVerificationType(null);
                      setPhoneCode('');
                      setSendAttempts(0);
                      setIsExpired(false);
                      setCountdown(140);
                    }}
                  >
                    Tentar Novamente
                  </button>
                </>
              )}
            </>
          )}
          {phoneVerified && (
            <p style={styles.verifiedText}>✓ Telefone verificado com sucesso!</p>
          )}
        </div>

        <p style={styles.securityText}>
          A validação do seu contato é essencial para a segurança da sua conta. 
          Este processo nos ajuda a proteger suas informações contra acessos não autorizados 
          e permite que você recupere sua conta facilmente em caso de necessidade.
        </p>
      </div>
    </div>
  );
};


const styles: { [key: string]: React.CSSProperties } = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1E1E1E',
      minHeight: '100vh',
      position: 'relative',
    },
    scrollView: {
      flex: 1,
      padding: '20px',
      maxWidth: '800px',
      margin: '0 auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      width: '100%',
      maxWidth: '150px',
      height: 'auto',
      marginBottom: '2px',
      objectFit: 'contain',
    },
    title: {
      fontSize: '24px',
      color: '#FFF',
      marginBottom: '1px',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '16px',
      color: '#CCC',
      marginBottom: '2px',
      textAlign: 'center',
    },
    section: {
      width: '100%',
      marginBottom: '20px',
      padding: '15px',
      backgroundColor: '#2A2A2A',
      borderRadius: '10px',
    },
    sectionTitle: {
      fontSize: '18px',
      color: '#FF6600',
      marginBottom: '10px',
    },
    instruction: {
      fontSize: '14px',
      color: '#CCC',
      marginBottom: '10px',
      textAlign: 'center',
    },
    input: {
      height: '40px',
      width: '100%',
      maxWidth: '300px',
      marginBottom: '10px',
      padding: '10px',
      backgroundColor: '#000',
      border: '1px solid gray',
      borderRadius: '20px',
      color: '#FFF',
      fontSize: '16px',
      cursor: 'pointer',
      display: 'block',
      margin: '10px auto',
      textAlign: 'center',
    },
    button: {
      backgroundColor: 'transparent',
      color: '#FFF',
      border: 'none',
      fontSize: '16px',
      cursor: 'pointer',
      padding: '10px',
      textAlign: 'center',
      outline: 'none',
      transition: 'opacity 0.3s ease',
      display: 'block',
      margin: '10px auto',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
    verifiedText: {
      color: '#4CAF50',
      fontSize: '16px',
      textAlign: 'center',
      marginTop: '10px',
    },
    expiredText: {
      color: '#e74c3c',
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '15px',
    },
    linkWhatsapp: {
      color: '#25D366',
      fontSize: '16px',
      textAlign: 'center',
      textDecoration: 'underline',
      cursor: 'pointer',
      display: 'block',
      marginBottom: '10px',
    },
    countdown: {
      color: '#FFF',
      fontSize: '14px',
      marginTop: '10px',
      textAlign: 'center',
    },
    securityText: {
      fontSize: '14px',
      color: '#999',
      marginTop: '20px',
      textAlign: 'center',
      maxWidth: '80%',
    },
    toast: {
      position: 'fixed',
      top: '20px',
      right: '20px',
      padding: '10px 20px',
      borderRadius: '4px',
      color: '#fff',
      fontSize: '14px',
      zIndex: 1000,
    },
    info: {
      backgroundColor: '#3498db',
    },
    success: {
      backgroundColor: '#2ecc71',
    },
    error: {
      backgroundColor: '#e74c3c',
    },
    warning: {
      backgroundColor: '#f39c12',
    },
  } as const;
  
  export default ValidacaoUser;