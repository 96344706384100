import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider } from './context/AuthContext';
import { RegisterProvider } from './context/RegisterContext';
import { BandaProvider } from './context/BandaContext';
import { SlugProvider } from './context/SlugContext';
import { MusicProvider } from './context/MusicContext';
import SlugInterceptor from './Services/SlugInterceptor';
import AppRoutes from './AppRoutes';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { PasswordResetProvider } from './context/PasswordResetContext';

const AuthenticatedApp: React.FC = () => {
  const { state } = useAuth();

  if (!state.isAuthChecked) {
    return <div>Carregando...</div>;
  }

  return (
    <RegisterProvider>
      <BandaProvider>
        <PasswordResetProvider>
          <div className="App">
            <AppRoutes />
            <ToastContainer />
          </div>
        </PasswordResetProvider>
      </BandaProvider>
    </RegisterProvider>
  );
};

const AuthProviderWithRouter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  return <AuthProvider navigate={navigate}>{children}</AuthProvider>;
};

const AppWithProviders: React.FC = () => {
  Modal.setAppElement('#root');

  return (
    <Provider store={store}>
      <Router>
        <AuthProviderWithRouter>
          <MusicProvider>
            <SlugProvider>
              <SlugInterceptor>
                <AuthenticatedApp />
              </SlugInterceptor>
            </SlugProvider>
          </MusicProvider>
        </AuthProviderWithRouter>
      </Router>
    </Provider>
  );
};

export default AppWithProviders;