import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import DataForm from '../Components/DataForm';
import SobreMim from '../Components/SobreMim';
import GenerosEdit from '../Components/GenerosEdit';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import EditSecurityModalUser from '../Components/EditSecurityModalUser'; 
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop';
import getCroppedImg from '../utils/cropImage';

interface User {
    id_usuario?: number;
    nome?: string;
    sobrenome?: string;
    descricao?: string;
    cidade?: string;
    estado?: string;
    data_nascimento?: string;
    genero_sexo?: string;
    genero_personalizado?: string; 
    telefone?: string | null;
    url_capa_usuario?: string | null;
    url_icone_usuario?: string | null;
    slug?: string;
    generos?: string[];
    auth_id?: number;
}

const UserEditPerfil: React.FC = () => {
    const navigate = useNavigate();
    const { state: authState } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState('Dados');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCropping, setIsCropping] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageType, setImageType] = useState<'capa' | 'icone'>('capa');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState<Area | null>(null);
    const [user, setUser] = useState<User>({
        url_capa_usuario: null,
        url_icone_usuario: null,
        nome: '',
        sobrenome: '',
        descricao: '',
        cidade: '',
        estado: '',
        data_nascimento: '',
        genero_sexo: '',
        telefone: null,
        slug: '',
        generos: [],
        auth_id: undefined,
    });

    const onCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, type: 'capa' | 'icone') => {
        const file = event.target.files?.[0];
        if (file) {
            const imageDataUrl = await readFile(file);
            setImageSrc(imageDataUrl);
            setImageType(type);
            setIsCropping(true);
            setZoom(1);
        }
    };

    const handleCrop = async () => {
        if (!imageSrc || !croppedArea) {
            console.error('Imagem ou área de corte não está definida');
            return;
        }
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedArea);
            if (!croppedImage) {
                console.error('Erro ao processar a imagem cortada');
                return;
            }

            const formData = new FormData();
            formData.append(imageType === 'capa' ? 'image' : 'icone', croppedImage, `${imageType}.jpg`);
            formData.append('id_usuario', String(authState.id_usuario));

            const result = await axios.post(
                `https://tribhus.shop:5000/users/upload${imageType === 'icone' ? '-icone' : ''}/${authState.id_usuario}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${authState.token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setUser(prevUser => ({
                ...prevUser,
                [imageType === 'capa' ? 'url_capa_usuario' : 'url_icone_usuario']: result.data.imageUrl
            }));

            setIsCropping(false);
            setImageSrc(null);
        } catch (error) {
            console.error('Erro ao processar ou enviar imagem:', error);
        }
    };

    const handleCancelCrop = () => {
        setIsCropping(false);
        setImageSrc(null);
    };

    const readFile = (file: File): Promise<string> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
        });
    };


    useEffect(() => {
        if (authState.id_usuario && authState.token) {
            axios.get(`https://tribhus.shop:5000/users/${authState.id_usuario}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            })
            .then(response => {
                setUser(prevUser => ({
                    ...prevUser,
                    ...response.data
                }));
            })
            .catch(error => {
                console.error("Erro ao buscar dados do usuário:", error);
            });
        }
    }, [authState.id_usuario, authState.token]);

     const handleSecurityPress = () => {
        setIsModalOpen(true);
    };

const handleChange = (name: keyof User, value: string) => {
  setUser(prevUser => ({
    ...prevUser,
    [name]: value
  }));
};

    const updateDescription = (newDescription: string) => {
        setUser(prevUser => ({
            ...prevUser,
            descricao: newDescription
        }));
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'Dados':
                return <DataForm user={user} handleChange={handleChange} />;
            case 'Sobre Mim':
                return <SobreMim user={user} updateDescription={updateDescription} />;
            case 'Gêneros do Rock':
                return <GenerosEdit user={user} setUser={setUser} />;
            default:
                return null;
        }
    };

    const renderImageOrPlaceholder = (source: string | null | undefined, style: React.CSSProperties, placeholderStyle: React.CSSProperties) => (
        source ? (
            <img src={source} alt="User" style={style} />
        ) : (
            <div style={{...style, ...placeholderStyle}}>
                <span style={styles.placeholderText}>Sem Imagem</span>
            </div>
        )
    );

    return (
        <div style={styles.wrapper}>
        <div style={styles.container}>
            <button onClick={() => navigate(-1)} style={styles.backButton}>
                <img
                    src={require('../assets/voltar.png')}
                    alt="Voltar"
                    style={styles.backIcon}
                />
            </button>
            <h1 style={styles.title}>Editando Perfil</h1>
            <div style={styles.coverImageContainer}>
                {renderImageOrPlaceholder(user.url_capa_usuario, styles.coverImage, styles.imagePlaceholder)}
                <input
                    type="file"
                    id="coverUpload"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, 'capa')}
                    accept="image/*"
                />
                <button onClick={() => document.getElementById('coverUpload')?.click()} style={styles.editIconCover}>
                    <img src={require('../assets/edit.png')} alt="Edit" style={styles.icon} />
                </button>
                <button onClick={handleSecurityPress} style={styles.securityIcon}>
                    🔒
                </button>
            </div>
            <div style={styles.profileImageContainer}>
                {renderImageOrPlaceholder(user.url_icone_usuario, styles.profileImage, styles.imagePlaceholder)}
                <input
                    type="file"
                    id="iconUpload"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, 'icone')}
                    accept="image/*"
                />
                <button onClick={() => document.getElementById('iconUpload')?.click()} style={styles.editIcon}>
                    <img src={require('../assets/edit.png')} alt="Edit" style={styles.icon} />
                </button>
            </div>
            <div style={styles.tabsContainer}>
                {['Dados', 'Sobre Mim', 'Gêneros do Rock'].map((tab) => (
                    <button
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                        style={{
                            ...styles.tabButton,
                            ...(activeTab === tab ? styles.activeTab : styles.inactiveTab)
                        }}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            {renderContent()}
            <EditSecurityModalUser 
                isOpen={isModalOpen} 
                onRequestClose={() => setIsModalOpen(false)} 
            />
    
    {isCropping && imageSrc && (
                <div style={styles.cropperContainer}>
                    <div style={imageType === 'capa' ? styles.cropperWrapperCapa : styles.cropperWrapperIcone}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={imageType === 'capa' ? 1000 / 220 : 1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            cropShape={imageType === 'icone' ? 'round' : 'rect'}
                            showGrid={false}
                        />
                    </div>
                    <div style={styles.controlsContainer}>
                        <div style={styles.instructionsText}>
                            Use a roda do mouse para aumentar/diminuir o zoom. Arraste a imagem para ajustar.
                        </div>
                        <div style={styles.cropControls}>
                            <button onClick={() => setZoom(Math.max(1, zoom - 0.1))} style={styles.controlButton}>-</button>
                            <span style={styles.zoomText}>{Math.round(zoom * 100)}%</span>
                            <button onClick={() => setZoom(Math.min(3, zoom + 0.1))} style={styles.controlButton}>+</button>
                        </div>
                        <div style={styles.actionButtons}>
                            <button onClick={handleCancelCrop} style={styles.cancelButton}>
                                Cancelar
                            </button>
                            <button onClick={handleCrop} style={styles.cropButton}>
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    wrapper: {
        backgroundColor: '#151922',
        minHeight: '100vh',
        width: '100%',
      },
      container: {
        backgroundColor: '#151922',
        minHeight: '100vh',
        color: 'white',
        padding: '20px',
        maxWidth: '1200px',  // ou outro valor que preferir
        margin: '0 auto',    // para centralizar
    },
    cropperContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    cropperWrapperCapa: {
        width: '100%',
        height: '60vh', // Aumentado para 60% da altura da viewport
        position: 'relative',
        maxWidth: '1000px', // Limitando a largura máxima
    },
    cropperWrapperIcone: {
        width: '300px', // Aumentado para 300px
        height: '300px', // Aumentado para 300px
        position: 'relative',
    },
    controlsContainer: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    instructionsText: {
        color: 'white',
        marginBottom: '15px',
        fontSize: '16px',
        textAlign: 'center',
    },
    cropControls: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    controlButton: {
        backgroundColor: '#FF6600',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        fontSize: '24px',
        cursor: 'pointer',
        margin: '0 15px',
    },
    zoomText: {
        color: 'white',
        fontSize: '18px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
    },
    cropButton: {
        padding: '12px 24px',
        backgroundColor: '#FF6600',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 'bold',
        marginLeft: '15px',
    },
    cancelButton: {
        padding: '12px 24px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 'bold',
        marginRight: '15px',
    },
    backButton: {
        position: 'absolute',
        left: '10px',
        top: '10px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    backIcon: {
        width: '24px',
        height: '24px',
        marginTop: '22px',
        marginBottom: '15px',
    },
    title: {
        fontSize: '20px',
        color: 'white',
        fontWeight: 'bold',
        marginBottom: '15px',
        textAlign: 'center',
    },
    coverImageContainer: {
        width: '100%',
        position: 'relative',
        marginBottom: '20px',
    },
    coverImage: {
        width: '100%',
        height: '220px',
        objectFit: 'cover',
    },
    profileImageContainer: {
        position: 'relative',
        marginTop: '-80px',
        marginBottom: '10px',
        width: '100px',
        height: '100px',
    },
    profileImage: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        border: '3px solid #FF6600',
        objectFit: 'cover',
    },
    editIconCover: {
        position: 'absolute',
        right: '2px',
        bottom: '-15px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    editIcon: {
        position: 'absolute',
        right: '-10px',
        bottom: '0',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    icon: {
        width: '30px',
        height: '30px',
    },
    securityIcon: {
        position: 'absolute',
        right: '10px',
        bottom: '-60px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '25px',
    },
    tabsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: '20px',
    },
    tabButton: {
        flex: 1,
        padding: '10px',
        background: 'none',
        border: 'none',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
    },
    activeTab: {
        borderBottom: '2px solid #FF6600',
    },
    inactiveTab: {
        borderBottom: 'none',
    },
    imagePlaceholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
    },
    placeholderText: {
        color: 'white',
        fontSize: '16px',
    },
};

export default UserEditPerfil;