import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import DataFormBand from '../Components/DataFormBand';
import logoHorizontal from '../assets/logo_horizontal.png';
import Sobre from '../Components/Sobre';
import GenerosEditBand from '../Components/GenerosEditBand';
import EditSecurityModal from '../Components/EditSecurityModal';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../utils/cropImage'; 
import { Area } from 'react-easy-crop'; 

interface Band {
    id_banda: number;
    nome_banda: string;
    cnpj?: string;
    nome_responsavel: string;
    nasc_responsavel: string;
    data_inicio?: string;
    telefone?: string;
    descricao?: string;
    url_capa_banda?: string;
    url_icone_banda?: string;
    cidade: string;
    estado?: string;
    id_musica_apresentacao?: number;
    url_facebook?: string;
    url_instagram?: string;
    url_spotify?: string;
    sobrenome_responsavel: string;
    auth_id: number;
    slug?: string;
    [key: `url_${string}`]: string | undefined;
}

const BandEditPerfil: React.FC = () => {
    const navigate = useNavigate();
    const onClose = () => navigate(-1);
    const { state } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState<string>('Dados');
    const { state: authState } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCropping, setIsCropping] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageType, setImageType] = useState<string>('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState<Area | null>(null);
    const [band, setBand] = useState<Band>({
        id_banda: state.id_banda ?? 0,
        nome_banda: '',
        nome_responsavel: '',
        nasc_responsavel: '',
        cidade: '',
        sobrenome_responsavel: '',
        auth_id: 0,
    });

    const onCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

// Lida com a seleção da imagem (tanto para capa quanto para ícone)
const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const file = event.target.files?.[0];
    if (file) {
        const imageDataUrl = await readFile(file);
        setImageSrc(imageDataUrl);
        setImageType(type);
        setIsCropping(true);
    }
};

const handleCancelCrop = () => {
    setIsCropping(false);
    setImageSrc(null);
};

 // Envia a imagem cortada
 const handleCrop = async () => {
    if (!imageSrc || !croppedArea) {
        console.error('Imagem ou área de corte não está definida');
        return;
    }
    try {
        const croppedImage = await getCroppedImg(imageSrc, croppedArea);
        if (!croppedImage) {
            console.error('Erro ao processar a imagem cortada');
            return;
        }

        // Crie um FormData para enviar a imagem cortada
        const formData = new FormData();
        formData.append(imageType === 'capa' ? 'image' : 'icone', croppedImage, `${imageType}.jpg`);

        // Envie a imagem para o servidor via POST
        const result = await axios.post(
            `https://tribhus.shop:5000/bands/upload-${imageType}/${authState.id_banda}`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${authState.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        // Atualize o estado da banda com a URL da imagem recebida na resposta
        if (imageType === 'capa') {
            setBand((prevBand) => ({ ...prevBand, url_capa_banda: result.data.imageUrl }));
        } else {
            setBand((prevBand) => ({ ...prevBand, url_icone_banda: result.data.imageUrl }));
        }

        // Limpe o campo de imagem após o upload
        setIsCropping(false);
        setImageSrc(null);
    } catch (error) {
        console.error('Erro ao processar ou enviar imagem:', error);
    }
};

    // Função para ler o arquivo
const readFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
};

    useEffect(() => {
        if (state.id_banda && authState.token) {
            axios.get(`https://tribhus.shop:5000/bands/banda/${state.id_banda}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            })
            .then(response => {
                console.log("Dados recebidos:", response.data);
                setBand(prevBand => ({
                    ...prevBand,
                    ...response.data
                }));
            })
            .catch(error => {
                console.error("Erro ao buscar dados da banda:", error);
            });
        }
    }, [state.id_banda, authState.token]);
   
     const handleChange = (name: keyof Band, value: string) => {
        setBand(prevBand => ({
            ...prevBand,
            [name]: value
        }));
    };

    const updateDescription = (newDescription: string) => {
        setBand(prevBand => ({
            ...prevBand,
            descricao: newDescription
        }));
    };
    
    const handleSecurityPress = () => {
        setIsModalOpen(true);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'Dados':
                return <DataFormBand band={band} handleChange={handleChange} />;
            case 'Sobre':
                return <Sobre band={band} updateDescription={updateDescription} />;
            case 'Gêneros do Rock':
                return band.id_banda ? <GenerosEditBand band={band} setBand={setBand} /> : null;
            default:
                return null;
        }
    };

    useEffect(() => {
        console.log('isCropping:', isCropping);
    }, [isCropping]);    

    return (
        <div style={styles.wrapper}>
        <div style={styles.container}>
            {/* Header e título */}
            <div style={styles.header}>
            <button onClick={onClose} style={styles.backButton}>
                    <img
                        src={require('../assets/voltar.png')}
                        alt="Voltar"
                        style={styles.backIcon}
                    />
                </button>
                  <img src={logoHorizontal} alt="Logo da Empresa" style={styles.logo} />
                <h1 style={styles.title}>Editando Perfil</h1>
            </div>

            {/* Capa da banda */}
            <div style={styles.coverImageContainer}>
                {band.url_capa_banda ? (
                    <img src={band.url_capa_banda} alt="Capa da banda" style={styles.coverImage} />
                ) : (
                    <div style={styles.imagePlaceholder}>Sem Imagem</div>
                )}
                <button onClick={() => document.getElementById('uploadCapa')?.click()} style={styles.editIconCover}>
                    <img src={require('../assets/edit.png')} alt="Editar capa" style={styles.editIcon} />
                </button>
                <input type="file" id="uploadCapa" style={{ display: 'none' }} accept="image/*" onChange={(e) => handleFileChange(e, 'capa')} />
            </div>

            {/* Ícone da banda */}
            <div style={styles.profileImageContainer}>
                {band.url_icone_banda ? (
                    <img src={band.url_icone_banda} alt="Ícone da banda" style={styles.profileImage} />
                ) : (
                    <div style={styles.imagePlaceholder}>Sem Imagem</div>
                )}
                <button onClick={() => document.getElementById('uploadIcon')?.click()} style={styles.editIconProfile}>
                    <img src={require('../assets/edit.png')} alt="Editar ícone" style={styles.editIcon} />
                </button>
                <button onClick={handleSecurityPress} style={styles.securityIcon}>
                    🔒
                </button>
                <input type="file" id="uploadIcon" style={{ display: 'none' }} accept="image/*" onChange={(e) => handleFileChange(e, 'icone')} />
            </div>

            {/* Tabs */}
            <div style={styles.tabsContainer}>
                {['Dados', 'Sobre', 'Gêneros do Rock'].map((tab) => (
                    <button
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                        style={{
                            ...styles.tabButton,
                            ...(activeTab === tab ? styles.activeTab : styles.inactiveTab),
                        }}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            {renderContent()}

            {/* Exibir o Cropper se uma imagem for selecionada */}
            {isCropping && imageSrc && (
                <div style={styles.cropperContainer}>
                    <div style={imageType === 'capa' ? styles.cropperWrapperCapa : styles.cropperWrapperIcone}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={imageType === 'capa' ? 1000 / 220 : 1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            cropShape={imageType === 'icone' ? 'round' : 'rect'}
                            showGrid={false}
                        />
                    </div>
                    <div style={styles.controlsContainer}>
                        <div style={styles.instructionsText}>
                            Use a roda do mouse para aumentar/diminuir o zoom. Arraste a imagem para ajustar.
                        </div>
                        <div style={styles.cropControls}>
                            <button onClick={() => setZoom(Math.max(1, zoom - 0.1))} style={styles.controlButton}>-</button>
                            <span style={styles.zoomText}>{Math.round(zoom * 100)}%</span>
                            <button onClick={() => setZoom(Math.min(3, zoom + 0.1))} style={styles.controlButton}>+</button>
                        </div>
                        <div style={styles.actionButtons}>
                            <button onClick={handleCancelCrop} style={styles.cancelButton}>
                                Cancelar
                            </button>
                            <button onClick={handleCrop} style={styles.cropButton}>
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            )}
            
           <EditSecurityModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} />
        </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    wrapper: {
        backgroundColor: '#151922',
        minHeight: '100vh',
        width: '100%',
      },
      container: {
        backgroundColor: '#151922',
        minHeight: '100vh',
        color: 'white',
        padding: '20px',
        maxWidth: '1200px',  // ou outro valor que preferir
        margin: '0 auto',    // para centralizar
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',  // Reduz o espaço inferior do cabeçalho
        padding: '10px 20px',  // Reduz o padding superior e inferior
    },
    cropperContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    cropperWrapperCapa: {
        width: '100%',
        height: '60vh', // Aumentado para 60% da altura da viewport
        position: 'relative',
        maxWidth: '1000px', // Limitando a largura máxima
    },
    cropperWrapperIcone: {
        width: '300px', // Aumentado para 300px
        height: '300px', // Aumentado para 300px
        position: 'relative',
    },
    controlsContainer: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    instructionsText: {
        color: 'white',
        marginBottom: '15px',
        fontSize: '16px',
        textAlign: 'center',
    },
    cropControls: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    controlButton: {
        backgroundColor: '#FF6600',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        fontSize: '24px',
        cursor: 'pointer',
        margin: '0 15px',
    },
    zoomText: {
        color: 'white',
        fontSize: '18px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
    },
    cropButton: {
        padding: '12px 24px',
        backgroundColor: '#FF6600',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 'bold',
        marginLeft: '15px',
    },
    cancelButton: {
        padding: '12px 24px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 'bold',
        marginRight: '15px',
    },
    backButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '0',
        marginRight: '60px',  // Espaço padrão entre o botão e a logo
    },
    backIcon: {
        width: '24px',
        height: '24px',
    },
    logo: {
        maxWidth: '150px',  // Ajusta o tamanho da logo se necessário
        marginBottom: '0',  // Remove o margin inferior
    },
    title: {
        fontSize: '24px',  // Ajusta o tamanho da fonte para economizar espaço
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'right',
        flex: 1,
        margin: '0',  // Remove margin do título
    },
    coverImageContainer: {
        width: '100%',
        position: 'relative',
        marginBottom: '20px',
    },
    coverImage: {
        width: '100%',
        height: '220px',
        objectFit: 'cover',
    },
    profileImageContainer: {
        position: 'relative',
        marginTop: '-80px',
        marginBottom: '10px',
        width: '100px',
        height: '100px',
    },
    profileImage: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        border: '3px solid #FF6600',
        objectFit: 'cover',
    },
    icon: {
        width: '30px',
        height: '30px',
    },
    securityIcon: {
        position: 'absolute',
        right: '10px',
        bottom: '-60px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '25px',
    },
    tabsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: '20px',
    },
    tabButton: {
        flex: 1,
        padding: '10px',
        background: 'none',
        border: 'none',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
    },
    activeTab: {
        borderBottom: '2px solid #FF6600',
    },
    inactiveTab: {
        borderBottom: 'none',
    },
    imagePlaceholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
    },
    placeholderText: {
        color: 'white',
        fontSize: '16px',
    },
    editIconCover: {
        position: 'absolute',
        right: '2px',
        bottom: '-5px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: '0',  
    },
    editIcon: {
        width: '30px',  
        height: '30px',  
    },
   editIconProfile: {
        position: 'absolute',
        right: '-5px',
        bottom: '0',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: '0', 
   },
};

export default BandEditPerfil;