import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterContext } from '../context/RegisterContext';
import BotaoAjuda from '../Components/BotaoAjuda'; 

const SextaTela = () => {
  const { state, dispatch } = useContext(RegisterContext);
  const navigate = useNavigate();

  const checkSlugExistence = async (slug: string) => {
    try {
      const response = await fetch(`https://tribhus.shop:5000/users/slug-existence/${slug}`); 
      const data = await response.json();
      return data.exists; // supondo que sua API retorne um objeto com uma propriedade `exists` que é verdadeira ou falsa
    } catch (error) {
      console.error("Error checking slug:", error);
      return false;
    }
  };  

  const handleNextClick = async () => {
    const slugExists = await checkSlugExistence(state.slug);
    if (slugExists) {
      alert("O slug já existe! Por favor, escolha outro.");
    } else {
      navigate('/SetimaTela');
    }
  };  

  return (
    <div style={styles.container}>
      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />

      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          onChange={e => dispatch({ type: 'SET_FORM', field: 'nome', value: e.target.value })}
          value={state.nome}
          placeholder="Nome"
        />
        <input
          style={styles.input}
          onChange={e => dispatch({ type: 'SET_FORM', field: 'sobrenome', value: e.target.value })}
          value={state.sobrenome}
          placeholder="Sobrenome"
        />
        <input
        style={styles.input}
        onChange={e => dispatch({ type: 'SET_FORM', field: 'slug', value: e.target.value })}
        value={state.slug}
        placeholder="Escolha seu nome de usuário"
        />
      </div>

      <button
  style={styles.button}
  onClick={handleNextClick}
>
  <span style={styles.buttonText}>Próximo</span>
</button>
      {/* Botão de Ajuda com a tela atual */}
      <BotaoAjuda telaAtual="SextaTela" />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#151922',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw'
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain' as 'contain', // Especifiquei o valor correto aqui
    marginBottom: '50px'
  },
  inputContainer: {
    width: '80%',
    marginBottom: '30px'
  },
  input: {
    height: '40px',
    borderColor: '#999',
    borderWidth: '1px',
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: '#fff',
    marginBottom: '15px',
    color: '#333',
    width: '100%'
  },
  button: {
    backgroundColor: '#914100',
    paddingHorizontal: '50px',
    paddingVertical: '15px',
    borderRadius: '10px',
    border: 'none',
    cursor: 'pointer'
  },
  buttonText: {
    color: '#fff1e5',
    fontSize: '16px'
  }
};

export default SextaTela;
